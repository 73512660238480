export const sessionStorage = {
  setItem: (key: string, value: string) => {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (e) {}
  },
  getItem: (key: string) => {
    try {
      return window.sessionStorage.getItem(key);
    } catch (e) {}
  },
  clear: () => {
    try {
      return window.sessionStorage.clear();
    } catch (e) {}
  },
};
