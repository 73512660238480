import moment from "moment-timezone";
import CouponModel from "src/models/CouponModel";
import GroupRegistrationModel, {
  isGroupRegistration,
} from "src/models/GroupRegistrationModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import MembershipModel, { isMembership } from "src/models/MembershipModel";
import OrganizationModel from "src/models/OrganizationModel";
import PrivateSerieModel, {
  isPrivateSerie,
} from "src/models/PrivateSerieModel";
import calculateInvoiceItemBalance from "src/utils/calculateInvoiceItemBalance";
import { calculatePricingForMembership } from "src/utils/calculatePricingForMembership";
import { calculatePricingForPrivateSerie } from "src/utils/calculatePricingForPrivateSerie";
import calculateProratedRegistrationPrice from "src/utils/calculateProratedRegistrationPrice";
import { checkCouponData } from "src/utils/checkCouponData";
import {
  AmountType,
  coupons,
  Frequency,
  GroupRegistrationStatus,
} from "types/code-generator";

interface CalculateCouponsForRegistrationInput {
  coupons: coupons["coupons"];
  registration: GroupRegistrationModel | PrivateSerieModel | MembershipModel;
  startDate: Date;
  organization?: OrganizationModel;
  invoiceItem?: InvoiceItemModel;
}

export default ({
  coupons,
  registration,
  startDate,
  organization,
  invoiceItem,
}: CalculateCouponsForRegistrationInput) => {
  const result: Array<{
    coupon: CouponModel;
    amount: number;
  }> = [];

  const activeCoupons = coupons.filter((c) =>
    checkCouponData({
      coupon: c,
    })
  );

  // console.log("activeCoupons", activeCoupons);

  let offering;
  let offeringPrice;

  if (isGroupRegistration(registration)) {
    offering = registration.class;

    if (invoiceItem) {
      offeringPrice = calculateInvoiceItemBalance(invoiceItem).totalOwed;
    } else {
      offeringPrice = calculateProratedRegistrationPrice({
        classItem: registration.class,
        endDate: moment(startDate).endOf("month").toDate(),
        startDate,
        organization,
      });
    }
  } else if (isPrivateSerie(registration)) {
    offering = registration.offering;
    offeringPrice = calculatePricingForPrivateSerie({
      privateSerie: registration,
      organization,
    }).price;
  } else if (isMembership(registration)) {
    offering = registration.offering;
    offeringPrice = calculatePricingForMembership({
      membership: registration,
      startDate,
    }).price;
  }

  activeCoupons.forEach((coupon) => {
    let amount = 0;

    if (
      coupon.availableForAllOfferings ||
      (offering.coupons &&
        offering.coupons.find((ocoupon) => ocoupon.id === coupon.id))
    ) {
      if (coupon.amountType === AmountType.Dollar) {
        amount += coupon.amount;
      } else {
        amount += offeringPrice * (coupon.amount / 100);
      }

      if (
        registration.status === GroupRegistrationStatus.Waitlist &&
        moment().isSame(startDate, "day")
      ) {
        amount = 0;
      }

      if (
        coupon.frequency === Frequency.OneTime &&
        moment().isBefore(startDate, "day")
      ) {
        amount = 0;
      }

      if (
        coupon.frequency === Frequency.Monthly &&
        coupon.applyAtCheckout === false
      ) {
        amount = 0;
      }

      if (amount >= offeringPrice) {
        amount = offeringPrice;
      }

      if (!Number.isInteger(amount)) {
        amount = Math.floor(amount);
      }

      result.push({ coupon, amount });
    }
  });

  // console.log("result", result);

  return result;
};
