import { ReactNode } from "react";
import { useStore } from "src/stores/Store";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  onClick?: (e: any) => void;
  children: ReactNode;
}

export default function HeaderNavButton(props: Props) {
  const store = useStore();

  return (
    <button
      className={tw(
        "m-0 h-[50px] w-[30px] cursor-pointer border-none p-0 text-left text-xl leading-[50px] outline-none",
        store.isAdminArea || store.user?.isStaff
          ? "text-organization"
          : "text-organization",
        props.className
      )}
      data-test="header-nav"
      {...props}
    >
      {props.children}
    </button>
  );
}
