import { isServer } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { lg, md, sm } from "src/styles/utility";
import { useMergeState } from "./useMergeState";

export interface WithMediaQueriesProps {
  small: boolean;
  medium: boolean;
  large: boolean;
  visualHeight: number;
}

export function useMediaQueries() {
  const value = useContext<WithMediaQueriesProps>(ViewportContext);
  return value;
}

export function withMediaQueries(Component) {
  return function (props) {
    const { small, medium, large } = useMediaQueries();
    return <Component {...props} small={small} medium={medium} large={large} />;
  };
}

const ViewportContext = React.createContext<WithMediaQueriesProps>({
  small: false,
  medium: false,
  large: false,
  visualHeight: 0,
});

let smallMediaQuery: MediaQueryList;
let mediumMediaQuery: MediaQueryList;
let largeMediaQuery: MediaQueryList;

if (!isServer) {
  smallMediaQuery = window.matchMedia(`(${sm})`);
  mediumMediaQuery = window.matchMedia(`(${md})`);
  largeMediaQuery = window.matchMedia(`(${lg})`);
}

const sizes = {
  small: sm,
  medium: md,
  large: lg,
};

export const ViewportProvider = (props) => {
  const [value, setValue] = useMergeState({
    small: smallMediaQuery.matches,
    medium: mediumMediaQuery.matches,
    large: largeMediaQuery.matches,
    visualHeight: window.visualViewport?.height || window.innerHeight,
    visualWidth: window.visualViewport?.width || window.innerWidth,
  });

  useEffect(() => {
    const changeHandler = (e) => {
      for (const s in sizes) {
        if (e.media === `(${sizes[s]})`) {
          setValue({ [s]: e.matches });
          break;
        }
      }
    };

    const resizeChangeHandler = (e) => {
      setValue({
        visualHeight: window.visualViewport?.height || window.innerHeight,
        visualWidth: window.visualViewport?.width || window.innerWidth,
      });
    };

    try {
      smallMediaQuery.addEventListener("change", changeHandler);
      mediumMediaQuery.addEventListener("change", changeHandler);
      largeMediaQuery.addEventListener("change", changeHandler);
      window.visualViewport?.addEventListener("resize", resizeChangeHandler);
    } catch (e) {
      smallMediaQuery.addListener(changeHandler);
      mediumMediaQuery.addListener(changeHandler);
      largeMediaQuery.addListener(changeHandler);
    }

    changeHandler(smallMediaQuery);
    changeHandler(mediumMediaQuery);
    changeHandler(largeMediaQuery);

    return () => {
      try {
        smallMediaQuery.removeEventListener("change", changeHandler);
        mediumMediaQuery.removeEventListener("change", changeHandler);
        largeMediaQuery.removeEventListener("change", changeHandler);
        window.visualViewport?.removeEventListener(
          "resize",
          resizeChangeHandler
        );
      } catch (e) {
        smallMediaQuery.removeListener(changeHandler);
        mediumMediaQuery.removeListener(changeHandler);
        largeMediaQuery.removeListener(changeHandler);
      }
    };
  }, []);

  return (
    <ViewportContext.Provider value={value}>
      {props.children}
    </ViewportContext.Provider>
  );
};
