import { css } from "@emotion/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import tw from "src/utils/tw";
import { ClassNameValue } from "tailwind-merge";

interface Props {
  className?: string;
  onClick?: (e) => void;
  align?: string;
  to?: any;
  href?: string;
  target?: string;
  isPreview?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

export default function LinkButton(props: Props) {
  const classNames: ClassNameValue = tw(
    "text-organization bg-none border-none cursor-pointer outline-none transition-all hover:text-organization",
    props.align === "right" && "float-right",
    props.disabled && "opacity-50 cursor-not-allowed",
    props.className
  );

  const styles = css`
    & .fa {
      position: relative;
      top: 1px;
    }
  `;

  if (props.to) {
    return (
      <Link
        to={props.to}
        css={styles}
        className={classNames}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    );
  }

  if (props.href) {
    return (
      <a
        href={props.href}
        css={styles}
        className={classNames}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      css={styles}
      className={classNames}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
