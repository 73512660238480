import { toast, Toast, ToastBar, Toaster, ToastOptions } from "react-hot-toast";
import Loading from "src/components/layout/Loading";
import { getErrorString } from "src/utils/getErrorString";

export const CustomToaster = () => {
  function renderToast(t: Toast) {
    let icon;

    if (t.type === "loading") {
      icon = <Loading small noPadding />;
    } else if (t.type === "error") {
      icon = (
        <i aria-hidden className="far fa-triangle-exclamation text-error" />
      );
    } else if (t.type === "success") {
      icon = <i aria-hidden className="far fa-circle-check text-success" />;
    }

    if (t.icon) {
      icon = t.icon;
    }

    return (
      <ToastBar
        toast={t}
        style={{
          borderRadius: 4,
          padding: ".5rem .5rem",
          maxWidth: "90vw",
        }}
      >
        {() => {
          return (
            <div className="flex items-center truncate px-2 py-1">
              <div className="mr-4">{icon}</div>
              <div className="flex-1 truncate">{t.message?.toString()}</div>
            </div>
          );
        }}
      </ToastBar>
    );
  }

  return (
    <Toaster
      toastOptions={{
        error: {
          duration: 5000,
        },
        success: {
          duration: 5000,
        },
      }}
      containerStyle={{ height: "4rem" }}
    >
      {renderToast}
    </Toaster>
  );
};

type ToastError =
  | string
  | { errors: Array<{ message: string }> }
  | { message: string }
  | { networkError: { result: { errors: Array<{ message: string }> } } }
  | unknown;

const message = {
  loading: (message: string, options?: ToastOptions) => {
    const toastId = toast.loading(message, {
      ...options,
      duration: options?.duration,
    });
    return () => toast.dismiss(toastId);
  },
  success: (message: string, options?: ToastOptions) => {
    toast.success(message, {
      ...options,
      duration: options?.duration || 2 * 1000,
    });
  },
  warning: (message: string, options?: ToastOptions) =>
    toast.success(message, {
      icon: <i aria-hidden className="far fa-triangle-exclamation text-main" />,
      ...options,
    }),
  error: (error: ToastError, options?: ToastOptions) => {
    const errorString = getErrorString(error) || "Error";

    return toast.error(errorString, options);
  },
};

export default message;
