import gql from "graphql-tag";
import {
  createFee,
  createFeeVariables,
  deleteFee,
  deleteFeeVariables,
  fees,
  feesVariables,
  updateFee,
  updateFeeVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const FeePayloadFragment = gql`
  fragment FeePayload on Fee {
    id
    amount
    amountType
    availableForAllOfferings
    billingCategory {
      id
    }
    counts {
      offerings
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    enabledByDefault
    order
    title
  }
`;

export const FeesQuery = gql`
  query fees($where: DefaultWhereInput) {
    fees(where: $where, orderBy: { order: asc }) {
      ...FeePayload
    }
  }
  ${FeePayloadFragment}
` as TypedDocumentNode<fees, feesVariables>;

export const CreateFeeMutation = gql`
  mutation createFee($data: FeeCreateInput!) {
    createFee(data: $data) {
      ...FeePayload
    }
  }
  ${FeePayloadFragment}
` as TypedDocumentNode<createFee, createFeeVariables>;

export const UpdateFeeMutation = gql`
  mutation updateFee($data: FeeUpdateInput!, $where: UniqueInput!) {
    updateFee(data: $data, where: $where) {
      ...FeePayload
    }
  }
  ${FeePayloadFragment}
` as TypedDocumentNode<updateFee, updateFeeVariables>;

export const DeleteFeeMutation = gql`
  mutation deleteFee($where: UniqueInput!) {
    deleteFee(where: $where) {
      ...FeePayload
    }
  }
  ${FeePayloadFragment}
` as TypedDocumentNode<deleteFee, deleteFeeVariables>;
