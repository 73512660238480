import { ReactNode } from "react";
import { useStore } from "src/stores/Store";
import { useUI } from "src/stores/UI";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  children?: ReactNode;
}

export const MenuContainer = (props: Props) => {
  const store = useStore();
  const ui = useUI();

  let top = 50;

  if (store.user?.isSalesOrAbove || store.isSuperAdminArea) {
    top += 30;
  }

  if (store.superAdmin) {
    top += 30;
  }

  return (
    <nav
      className={tw(
        "fixed bottom-0 left-0 z-30 w-[300px] transform overflow-y-auto bg-bgGray transition",
        ui.menuOpen ? "translate-x-0 shadow" : "-translate-x-full",
        props.className
      )}
      css={{
        top,
      }}
    >
      {props.children}
    </nav>
  );
};
