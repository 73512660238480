import moment from "moment-timezone";
import CouponModel from "src/models/CouponModel";
import GroupRegistrationModel from "src/models/GroupRegistrationModel";
import OrganizationModel from "src/models/OrganizationModel";
import { checkCouponData } from "src/utils/checkCouponData";
import {
  AmountType,
  coupons,
  Frequency,
  GroupRegistrationStatus,
} from "types/code-generator";

interface CalculateCouponsForInvoiceItemInput {
  coupons: coupons["coupons"];
  registration: GroupRegistrationModel;
  startDate: Date;
  organization?: OrganizationModel;
  chargeAmount: number;
}

export function calculateCouponsForCharge({
  coupons,
  registration,
  startDate,
  organization,
  chargeAmount,
}: CalculateCouponsForInvoiceItemInput) {
  const result: Array<{
    coupon: CouponModel;
    amount: number;
  }> = [];

  const activeCoupons = coupons.filter((c) =>
    checkCouponData({
      coupon: c,
    })
  );

  // console.log("activeCoupons", activeCoupons);

  const offering = registration.class;
  const price = chargeAmount;

  activeCoupons.forEach((coupon) => {
    let amount = 0;

    if (
      coupon.availableForAllOfferings ||
      (offering.coupons &&
        offering.coupons.find((ocoupon) => ocoupon.id === coupon.id))
    ) {
      if (coupon.amountType === AmountType.Dollar) {
        amount += coupon.amount;
      } else {
        amount += price * (coupon.amount / 100);
      }

      if (
        registration.status === GroupRegistrationStatus.Waitlist &&
        moment().isSame(startDate, "day")
      ) {
        amount = 0;
      }

      if (
        coupon.frequency === Frequency.OneTime &&
        moment().isBefore(startDate, "day")
      ) {
        amount = 0;
      }

      if (
        coupon.frequency === Frequency.Monthly &&
        coupon.applyAtCheckout === false
      ) {
        amount = 0;
      }

      if (amount >= price) {
        amount = price;
      }

      if (!Number.isInteger(amount)) {
        amount = Math.floor(amount);
      }

      result.push({ coupon, amount });
    }
  });

  // console.log("result", result);

  return result;
}
