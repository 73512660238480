import { intersection } from "lodash-es";
import moment from "moment-timezone";
import ClassModel from "src/models/ClassModel";
import DiscountModel from "src/models/DiscountModel";
import GroupRegistrationModel from "src/models/GroupRegistrationModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import OrganizationModel from "src/models/OrganizationModel";
import UserModel from "src/models/UserModel";
import calculateCouponsForRegistration from "src/utils/calculateCouponsForRegistration";
import calculateDiscountsForRegistration from "src/utils/calculateDiscountsForRegistration";
import { calculateMembershipRegistrationPrice } from "src/utils/calculateMembershipRegistrationPrice";
import calculateProratedRegistrationPrice from "src/utils/calculateProratedRegistrationPrice";
import { coupons, GroupRegistrationStatus } from "types/code-generator";
import { v4 as uuid } from "uuid";

interface GetInvoiceItemForGroupRegistrationInput {
  registration: GroupRegistrationModel;
  registrations: GroupRegistrationModel[];
  startDate: Date;
  discounts: Array<{
    discount: DiscountModel;
    ids: string[];
  }>;
  coupons: coupons["coupons"];
  organization: OrganizationModel;
  index?: number;
  atRegistration?: boolean;
  user: UserModel;
}

export default ({
  registration,
  registrations,
  startDate,
  discounts,
  coupons,
  organization,
  index = 0,
  atRegistration,
  user,
}: GetInvoiceItemForGroupRegistrationInput) => {
  let endDate = moment(startDate).tz(organization.timezone).endOf("month");

  if (registration.finalDate) {
    const finalDate = moment(registration.finalDate).tz(organization.timezone);
    if (finalDate.isAfter(startDate) && finalDate.isBefore(endDate)) {
      endDate = finalDate;
    }
  }

  let amount = calculateProratedRegistrationPrice({
    classItem: registration.class,
    endDate: endDate.toDate(),
    startDate,
    organization,
  });

  // console.log("amount", amount);

  if (registration.class?.priceAdjustments) {
    for (const pa of registration.class.priceAdjustments) {
      const paDate = moment(pa.date).tz(organization.timezone);

      if (
        paDate.isSame(startDate, "minute") ||
        (paDate.isBefore(startDate, "minute") &&
          registration.class.price !== pa.price)
      ) {
        amount = calculateProratedRegistrationPrice({
          classItem: new ClassModel({
            ...registration.class,
            price: pa.price,
          }),
          endDate: moment(startDate)
            .tz(organization.timezone)
            .endOf("month")
            .toDate(),
          startDate,
          organization,
        });
      }
    }
  }

  if (
    registration.class.membershipPrices.length > 0 &&
    user?.memberships?.length > 0
  ) {
    const membershipPrice = calculateMembershipRegistrationPrice({
      offering: registration.class,
      user,
      registration,
    });

    amount = calculateProratedRegistrationPrice({
      classItem: {
        ...registration.class,
        price: membershipPrice,
      } as ClassModel,
      endDate: endDate.toDate(),
      startDate,
      organization,
    });
  }

  if (registration.class?.chargeAmounts?.length && atRegistration) {
    let atRegistrationChargeAmount = registration.class?.chargeAmounts.find(
      (ca) => ca.atRegistration
    );

    for (const m of user.memberships) {
      const membershipAtRegistrationChargeAmount =
        registration.class?.chargeAmounts.find(
          (ca) =>
            ca.atRegistration && ca.membershipOffering?.id === m.offering?.id
        );

      if (
        membershipAtRegistrationChargeAmount?.price <
        atRegistrationChargeAmount?.price
      ) {
        atRegistrationChargeAmount = membershipAtRegistrationChargeAmount;
      }
    }

    if (atRegistrationChargeAmount && atRegistrationChargeAmount.price > 0) {
      amount = atRegistrationChargeAmount.price;
    }
  }

  if (registration.status === GroupRegistrationStatus.Waitlist) {
    amount = 0;
  }

  let description = registration.student
    ? `${registration.student.firstName}`
    : registration.roster?.map((rs) => rs.firstName).join(", ");

  if (registration.class && registration.class.program) {
    description += `: ${registration.class.program.title}`;
  }

  if (registration.status === GroupRegistrationStatus.Waitlist) {
    description += " (Waitlist)";
  }

  let registrationDiscounts: Array<{
    discount: DiscountModel;
    amount: number;
  }> = [];

  if (amount > 0) {
    registrationDiscounts = calculateDiscountsForRegistration({
      discounts: discounts
        .filter(
          (d) =>
            d.discount.availableForAllOfferings ||
            registration.class?.discounts?.find((cd) => cd.id === d.discount.id)
        )
        .filter((d) => {
          if (registration.student) {
            return (
              d.ids.includes(registration.id) ||
              d.ids.includes(registration.student.id)
            );
          } else if (registration.roster) {
            return (
              d.ids.includes(registration.id) ||
              intersection(
                d.ids,
                registration.roster.map((rs) => rs.id)
              ).length > 0
            );
          }
          return false;
        })
        .map((d) => d.discount),
      registration,
      registrations,
      startDate,
      organization,
      index,
    });
  }

  // console.log("registrationDiscounts", registrationDiscounts);

  let invoiceItem = new InvoiceItemModel({
    id: uuid(),
    amount,
    description,
    groupRegistration: registration,
    discounts: registrationDiscounts,
    coupons: [],
  });

  const registrationCoupons = calculateCouponsForRegistration({
    coupons,
    registration,
    startDate,
    organization,
    invoiceItem,
  });

  invoiceItem.coupons = registrationCoupons;

  return invoiceItem;
};
