import { ReactNode } from "react";
import tw from "src/utils/tw";

interface Props {
  type: "error" | "info" | "caution";
  description?: ReactNode | string;
  message: ReactNode | string;
  className?: string;
}

export function Alert(props: Props) {
  return (
    <div
      className={tw(
        "border",
        "px-4",
        "pt-3",
        "pb-4",
        "rounded-lg",
        "my-4",
        "flex",
        "border-error",
        "bg-paleError",
        props.type === "caution" && "border-main bg-paleMain",
        props.type === "info" && "border-action bg-paleAction",
        props.className
      )}
    >
      <div className="mr-4">
        <i
          className={tw(
            "far fa-triangle-exclamation text-error",
            props.type === "info" && "text-action",
            props.type === "caution" && "text-main"
          )}
        />
      </div>
      <div className="flex-1">
        <div className="font-bold">{props.message}</div>
        {props.description && <div>{props.description}</div>}
      </div>
    </div>
  );
}
