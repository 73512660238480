import moment from "moment-timezone";
import { TimeType } from "types/code-generator";

interface CheckOrganizationPaymentAlertsInput {
  organization: {
    chargeStartDate: Date;
    lastFailedPayment: Date;
    owner: {
      stripeCustomerId: string;
      stripeToken: any;
      balance: number;
    };
    paymentDeadline: Date;
    gracePeriod: number;
    gracePeriodType: TimeType;
  };
}

export function checkOrganizationPaymentAlerts(
  args: CheckOrganizationPaymentAlertsInput
) {
  const today = moment();
  const isCharging = args.organization.chargeStartDate;
  const hasPaymentMethod =
    args.organization.owner?.stripeCustomerId &&
    args.organization.owner?.stripeToken?.id;
  const hasBalance = args.organization.owner?.balance > 0;
  const paymentMethodDeadline =
    args.organization.paymentDeadline &&
    moment(args.organization.paymentDeadline);
  let balanceDeadline =
    args.organization.lastFailedPayment &&
    args.organization.gracePeriod &&
    moment(args.organization.lastFailedPayment);

  // console.log("balanceDeadline", balanceDeadline);

  const hasPaymentMethodAlert =
    isCharging && paymentMethodDeadline && !hasPaymentMethod;

  const hasBalanceAlert = isCharging && hasBalance;

  if (
    (hasPaymentMethodAlert || hasBalanceAlert) &&
    args.organization.gracePeriod
  ) {
    if (paymentMethodDeadline) {
      paymentMethodDeadline.add(
        args.organization.gracePeriod,
        args.organization
          .gracePeriodType as moment.unitOfTime.DurationConstructor
      );
    }

    if (balanceDeadline) {
      balanceDeadline.add(
        args.organization.gracePeriod,
        args.organization
          .gracePeriodType as moment.unitOfTime.DurationConstructor
      );
    }
  }

  // console.log("paymentMethodDeadline", paymentMethodDeadline?.format());
  // console.log("balanceDeadline", balanceDeadline?.format());

  const isSuspended =
    (hasPaymentMethodAlert &&
      paymentMethodDeadline &&
      paymentMethodDeadline.isBefore(today, "day")) ||
    (hasBalanceAlert &&
      balanceDeadline &&
      balanceDeadline.isBefore(today, "day"));

  return {
    hasPaymentMethodAlert,
    hasBalanceAlert,
    isSuspended,
    paymentMethodDeadline,
    balanceDeadline,
  };
}
