import CustomerRequestModel from "src/models/CustomerRequestModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import StudentInfoRequestResponseModel from "src/models/StudentInfoRequestResponseModel";
import { coupons, membership, MembershipStatus } from "types/code-generator";
import { EventModel } from "./EventModel";
import MembershipOfferingModel from "./MembershipOfferingModel";
import UserModel from "./UserModel";

export const isMembership = (item): item is MembershipModel =>
  item instanceof MembershipModel || item.nextInvoiceDate !== undefined;

class MembershipModel {
  accountOwner: UserModel = null;
  accountOwnerId: string;
  checkIns: membership["membership"]["checkIns"] = [];
  coupons: coupons["coupons"];
  createdAt: Date;
  createdBy: UserModel;
  customerRequest: CustomerRequestModel;
  dateApproved: Date;
  disableAutoRecharge: boolean = false;
  events: EventModel[] = [];
  finalDate: Date;
  id: string;
  invoiceItems: InvoiceItemModel[];
  nextInvoiceDate: Date;
  offering: MembershipOfferingModel = null;
  offeringId: string;
  roster: UserModel[] = [];
  status: MembershipStatus = MembershipStatus.Pending;
  studentInfoRequestResponses: StudentInfoRequestResponseModel[] = [];
  visits: number = 0;
  updatedAt: Date;

  constructor(data) {
    Object.assign(this, data);

    if (data?.offering) {
      this.offering = new MembershipOfferingModel(data.offering);
    }
  }
}

export default MembershipModel;
