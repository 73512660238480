import moment, { Moment } from "moment-timezone";
import ClassModel, { isClass } from "src/models/ClassModel";
import OfferingModel, { isOffering } from "src/models/OfferingModel";
import { Frequency } from "types/code-generator";

interface CalculateNumberOfClassesFromDateInput {
  classItem: ClassModel | OfferingModel;
  endDate: Moment;
  getTotalNumber?: boolean;
  startDate: Moment;
  organization: {
    timezone: string;
  };
}

export default ({
  classItem,
  endDate,
  getTotalNumber = false,
  startDate,
  organization,
}: CalculateNumberOfClassesFromDateInput) => {
  let numberOfDays = 0;
  let date = startDate.clone();
  let lastDate = endDate?.clone();

  if (
    startDate.isSame(lastDate, "day") &&
    classItem.classTimes &&
    classItem.classTimes.every(
      (ct) => ct.startTime === null && ct.endTime === null
    )
  ) {
    // This is a one day, all day class
    return 1;
  }

  if (classItem.frequency === Frequency.Custom) {
    const allDates = classItem.classTimes
      .filter((ct) => ct.date)
      .map((ct) => moment(ct.date).tz(organization.timezone));

    date = moment.min(allDates);
    lastDate = moment.max(allDates);

    if (date.isSame(lastDate, "day")) {
      return 1;
    }
  }

  while (date.isSameOrBefore(lastDate, "day")) {
    const day = date.format("dddd");

    let isExcludedDate = false;

    if (classItem.excludedDates && classItem.excludedDates.length > 0) {
      for (const d of classItem.excludedDates) {
        const excludedDate = moment(d).tz(organization.timezone).startOf("day");

        // console.log(
        //   "excludedDate",
        //   date.toISOString(),
        //   excludedDate.toISOString()
        // );

        if (excludedDate.isSame(date, "date")) {
          isExcludedDate = true;
        }
      }
    }

    // if (isExcludedDate) {
    //   console.log(
    //     "isExcludedDate",
    //     date.format(),
    //     numberOfDays,
    //     isExcludedDate
    //   );
    // }

    let classTimes = classItem.classTimes || [];

    // if (classTimes.length === 0) {
    //   classTimes = days.map((d) => new ClassTimeModel({ day: d.value }));
    // }

    for (const t of classTimes) {
      if (t.day === day || (t.date && moment(t.date).isSame(date, "day"))) {
        if (getTotalNumber) {
          numberOfDays++;
        } else if (
          classItem.frequency === Frequency.Custom &&
          !isExcludedDate
        ) {
          numberOfDays++;
        } else if (
          (isClass(classItem) &&
            (!classItem.prorateExcludedDates || !isExcludedDate)) ||
          isOffering(classItem)
        ) {
          const classStartTime = moment(t.startTime, "HH:mm");
          const classStartDateTime = moment(date).set({
            hour: classStartTime.get("hour"),
            minute: classStartTime.get("minute"),
            second: classStartTime.get("second"),
          });

          const classEndTime = moment(t.endTime, "HH:mm");
          const classEndDateTime = moment(date).set({
            hour: classEndTime.get("hour"),
            minute: classEndTime.get("minute"),
            second: classEndTime.get("second"),
          });

          // console.log(
          //   "classStartDateTime",
          //   classStartDateTime.format("YYYY-MM-DD h:mm a")
          // );

          // console.log(
          //   "classEndDateTime",
          //   classEndDateTime.format("YYYY-MM-DD h:mm a")
          // );

          if (
            startDate.isSameOrBefore(classStartDateTime) ||
            startDate.isBefore(classEndDateTime)
          ) {
            // console.log("date", date.toISOString());
            numberOfDays++;
          }

          // if (t.endTime && now.isSame(date, "day")) {
          //   const endTime = moment(t.endTime, "HH:mm");
          //   if (startDate.isSameOrBefore(endTime)) {
          //     numberOfDays++;
          //   }
          // } else {
          //   numberOfDays++;
          // }
        }
      }
    }

    date = date.add(1, "day");
  }

  return numberOfDays;
};
