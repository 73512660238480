import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import UserModel from "src/models/UserModel";
import { useStore } from "src/stores/Store";
import LinkButton from "../buttons/LinkButton";
import Container from "../layout/Container";

export default function ViewingAsHeader(props) {
  const store = useStore();
  const history = useHistory();

  function exit() {
    let domain = "captyn.com";

    if (process.env.NODE_ENV === "development") {
      domain = "local.localhost";
    }

    if (process.env.NEXT_PUBLIC_STAGE === "demo") {
      domain = "nytpac.com";
    }

    Cookies.remove("viewingAs", { domain });

    store.setCurrentUser({
      user: new UserModel(store.superAdmin),
      token: null,
    });

    store.setSuperAdmin(null);
    store.setUserLevel(store.superAdmin.level);

    if (Cookies.get("returnPathname")) {
      window.location.href = `${
        process.env.NEXT_PUBLIC_APP_SUPER_ADMIN
      }${Cookies.get("returnPathname")}`;
      Cookies.remove("returnPathname", { domain });
    } else {
      history.push("/super-admin");
    }
  }

  return (
    <div
      className="relative border-y border-borderGray bg-bgGray pt-1"
      css={{ height: 30 }}
    >
      <Container full>
        <div className="flex text-sm">
          <div className="flex-1">
            <strong>
              Viewing as {store.user?.firstName} {store.user?.lastName}
            </strong>
          </div>
          <div>
            <LinkButton onClick={exit}>
              <strong>Exit</strong> <i aria-hidden className="far fa-xmark" />
            </LinkButton>
          </div>
        </div>
      </Container>
    </div>
  );
}
