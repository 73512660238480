import {
  CssFontSource,
  CustomFontSource,
  StripeCardElementOptions,
} from "@stripe/stripe-js";
import { Day, Level, PermissionType, SettingType } from "types/code-generator";

export const hideHeader = [
  /\/account\/cleanup/,
  /\/account\/settings\/notifications\/[a-zA-Z]+/,
  /\/account\/students\/[a-z0-9]+\/profile\/edit/,
  /\/account\/agreements/,
  /\/account\/info-requests/,
  /\/account\/check-in\/[a-z0-9]+/,
  /\/admin\/agreements\/[a-z0-9]+/,
  /\/admin\/bulk-edit/,
  /\/admin\/bulk-register/,
  /\/admin\/usa-swimming/,
  /\/admin\/meets\/[a-z0-9]+\/edit/,
  /\/admin\/meets\/add/,
  /\/admin\/meets\/times\/import/,
  /\/admin\/meets\/[a-z0-9]+\/visibility/,
  /\/admin\/meets\/[a-z0-9]+\/details/,
  /\/admin\/meets\/[a-z0-9]+\/unknown-details/,
  /\/admin\/volunteers\/[a-z0-9]+\/visibility/,
  /\/admin\/volunteers\/add/,
  /\/admin\/classes\/[a-z0-9]+\/edit\/[\d]/,
  /\/admin\/custom-private-lessons\/[a-z0-9]+\/add/,
  /\/account\/custom-private-lessons\/[a-z0-9]+\/add/,
  /\/admin\/custom-private-lessons\/[a-z0-9]+\/post/,
  /\/admin\/custom-private-lessons\/[a-z0-9]+\/schedule/,
  /\/admin\/departments\/add/,
  /\/admin\/fees\/[a-z0-9]+/,
  /\/admin\/group-offerings\/[a-z0-9]+\/edit/,
  /\/admin\/group-offerings\/add/,
  /\/admin\/group-registrations\/[a-z0-9]+\/edit-info-requests/,
  /\/account\/group-registrations\/[a-z0-9]+\/edit-info-requests/,
  /\/account\/students\/[a-z0-9]+\/edit-info-requests/,
  /\/admin\/health-questions\/add/,
  /\/admin\/health-questions\/edit\/[a-z0-9]+/,
  /\/admin\/locations\/[a-z0-9]+/,
  /\/admin\/membership-offerings\/add/,
  /\/admin\/memberships\/[a-z0-9]+\/edit-info-requests/,
  /\/admin\/memberships\/[a-z0-9]+\/participant-details/,
  /\/admin\/memberships\/[a-z0-9]+\/add/,
  /\/account\/memberships\/[a-z0-9]+\/add/,
  /\/admin\/offerings\/[a-z0-9]+\/edit\/[\d]/,
  /\/admin\/offerings\/add/,
  /\/admin\/offerings\/add\/[\d]/,
  /\/admin\/pass-offerings\/add/,
  /\/admin\/passes\/add/,
  /\/admin\/private-lessons\/add/,
  /\/admin\/private-offerings\/[a-z0-9]+\/edit/,
  /\/admin\/private-offerings\/add/,
  /\/admin\/settings\/[a-z0-9]+\/addons\/[a-z0-9]+/,
  /\/admin\/settings\/[a-z0-9]+\/coupons\/[a-z0-9]+/,
  /\/admin\/settings\/[a-z0-9]+\/coupons\/add/,
  /\/admin\/settings\/[a-z0-9]+\/discounts\/[a-z0-9]+/,
  /\/admin\/settings\/[a-z0-9]+\/discounts\/add/,
  /\/admin\/settings\/[a-z0-9]+\/programs\/[a-z0-9]+/,
  /\/admin\/settings\/[a-z0-9]+\/student-info-requests\/[a-z0-9]+/,
  /\/admin\/settings\/[a-z0-9]+\/student-info-requests\/add/,
  /\/admin\/settings\/[a-z0-9]+\/student-info-requests\/edit\/[a-z0-9]+/,
  /\/admin\/setup\/department\/[a-z]+/,
  /\/admin\/setup\/organization\/[a-z]+/,
  /\/admin\/users\/[a-z0-9]+\/departments\/edit/,
  /\/admin\/users\/[a-z0-9]+\/edit/,
  /\/admin\/users\/[a-z0-9]+\/notifications\/[a-zA-Z]+/,
  /\/admin\/users\/[a-z0-9]+\/permissions\/edit/,
  /\/admin\/users\/[a-z0-9]+\/profile\/edit/,
  /\/admin\/users\/[a-z0-9]+\/access\/add/,
  /\/admin\/users\/add/,
  /\/admin\/users\/add\/[\d]/,
  /\/admin\/communications\/templates\/add/,
  /\/admin\/communications\/templates\/[a-z0-9]+\/edit/,
  /\/callback-stripe/,
  /\/cart/,
  /\/explore/,
  /\/message/,
  /\/edit-message/,
  /\/register/,
  /\/super-admin\/organizations\/[a-z0-9]+\/users\/add/,
  /\/super-admin\/organizations\/[a-z0-9]+\/users\/add\/[\d]/,
  /\/super-admin\/referrals\/add/,
  /\/super-admin\/referrals\/edit\/[a-z0-9]+/,
  /\/super-admin\/users\/add/,
  /\/super-admin\/organizations\/add/,
  /^\/admin\/checkout/,
  /^\/admin\/memberships\/[a-z0-9]+\/checkout/,
  /^\/account\/memberships\/[a-z0-9]+\/checkout/,
  /^\/admin\/departments\/[a-z0-9]+$/,
  /^\/checkout/,
];

export const swimAmericaHideHeader = [/\/search/, /\/results/];

export const showWideContainer = [
  /\/admin\/classes/,
  /\/admin\/private-lessons/,
  /\/admin\/group-offerings/,
  /\/admin\/pending/,
];

export const staffAdminRoutes = [
  /\/admin\/schedule/,
  /\/admin\/message/,
  /\/admin\/custom-private-lessons/,
  /\/admin\/users\/[a-z0-9]/,
  /\/admin\/meets/,
];

export const staffArea = [/\/account\/lessons/, /\/account\/lessons\/claim/];

export const dateFormat = "MMM D, YYYY";
export const dateFormatMed = "ddd, MMM D, YYYY";
export const dateFormatLong = "ddd, MMM D, YYYY [at] h:mm a";
export const meetTimeFormat = "m:ss.SS";
export const meetTimeShortFormat = "ss.SS";

export const days = [
  {
    value: Day.Sunday,
    label: "Su",
    short: "Sun",
  },
  {
    value: Day.Monday,
    label: "M",
    short: "Mon",
  },
  {
    value: Day.Tuesday,
    label: "Tu",
    short: "Tue",
  },
  {
    value: Day.Wednesday,
    label: "W",
    short: "Wed",
  },
  {
    value: Day.Thursday,
    label: "Th",
    short: "Thu",
  },
  {
    value: Day.Friday,
    label: "F",
    short: "Fri",
  },
  {
    value: Day.Saturday,
    label: "Sa",
    short: "Sat",
  },
];

export const months = [
  {
    name: "January",
    value: 0,
    abbreviation: "Jan",
  },
  {
    name: "February",
    value: 1,
    abbreviation: "Feb",
  },
  {
    name: "March",
    value: 2,
    abbreviation: "Mar",
  },
  {
    name: "April",
    value: 3,
    abbreviation: "Apr",
  },
  {
    name: "May",
    value: 4,
    abbreviation: "May",
  },
  {
    name: "June",
    value: 5,
    abbreviation: "Jun",
  },
  {
    name: "July",
    value: 6,
    abbreviation: "Jul",
  },
  {
    name: "August",
    value: 7,
    abbreviation: "Aug",
  },
  {
    name: "September",
    value: 8,
    abbreviation: "Sep",
  },
  {
    name: "October",
    value: 9,
    abbreviation: "Oct",
  },
  {
    name: "November",
    value: 10,
    abbreviation: "Nov",
  },
  {
    name: "December",
    value: 11,
    abbreviation: "Dec",
  },
];

export const stripeCCOptions: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: '"soleil", sans-serif',
      fontSize: "15px",
    },
  },
};

export const stripeFonts: (CssFontSource | CustomFontSource)[] = [
  {
    cssSrc: "https://use.typekit.net/dlm1ocu.css",
  },
];

export const offeringDateKeys = [
  "startDate",
  "endDate",
  "publishDate",
  "unpublishDate",
  "registrationOpen",
  "registrationClose",
];

export const offeringArrayKeys = [
  "coupons",
  "agreements",
  "studentInfoRequests",
  "addons",
  "instructors",
  "tags",
  "fees",
];

export const offeringObjectKeys = [
  "department",
  "additionalFeeBillingCategory",
  "program",
  "location",
  "zone",
  "instructor",
];

export const pagesToVisit = {
  settings: [
    "Communications",
    "Programs",
    // "Global Excluded Dates",
    "Billing Categories",
    "Coupons",
    "Discounts",
    "Annual Fee",
    "Add-ons",
    "Student Info Requests",
    "Advanced Settings",
    "Transaction Report",
  ],
};

export const defaultOrgColors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

export const ClassTypes = {
  Class: "Class",
  Camp: "Camp",
  Clinic: "Clinic",
  Event: "Event",
  Team: "Team",
  Training: "Training",
  Group: "Group",
};

export const OrganizationFilters = {
  Program: "Program",
  Location: "Location",
  Tag: "Tag",
  "Assigned Staff": "Assigned Staff",
  Age: "Age",
  "Day of Week": "Day of Week",
};

export const OrganizationFiltersKeys = {
  Program: "program",
  Location: "location",
  Tag: "tag",
  "Assigned Staff": "instructor",
  Age: "ages",
  "Day of Week": "day",
};

export const PrivateOfferingTypes = {
  "Private Lesson": "Private Lesson",
  "Private Session": "Private Session",
  "Private Instruction": "Private Instruction",
  "Private Training": "Private Training",
  "Personal Training": "Personal Training",
  Tutoring: "Tutoring",
  "One-on-one": "One-on-one",
};

// export const MembershipOfferingMonthlyTypes = {
//   Membership: "Membership",
// };

// export const MembershipOfferingOneTimeTypes = {
//   "Guest Pass": "Guest Pass",
//   "Drop-in": "Drop-in",
//   "Punch Card": "Punch Card",
// };

export const ParticipantTypes = {
  Participant: "Participant",
  Student: "Student",
  Athlete: "Athlete",
  Camper: "Camper",
  Attendee: "Attendee",
  Trainee: "Trainee",
};

export const MembershipOfferingTypes = {
  Membership: "Membership",
  "Registration Fee": "Registration Fee",
  "Annual Fee": "Annual Fee",
  "Limited Pass": "Limited Pass",
};

export const MembershipOfferingPassTypes = {
  "Special Access": "Special Access",
  "Drop In": "Drop In",
  "Guest Pass": "Guest Pass",
  "Punch Card": "Punch Card",
  "Try Out": "Try Out",
};

// export const MembershipOfferingParticipantTypes = {
//   Member: "Member",
//   Custom: "Custom",
// };

export const PermissionLevels = {
  [Level.AccountOwner]: {
    label: "Customer",
    value: Level.AccountOwner,
    description: "Access only to their own account.",
    permissions: [],
  },
  "Customer Account + Roster": {
    label: "Customer Account + Roster",
    value: PermissionType.ViewRoster,
    description:
      "Access only to their own account. This permission also grants read-only access to the Schedule & Roster. This permission is limited to the departments assigned to this user.",
    permissions: [PermissionType.ViewRoster],
  },
  [Level.Instructor]: {
    label: "Staff",
    value: Level.Instructor,
    description:
      "Staff accounts have access to their own account, plus management tools like Schedule & Roster. Staff are available to be assigned as the instructor of a class/camp/clinic/event, and have the ability to claim, be assigned to, and manage their own private lesson series. You may add further restrictions below.",
    permissions: [
      PermissionType.AssignInstructor,
      PermissionType.CancelClass,
      PermissionType.EditRoster,
      PermissionType.MoveRegistration,
      PermissionType.CancelRegistration,
    ],
  },
  [Level.Admin]: {
    label: "Administrator",
    value: Level.Admin,
    description:
      "Administrators have access to their own account, all staff features, plus complete access to all features available in Captyn for this organization. You may add further restrictions below.",
    permissions: [
      PermissionType.AccessAdvancedSetting,
      PermissionType.ExportData,
      PermissionType.ChangePermission,
      PermissionType.ManageDepartment,
    ],
  },
};

export const PermissionDescriptions = {
  [PermissionType.ViewRoster]: {
    label: "",
    description:
      "Access only to their own account. This permission also grants read-only access to the Schedule & Roster. This permission is limited to the departments assigned to this user.",
  },
  [PermissionType.AssignInstructor]: {
    label: "Manage staff assignments on the Today screen",
    description: "Add or remove staff on the daily roster.",
  },
  [PermissionType.CancelClass]: {
    label: "Cancel daily activity on the Today screen",
    description:
      "Cancel an activity on any given day. Also allows ability to send cancellation notification to participants.",
  },
  [PermissionType.EditRoster]: {
    label:
      "Add drop-ins, perform check ins, and take attendance on the Today screen",
    description:
      "Ability to manipulate the daily roster without affecting billing (example • split roster, add drop-in, check in, take attendance, etc.)",
  },
  [PermissionType.AccessAdvancedSetting]: {
    label: "Access to Settings & Reports",
    description:
      "Access global settings (i.e. merchant account, agreements, etc.)",
  },
  [PermissionType.ExportData]: {
    label: "Able to export data",
    description:
      "Able to export a file from various areas, including transactions, invoices, offerings, accounts, and participants.",
  },
  [PermissionType.ChangePermission]: {
    label: "Add or manage accounts & permissions",
    description: "Ability to edit permissions of any connected account.",
  },
  [PermissionType.ManageDepartment]: {
    label: "Manage departments & access",
    description: "Ability to edit departments assigned to any administrator.",
  },
  [PermissionType.MoveRegistration]: {
    label: "Move registrations",
    description:
      "Allows limited ability to charge/credit at the time of processing a move.",
  },
  [PermissionType.CancelRegistration]: {
    label: "Cancel registrations",
    description:
      "Allows limited ability to charge, credit, and refund at the time of processing a cancellation.",
  },
};

export const NotificationDescriptions = {
  [SettingType.NotificationAccount]:
    "Important alerts (cancellation, new lesson scheduled, etc.)",
  [SettingType.NotificationReminders]: "Reminders before a scheduled activity",
  [SettingType.NotificationMarketing]:
    "Marketing, offers, and promotional emails from {{organization}}",
  [SettingType.NotificationPrivateSeriePosted]:
    "When a one-on-one or semi-private registration is posted for claim",
  [SettingType.NotificationLessonTransferOrAssignment]:
    "When a one-on-one or semi-private registration is assigned to you",
  [SettingType.NotificationLessonsAdded]:
    "When a customer adds additional sessions to a registration that is assigned to you",
  [SettingType.NotificationCustomerCancellation]:
    "When a customer requests to cancel or reschedule a one-on-one or semi-private session",
  [SettingType.NotificationNewLesson]:
    "When a new registration or customer request has been received",
  [SettingType.OrganizationUpdates]:
    "Organization related events like first time signing in, Stripe connected, first registration received, etc.",
};

export const NotificationShortDescriptions = {
  [SettingType.NotificationAccount]: "Account Notifications",
  [SettingType.NotificationReminders]: "Activity Reminders",
  [SettingType.NotificationNewLesson]: "New Registration/Customer Request",
  [SettingType.NotificationCustomerCancellation]: "Customer Cancel/Reschedule",
  [SettingType.NotificationMarketing]: "Marketing",
  [SettingType.NotificationLessonTransferOrAssignment]:
    "Series Transfer/Assign",
  [SettingType.NotificationPrivateSeriePosted]: "Series Posted",
  [SettingType.NotificationLessonsAdded]: "Lessons Added",
};

export const levels = {
  [Level.Instructor]: "Staff",
  [Level.AccountOwner]: "Account Owner",
  [Level.Admin]: "Administrator",
  [Level.SuperAdmin]: "Super Admin",
  [Level.Student]: "Participant",
  [Level.Sales]: "Sales",
};

export const capacities = {
  full: "Offering Full",
  waitlist: "Holding Waitlist",
  empty: "Empty Offering",
  available: "Spots Available",
  soon: "Available Soon",
};

export const enum RequiredFields {
  AccountPhoneNumber = "AccountPhoneNumber",
  AccountPhoneNumberRequired = "AccountPhoneNumberRequired",
  AccountAddress = "AccountAddress",
  AccountAddressRequired = "AccountAddressRequired",
  ParticipantGender = "ParticipantGender",
  ParticipantGenderRequired = "ParticipantGenderRequired",
  ParticipantBirthdate = "ParticipantBirthdate",
  ParticipantBirthdateRequired = "ParticipantBirthdateRequired",
  ParticipantEmergencyContact = "ParticipantEmergencyContact",
  ParticipantEmergencyContactRequired = "ParticipantEmergencyContactRequired",
  ParticipantPhoto = "ParticipantPhoto",
  ParticipantPhotoRequired = "ParticipantPhotoRequired",
}

export const requiredFields = {
  [RequiredFields.AccountPhoneNumber]: "Phone Number",
  [RequiredFields.AccountAddress]: "Address",
  [RequiredFields.ParticipantGender]: "Gender",
  [RequiredFields.ParticipantBirthdate]: "Birthdate",
  [RequiredFields.ParticipantEmergencyContact]: "Emergency Contact",
  [RequiredFields.ParticipantPhoto]: "Photo",
};

export const settingGroups = {
  [Level.AccountOwner]: [
    SettingType.NotificationAccount,
    SettingType.NotificationMarketing,
  ],
  [Level.Instructor]: [
    SettingType.NotificationPrivateSeriePosted,
    SettingType.NotificationLessonTransferOrAssignment,
    SettingType.NotificationLessonsAdded,
    SettingType.NotificationCustomerCancellation,
  ],
  [Level.Admin]: [SettingType.NotificationNewLesson],
  [Level.SuperAdmin]: [SettingType.OrganizationUpdates],
};

export const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_KEY}&libraries=places&callback=console.log`;

export const enum NotificationMarketingType {
  EmailSms = "EmailSms",
  Email = "Email",
  Sms = "Sms",
}

export const hexRegex = /^[0-9A-Fa-f]{6}$/;

export const enum FileTypes {
  OfferingImport = "OfferingImport",
  UserImport = "UserImport",
  EnrollmentImport = "EnrollmentImport",
  MembershipImport = "MembershipImport",
}

export const SmsRecipientLimit = 1000;

export const SwimAmericaDomains = ["swimamerica", "swimamerica-dev"];
