import { ReactNode } from "react";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  selected?: boolean;
  recentlyCreated?: boolean;
  noBorder?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  id?: string;
}

export default function ItemCard(props: Props) {
  return (
    <div
      id={props.id}
      className={tw(
        props.loading && !props.noBorder
          ? "border-y"
          : !props.noBorder && "border",
        props.selected ? "border-organization" : "border-borderGray",
        props.noBorder || props.loading ? "rounded-none" : "rounded-lg",
        props.noBorder ? "p-0" : "p-4",
        props.recentlyCreated ? "bg-mainLightest" : "bg-white",
        "mx-auto",
        props.loading ? "my-1" : "mb-2",
        // props.loading ? "max-w-none" : "max-w-md",
        "transition",
        "relative",
        props.className
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
