import { gql } from "graphql-tag";
import { withApiMutation } from "src/stores/Api";
import { sendEmail, sendEmailVariables } from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const SendEmailMutation = gql`
  mutation sendEmail($data: SendEmailInput!) {
    sendEmail(data: $data) {
      success
    }
  }
` as TypedDocumentNode<sendEmail, sendEmailVariables>;

export const SendEmail = withApiMutation(SendEmailMutation, {
  name: "sendEmail",
});
