import { gql } from "graphql-tag";
import { CouponPayloadFragment } from "src/api/Coupon";
import { withApi, withApiMutation } from "src/stores/Api";
import { getSearchParams } from "src/utils/useSearchParams";
import {
  createOffering,
  createOfferingVariables,
  filteredOfferings,
  filteredOfferingsVariables,
  offering,
  offeringVariables,
  updateOffering,
  updateOfferingVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { DepartmentPayloadFragment } from "./Department";

const OfferingPayloadFragment = gql`
  fragment OfferingPayload on Offering {
    id
    additionalFeeAmount
    additionalFeeBillingCategory {
      id
    }
    additionalFeeTitle
    addons {
      id
      title
      description
      billingCategory {
        id
        title
      }
      price
    }
    agreements {
      id
      title
      description
      required
    }
    allowAdditionalStudents
    allowExtension
    allowNonMembers
    applyAnnualFee
    availableDays
    billingCategory {
      id
      title
    }
    customerInfoRequests
    charges {
      id
      amount
      description
      billingCategory {
        id
        title
      }
    }
    credits {
      id
      amount
      description
      billingCategory {
        id
        title
      }
    }
    classTimes {
      id
      atAccountHome
      date
      day
      endTime
      location {
        id
        address1
        city
        state
        title
        zip
      }
      startTime
      zone {
        id
        title
      }
    }
    collectTaxes
    communications {
      id
      ruleAmount
      ruleAmountType
    }
    coupons {
      ...CouponPayload
    }
    customerInfoRequests
    department {
      ...DepartmentPayload
    }
    description
    discounts {
      id
      amount
      min
      max
    }
    duration
    endDate
    excludedDates
    extensionDiscounts {
      id
      amount
      min
      max
    }
    extensionFreePresentation
    extensionMinAttendances
    extensionPrice
    extensionPriceAdjustments {
      id
      min
      price
    }
    fees {
      id
      amount
      amountType
      title
    }
    firstLessonStartDays
    firstLessonStart
    freePresentation
    frequency
    hideDefaultStaff
    instructor {
      id
      email
      firstName
      lastName
    }
    location {
      id
      title
    }
    maxAge
    maxAgeType
    maxInitial
    maxParticipants
    membershipOfferings {
      id
    }
    minAge
    minAgeType
    minAttendances
    minParticipants
    multiClassDiscount
    multiStudentDiscount
    order
    package
    participantType
    paymentFrequency
    price
    priceAdjustments {
      id
      min
      price
    }
    priceForAdditionalStudents
    program {
      id
      billingCategory {
        id
        title
      }
      description
      title
    }
    publishDate
    publishOnWebsite
    registrationClose
    registrationOpen
    requestStartDate
    restrictReschedule
    restrictRescheduleType
    startDate
    status
    studentInfoRequests {
      id
      answers
      description
      noQuestion
      order
      required
      slug
      type
      yesQuestion
    }
    tags {
      id
      title
    }
    template
    title
    type
    unpublishDate
  }
  ${CouponPayloadFragment}
  ${DepartmentPayloadFragment}
`;

export const UpdateOfferingMutation = gql`
  mutation updateOffering($data: OfferingCreateInput!, $where: UniqueInput!) {
    updateOffering(data: $data, where: $where) {
      ...OfferingPayload
    }
  }
  ${OfferingPayloadFragment}
` as TypedDocumentNode<updateOffering, updateOfferingVariables>;

export const DeleteOfferingMutation = gql`
  mutation deleteOffering($id: String!) {
    deleteOffering(where: { id: $id }) {
      id
    }
  }
`;

export const CreateOfferingMutation = gql`
  mutation createOffering($data: OfferingCreateInput!) {
    createOffering(data: $data) {
      ...OfferingPayload
    }
  }
  ${OfferingPayloadFragment}
` as TypedDocumentNode<createOffering, createOfferingVariables>;

export const FilteredOfferingsQuery = gql`
  query filteredOfferings(
    $where: OfferingWhereInput!
    $filter: String
    $skip: Int
    $take: Int
  ) {
    offerings(where: $where, filter: $filter, take: $take, skip: $skip) {
      ...OfferingPayload
    }
  }
  ${OfferingPayloadFragment}
` as TypedDocumentNode<filteredOfferings, filteredOfferingsVariables>;

export const OfferingQuery = gql`
  query offering($id: String!) {
    offering(where: { id: $id }) {
      ...OfferingPayload
    }
  }
  ${OfferingPayloadFragment}
` as TypedDocumentNode<offering, offeringVariables>;

const FilteredOfferingsCountsQuery = gql`
  query filteredOfferingsCounts($where: OfferingWhereInput!, $filter: String) {
    offeringsCounts(where: $where, filter: $filter) {
      count
      total
      registrations
      participants
      active
      pending
      abandoned
      closed
    }
  }
`;

export const QueryFilteredOfferingsCounts = withApi(
  FilteredOfferingsCountsQuery,
  {
    name: "queryFilteredOfferingsCounts",
    skip: ({ match }: any) => {
      return match.params.tab !== "offerings";
    },
    options: ({ store }: any) => {
      const countParams = getSearchParams(["sort", "order"]);

      return {
        variables: {
          where: { organizationId: store.organization.id },
          filter: countParams.toString(),
        },
      };
    },
  }
);

export const UpdateOffering = withApiMutation(UpdateOfferingMutation, {
  name: "updateOffering",
});

export const DeleteOffering = withApiMutation(DeleteOfferingMutation, {
  name: "deleteOffering",
});
