import { ElementProps, ElementsConsumer } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";

export const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

export interface WithStripeProps {
  stripe: Stripe;
  elements: ElementProps;
}

export function withStripe(Component) {
  return function WithStripeComponent(props) {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Component {...props} stripe={stripe} elements={elements} />
        )}
      </ElementsConsumer>
    );
  };
}
