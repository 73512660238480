import styled from "@emotion/styled";
import colors from "src/utils/colors";

export default styled("div")`
  border: 1px solid ${colors.borderGray};
  border-radius: 6px;
  height: 44px;
  padding: 0.8rem 1rem 0 1rem;
  margin: 0 0 1.5rem 0;
  background: ${colors.bgGray};
`;
