import { ReactNode } from "react";
import { useMergeState } from "src/utils/useMergeState";
import { FormMethods, useRCForm } from "src/utils/useRCForm";

interface CustomFormUtils {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  state: object;
  setState: (state: object) => void;
}

export type UseFormHook<V = any> = CustomFormUtils & FormMethods<V>;

export interface WithFormProps<V = any> {
  form: UseFormHook<V>;
}

export function useForm<V = any>(...args): UseFormHook<V> {
  const [state, setState] = useMergeState({ isSubmitting: false });
  const form = useRCForm<V>(...args);
  return {
    ...form,
    state,
    setState,
    isSubmitting: state.isSubmitting,
    setIsSubmitting: (isSubmitting: boolean) => setState({ isSubmitting }),
  };
}

export function withForm(Component) {
  return function WithFormComponent(props) {
    const form = useForm();
    return <Component {...props} form={form} />;
  };
}

interface FormProps {
  onSubmit: () => void;
  children: ReactNode;
  className?: string;
}

export function Form(props: FormProps) {
  function onSubmit(e) {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit();
    }
  }

  return (
    <form onSubmit={onSubmit} className={props.className}>
      {props.children}
    </form>
  );
}
