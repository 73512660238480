import React, { ReactNode, Ref } from "react";
import tw from "src/utils/tw";

interface Props {
  onChange?: (value) => void;
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  small?: boolean;
  disabled?: boolean;
  multiLine?: boolean;
  // value?: string;
}

function InputCheckbox(props: Props, ref: Ref<any>) {
  function onChange(e) {
    if (props["data-__meta"]) {
      props.onChange(e);
    } else if (props.onChange) {
      props.onChange(e.target.checked);
    }
  }

  const outerSize = props.small
    ? { width: 31, height: 31 }
    : { width: 41, height: 41 };
  const innerSize = props.small
    ? { width: 16, height: 16 }
    : { width: 18, height: 18 };

  return (
    <div className="align-top">
      <label
        // htmlFor={props.value.toString()}
        className={tw("flex", "cursor-pointer", props.className)}
      >
        <div
          css={outerSize}
          className={tw(
            "relative",
            props.children ? (props.small ? "mr-2" : "mr-4") : ""
          )}
        >
          <input
            type="checkbox"
            checked={props.checked}
            // id={props.value.toString()}
            onChange={onChange}
            disabled={props.disabled}
            ref={ref}
            className={tw(
              "appearance-none",
              "p-0",
              "m-0",
              "w-full",
              "h-full",
              "border",
              "rounded",
              "transition",
              !props.disabled && "hover:border-dark",
              !props.disabled && "cursor-pointer",
              props.disabled ? "bg-bgGray" : "bg-white",
              props.checked && !props.disabled
                ? "border-dark"
                : "border-borderGray"
            )}
          />
          <i
            className={tw(
              "pointer-events-none",
              "far fa-check",
              props.small ? "text-lg" : "text-xl",
              "absolute",
              "bgs-error",
              "leading-none",
              // "z-10",
              "left-0",
              "top-0",
              "right-0",
              "bottom-0",
              "m-auto",
              "transition",
              "transform",
              "text-organization",
              props.checked ? "scale-100 opacity-100" : "scale-50 opacity-0",
              props.disabled && "text-borderGray"
            )}
            css={{
              ...innerSize,
            }}
          />
        </div>
        <div
          className={tw(
            "flex-1 transition",
            props.disabled && "opacity-50",
            !props.multiLine && "truncate"
          )}
        >
          {props.children}
        </div>
      </label>
    </div>
  );
}

export default React.forwardRef(InputCheckbox);
