import { BorderButton } from "src/components/buttons/BorderButton";
import { DepartmentSelectModal } from "src/components/modals/DepartmentSelectModal";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { modals } from "src/utils/modals";

interface Props {}

export function DepartmentFilter(props: Props) {
  const session = useSession();
  const store = useStore();

  function showDepartmentSelect() {
    modals.show(DepartmentSelectModal);
  }

  let label = "All Departments";
  4;
  const departments = session.departments.filter(
    (d) => d.organization.id === store.organization.id
  );

  if (
    (!store.user?.isSalesOrAbove &&
      departments.length > 0 &&
      departments.length < store.user?.departments.length) ||
    (store.user?.isSalesOrAbove &&
      departments.length < store.organization.departments.length)
  ) {
    label = departments.map((d) => d.title).join(", ");
  }

  if (label.length > 40) {
    label = `${departments.length} Department${
      departments.length !== 1 ? "s" : ""
    }`;
  }

  if (departments.length === 0) {
    label = "No Departments";
  }

  return (
    <div className="mx-4 mb-4">
      <BorderButton
        className="flex w-full items-center gap-3 text-left"
        onClick={showDepartmentSelect}
        bgColor="#fff"
      >
        <div>
          <i aria-hidden className="far fa-filter text-organization" />
        </div>
        <div className="flex-1 truncate">{label}</div>
      </BorderButton>
    </div>
  );
}
