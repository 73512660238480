import calculateInvoiceItemBalance, {
  Balance,
} from "src/utils/calculateInvoiceItemBalance";
import InvoiceModel from "../../src/models/InvoiceModel";

const startingBalance: Balance = {
  totalBillable: 0,
  totalCaptyn: 0,
  totalCard: 0,
  totalCardRefund: 0,
  totalCash: 0,
  totalCashRefund: 0,
  totalClassDiscounts: 0,
  totalCoupons: 0,
  totalCredits: 0,
  totalDiscounts: 0,
  totalEnrollments: 0,
  totalFees: 0,
  totalLineItemFees: 0,
  totalOwed: 0,
  totalPaid: 0,
  totalRefunded: 0,
  totalStudentDiscounts: 0,
  totalTaxes: 0,
};

export default (invoice: InvoiceModel): Balance => {
  if (!invoice.invoiceItems) {
    return { ...startingBalance };
  }

  const invoiceItemTotals = invoice.invoiceItems.map((i) =>
    calculateInvoiceItemBalance(i)
  );

  const totals = invoiceItemTotals.reduce(
    (acc, t) => {
      for (const k in t) {
        acc[k] = (acc[k] || 0) + t[k];
      }
      return acc;
    },
    { ...startingBalance }
  );

  if (totals.totalOwed < 0) {
    totals.totalOwed = 0;
  }

  return totals;
};
