import { css } from "@emotion/react";

export const sm = "max-width: 47.9375em";
export const md = "min-width: 48em";
export const lg = "min-width: 64em";
export const semiBold = 600;

export const cf = css`
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;
