import { capitalize } from "lodash-es";
import { Link, useHistory } from "react-router-dom";
import Container from "src/components/layout/Container";
import { FilterUsersModal } from "src/components/modals/FilterUsersModal";
import { OrganizationsModal } from "src/components/modals/OrganizationsModal";
import { useStore } from "src/stores/Store";
import { useUI } from "src/stores/UI";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import { Level } from "types/code-generator";

interface Props {}

export function SuperAdminBar(props: Props) {
  const store = useStore();

  function showOrganizations() {
    modals.show(OrganizationsModal);
  }

  const stage = process.env.NEXT_PUBLIC_STAGE;

  let stageLabel = (
    <>
      <strong>{capitalize(process.env.NEXT_PUBLIC_STAGE)} • Super Admin</strong>{" "}
      <i aria-hidden className="far fa-circle-arrow-right" />
    </>
  );

  if (process.env.NEXT_PUBLIC_STAGE === "prod") {
    stageLabel = (
      <>
        <strong>Production • Super Admin</strong>{" "}
        <i aria-hidden className="far fa-circle-arrow-right" />
      </>
    );
  } else if (store.isDemo) {
    stageLabel = (
      <>
        <strong>Demo</strong>
      </>
    );
  }

  let content = (
    <>
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <button
            className="flex w-full items-center gap-1.5 truncate"
            onClick={showOrganizations}
          >
            <strong>
              {store.organization && store.organization.title}{" "}
              {store.isDemo && store.organization.title !== "Demo" && " (Demo)"}
            </strong>
            <i aria-hidden className="far fa-arrows-cross mt-1" />
          </button>
        </div>
        {store.user && store.user.isSalesOrAbove && (
          <a
            href={`${process.env.NEXT_PUBLIC_APP_SUPER_ADMIN}/super-admin`}
            className="inline-block flex-1 truncate text-right text-white"
            type="button"
          >
            {stageLabel}
          </a>
        )}
      </div>
    </>
  );

  return (
    <div
      className={tw(
        "z-40 h-[30px] pt-1 text-sm text-white shadow-sm",
        store.isDemo
          ? "bg-dark"
          : stage === "dev"
            ? "bg-main"
            : "bg-organization"
      )}
    >
      <Container full>{content}</Container>
    </div>
  );
}

export function SuperAdminHeader(props: Props) {
  const ui = useUI();
  const history = useHistory();

  // const countsQuery = useApi(OrganizationsCountsQuery, {
  //   variables: {
  //     where: {},
  //   },
  // });

  // const counts = countsQuery.organizationsCounts;

  function toggleMenu() {
    ui.toggleMenu();
  }

  function selectUser(userProfile) {
    if (userProfile) {
      history.push(`/super-admin/users/${userProfile.id}`);
    }
  }

  function searchForUsers() {
    modals.show(FilterUsersModal, {
      levels: [Level.Captyn],
      handleOk: (user) => {
        selectUser(user);
      },
    });
  }

  return (
    <>
      <div className="fixed left-0 top-0 z-40 w-full">
        <SuperAdminBar />
      </div>
      <div className="fixed left-0 top-0 z-20 h-[80px] w-full bg-dark shadow">
        <Container full className="h-[50px]">
          <div className="flex h-full items-center pt-[55px]">
            <div className="flex flex-1 items-center gap-2">
              <button
                className="-mt-1 w-7 text-left text-2xl text-main"
                onClick={toggleMenu}
              >
                {ui.menuOpen ? (
                  <i aria-hidden className="far fa-xmark" />
                ) : (
                  <i aria-hidden className="far fa-bars" />
                )}
              </button>
              <Link to="/super-admin" className="flex gap-2">
                <img
                  src="/img/captyn-logo-white.svg"
                  alt="Captyn"
                  width="91"
                  height="16"
                />
                <div className="rounded bg-success px-2 pb-1 pt-0.5 text-xs font-bold uppercase text-white">
                  Super
                </div>
              </Link>
            </div>

            <button
              onClick={searchForUsers}
              className="h-9 w-9 rounded-full bg-white text-organization"
            >
              <i aria-hidden className="far fa-magnifying-glass" />
            </button>

            {/* {counts?.organizationsPending > 0 && (
              <>
                <Link
                  to={`/super-admin/organizations?status=Pending`}
                  className="ml-2 flex h-9 w-9 items-center justify-center rounded-full bg-organization text-white"
                >
                  {counts.organizationsPending}
                </Link>
              </>
            )} */}
          </div>
        </Container>
      </div>
    </>
  );
}

// const OrganizationsCountsQuery = gql`
//   query pendingOrganizationsCounts(
//     $where: OrganizationWhereInput
//     $filter: String
//   ) {
//     organizationsCounts(where: $where, filter: $filter) {
//       organizationsPending
//     }
//   }
// ` as TypedDocumentNode<
//   pendingOrganizationsCounts,
//   pendingOrganizationsCountsVariables
// >;
