import ItemCard from "src/components/items/item/ItemCard";
import Modal, { ModalProps } from "src/components/modals/Modal";
import UserModel from "src/models/UserModel";
import { useStore } from "src/stores/Store";
import { modals, useModal } from "src/utils/modals";
import tw from "src/utils/tw";
import { Level, user } from "types/code-generator";

interface Props extends ModalProps {}

export const ProfileSwitchModal = modals.create((props: Props) => {
  const modal = useModal();
  const store = useStore();

  function switchProfile(profile: user["user"]["profile"]["profiles"][0]) {
    let url = `https://${profile.organization.subdomain}.captyn.com`;

    if (process.env.NODE_ENV === "development") {
      url = `https://${profile.organization.subdomain}.local.localhost:3000`;
    } else if (process.env.NEXT_PUBLIC_STAGE === "demo") {
      url = `https://${profile.organization.subdomain}.nytpac.com`;
    }

    window.location.href = url;
  }

  const profiles = [
    new UserModel({
      ...store.user,
      organization: store.organization,
    }),
    ...store.user.profile.profiles.filter(
      (p) => p.organization.id !== store.organization.id
    ),
  ];

  return (
    <Modal
      visible={modal.visible}
      title="Switch Organization"
      afterClose={modal.remove}
      handleCancel={modal.hide}
    >
      {profiles.map((p) => {
        const user = new UserModel(p);
        const isCurrentOrg = p.organization.id === store.organization.id;
        let level = p.level.toString();

        if (user.isAdminOrAbove) {
          level = store.organization.terminology.administrator;
        } else if (user.isInstructor) {
          level = store.organization.terminology.staff;
        } else if (user.level === Level.AccountOwner) {
          level = "Customer";
        }

        return (
          <div key={p.id}>
            <ItemCard
              className="flex cursor-pointer items-center gap-2 overflow-hidden border-b-3 p-0 hover:border-darkGray"
              onClick={isCurrentOrg ? undefined : () => switchProfile(p)}
            >
              <div
                className={tw(
                  "flex h-16 w-16 items-center justify-center",
                  !p.organization.logo && "bg-organization",
                  p.organization.logo && "border-r border-borderGray"
                )}
              >
                {p.organization.logo && (
                  <img
                    src={p.organization.logo.url}
                    className="h-full w-full object-cover"
                  />
                )}
                {!p.organization.logo && (
                  <i className="far fa-medal text-xl text-white opacity-50" />
                )}
              </div>
              <div className="flex-1 leading-tight">
                <strong
                  css={{
                    color: p.organization.color,
                  }}
                >
                  {p.organization.title}
                </strong>
                <div className="text-sm">{level}</div>
              </div>
            </ItemCard>

            {isCurrentOrg && <hr />}
          </div>
        );
      })}
    </Modal>
  );
});
