import gql from "graphql-tag";
import {
  createReferral,
  createReferralTemplate,
  createReferralTemplateVariables,
  createReferralVariables,
  deleteReferral,
  deleteReferralTemplate,
  deleteReferralTemplateVariables,
  deleteReferralVariables,
  referral,
  referralTemplate,
  referralTemplates,
  referralTemplatesVariables,
  referralTemplateVariables,
  referralVariables,
  updateReferral,
  updateReferralTemplate,
  updateReferralTemplateVariables,
  updateReferralVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const ReferralTemplatePayloadFragment = gql`
  fragment ReferralTemplatePayload on ReferralTemplate {
    description
    firstReferralDeadline
    id
    learnMore
    milestones {
      id
      title
      amount
    }
    stopShowing
    title
  }
`;

export const ReferralPayloadFragment = gql`
  fragment ReferralPayload on Referral {
    id
    name
    email
    milestoneProgress
    note
    payouts {
      id
      amount
      by {
        id
        firstName
        lastName
      }
    }
    referralTemplate {
      title
      milestones {
        id
        amount
        title
      }
    }
    user {
      id
      email
      firstName
      lastName
      organization {
        id
        logo {
          url
        }
        title
      }
    }
  }
`;

export const ReferralTemplatesQuery = gql`
  query referralTemplates($where: DefaultWhereInput) {
    referralTemplates(where: $where) {
      ...ReferralTemplatePayload
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<referralTemplates, referralTemplatesVariables>;

export const ReferralTemplateQuery = gql`
  query referralTemplate($where: UniqueInput!) {
    referralTemplate(where: $where) {
      ...ReferralTemplatePayload
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<referralTemplate, referralTemplateVariables>;

export const DeleteReferralTemplateMutation = gql`
  mutation deleteReferralTemplate($where: UniqueInput!) {
    deleteReferralTemplate(where: $where) {
      ...ReferralTemplatePayload
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<deleteReferralTemplate, deleteReferralTemplateVariables>;

export const CreateReferralTemplateMutation = gql`
  mutation createReferralTemplate($data: ReferralTemplateCreateInput!) {
    createReferralTemplate(data: $data) {
      ...ReferralTemplatePayload
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<createReferralTemplate, createReferralTemplateVariables>;

export const UpdateReferralTemplateMutation = gql`
  mutation updateReferralTemplate(
    $data: ReferralTemplateCreateInput!
    $where: UniqueInput!
  ) {
    updateReferralTemplate(data: $data, where: $where) {
      ...ReferralTemplatePayload
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<updateReferralTemplate, updateReferralTemplateVariables>;

export const CreateReferralMutation = gql`
  mutation createReferral($data: ReferralCreateInput!) {
    createReferral(data: $data) {
      ...ReferralPayload
    }
  }
  ${ReferralPayloadFragment}
` as TypedDocumentNode<createReferral, createReferralVariables>;

export const UpdateReferralMutation = gql`
  mutation updateReferral($data: ReferralCreateInput!, $where: UniqueInput!) {
    updateReferral(data: $data, where: $where) {
      ...ReferralPayload
    }
  }
  ${ReferralPayloadFragment}
` as TypedDocumentNode<updateReferral, updateReferralVariables>;

export const DeleteReferralMutation = gql`
  mutation deleteReferral($where: UniqueInput!) {
    deleteReferral(where: $where) {
      ...ReferralPayload
    }
  }
  ${ReferralPayloadFragment}
` as TypedDocumentNode<deleteReferral, deleteReferralVariables>;

export const ReferralQuery = gql`
  query referral($where: UniqueInput!) {
    referral(where: $where) {
      ...ReferralPayload
    }
  }
  ${ReferralPayloadFragment}
` as TypedDocumentNode<referral, referralVariables>;
