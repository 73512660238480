import { Transition, TransitionChild } from "@headlessui/react";
import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { BorderButton } from "src/components/buttons/BorderButton";
import { ModalProps } from "src/components/modals/Modal";
import { md, sm } from "src/styles/utility";
import colors from "src/utils/colors";
import { isServer } from "src/utils/isServer";
import tw from "src/utils/tw";
import { useMediaQueries } from "src/utils/useMediaQueries";

export function ReferralModal(props: ModalProps) {
  const container = useMemo(() => {
    return isServer
      ? null
      : document.body.appendChild(document.createElement("div"));
  }, []);

  useEffect(() => {
    return () => {
      container?.remove();
    };
  }, []);

  return ReactDOM.createPortal(<ModalBody {...props} />, container);
}

function ModalBody(props: ModalProps) {
  const { visualHeight, small } = useMediaQueries();

  let height;
  let maxHeight = "calc(100vh - 40px)";

  if (small) {
    maxHeight = "calc(100vh - 100px - env(safe-area-inset-bottom))";
  }

  if (props.large) {
    height = `calc(${visualHeight}px - 2rem - env(safe-area-inset-bottom))`;
    maxHeight = "none";
  } else if (props.fixedHeight) {
    if (small) {
      if (visualHeight > 500) {
        height = `calc(${visualHeight}px - env(safe-area-inset-bottom))`;
      }
    } else {
      height = `calc(${visualHeight * 0.95}px - env(safe-area-inset-bottom))`;
      maxHeight = "1000px";
    }
  }

  return (
    <Transition
      show={props.visible}
      className={tw("fixed inset-0 z-40", props.className)}
      afterLeave={props.afterClose}
      as="div"
    >
      <div
        className={tw("min-h-screen", !props.large && "px-4", "text-center")}
      >
        <TransitionChild
          className="fixed inset-0 bg-dark bg-opacity-90 transition duration-200 data-[closed]:opacity-0"
          onClick={
            props.hideClose || props.disabled ? undefined : props.handleCancel
          }
          as="div"
        />

        <span
          className="inline-block h-screen align-middle sm:align-top"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          className={tw(
            props.large ? "max-w-7xl align-top" : "max-w-lg align-middle",
            !props.fixedHeight && "mt-4",
            "inline-block w-full transform text-left transition-all duration-200 data-[closed]:scale-95 data-[closed]:opacity-0"
          )}
          as="div"
        >
          <div
            className={tw(
              "bg-[#163670]",
              !props.large && "rounded-xl shadow-xl",
              "overflow-hidden",
              "flex",
              "flex-col",
              "xl:rounded-xl"
            )}
            css={{
              minHeight: 400,
              maxHeight,
              [`@media (${md})`]: {
                height,
                maxHeight,
              },
              [`@media (${sm})`]: {
                minHeight: 0,
                maxHeight,
                height,
              },
            }}
          >
            <div className="flex items-center bg-dark">
              <div className="flex-1 px-4 py-4 text-lg font-bold text-white">
                {props.title}
              </div>

              <div
                className="w-48 py-2 text-right"
                css={{
                  backgroundImage: "url(/img/referral-template-bg.svg)",
                  backgroundSize: "100% auto",
                  backgroundPosition: "left top",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <BorderButton
                  round
                  bgColor={colors.white}
                  disabled={props.disabled}
                  onClick={props.handleCancel}
                  className="mr-4"
                >
                  <i className="far fa-xmark text-organization" />
                </BorderButton>
              </div>
            </div>

            <div className="relative flex-1 overflow-auto p-4">
              <div className="relative z-10">{props.children}</div>
              <div className="pointer-events-none absolute inset-0 z-0 overflow-hidden opacity-10">
                <div
                  className="absolute -right-36 -top-16"
                  css={{
                    fontSize: 300,
                  }}
                >
                  💛
                </div>
              </div>
            </div>

            <div
              className="relative flex items-center gap-4 bg-dark px-4"
              css={{
                minHeight: 70,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              {props.footer}
            </div>
          </div>
        </TransitionChild>
      </div>
    </Transition>
  );
}
