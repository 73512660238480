import { intersection } from "lodash-es";
import moment from "moment-timezone";
import ClassModel from "src/models/ClassModel";
import DiscountModel from "src/models/DiscountModel";
import GroupRegistrationModel, {
  isGroupRegistration,
} from "src/models/GroupRegistrationModel";
import MembershipModel from "src/models/MembershipModel";
import OrganizationModel from "src/models/OrganizationModel";
import PrivateSerieModel, {
  isPrivateSerie,
} from "src/models/PrivateSerieModel";
import UserModel from "src/models/UserModel";
import calculateProratedRegistrationPrice from "src/utils/calculateProratedRegistrationPrice";
import { DiscountType, GroupRegistrationStatus } from "types/code-generator";

interface CalculateDiscountsInput {
  discounts: DiscountModel[];
  registrations: Array<
    GroupRegistrationModel | PrivateSerieModel | MembershipModel
  >;
  startDate: Date;
  students: UserModel[];
  organization: OrganizationModel;
}

export default ({
  discounts,
  students,
  registrations,
  startDate,
  organization,
}: CalculateDiscountsInput) => {
  const results: Array<{
    discount: DiscountModel;
    ids: string[];
  }> = [];
  const eligibleDiscounts = discounts.filter((d) => {
    if (d.availableForAllOfferings) {
      if (organization.hasDepartments) {
        return registrations.some((r) => {
          if (isGroupRegistration(r)) {
            return r.class?.department?.id === d.department?.id;
          }

          if (isPrivateSerie(r)) {
            return r.offering?.department?.id === d.department?.id;
          }
        });
      }
      return true;
    }

    for (const r of registrations) {
      if (
        r &&
        isGroupRegistration(r) &&
        r.class?.discounts?.find((cd) => cd.id === d.id)
      ) {
        return true;
      }

      if (
        r &&
        isPrivateSerie(r) &&
        r.offering?.discounts?.find((od) => od.id === d.id)
      ) {
        return true;
      }
    }

    return false;
  });

  // console.log("eligibleDiscounts", eligibleDiscounts);

  eligibleDiscounts.forEach((d) => {
    const minForDiscount = d.min;
    const maxForDiscount = d.max;

    if (d.discountType === DiscountType.MultiClass) {
      students.forEach((s) => {
        const registrationsForStudent = registrations.filter((r) => {
          if (isGroupRegistration(r)) {
            return (
              ((r.student && r.student.id === s.id) ||
                (r.roster && r.roster.find((rs) => rs.id === s.id))) &&
              r.status !== GroupRegistrationStatus.Waitlist
            );
          }
          return false;
        });

        const eligibleMultiClassRegistrations = registrationsForStudent
          .filter((r) => {
            if (isGroupRegistration(r)) {
              const price = calculateProratedRegistrationPrice({
                classItem: new ClassModel(r.class),
                endDate: moment(startDate).endOf("month").toDate(),
                startDate,
                organization,
              });
              let departmentEligible = true;

              if (organization.hasDepartments) {
                departmentEligible =
                  r.class?.department?.id === d.department?.id;
              }

              return (
                departmentEligible &&
                (price > 0 || r.class.chargeAmounts.length > 0)
              );
            }
            return false;
          })
          .map((r) => r.id);

        if (
          eligibleMultiClassRegistrations.length >= minForDiscount &&
          eligibleMultiClassRegistrations.length <= maxForDiscount
        ) {
          const existingMultiClassDiscountIndex = results.findIndex(
            (d) => d.discount.discountType === DiscountType.MultiClass
          );

          if (existingMultiClassDiscountIndex >= 0) {
            const existingMultiClassDiscount =
              results[existingMultiClassDiscountIndex];
            if (existingMultiClassDiscount.discount.min > d.min) {
              results.splice(existingMultiClassDiscountIndex, 1);
            }
          }

          results.push({
            discount: d,
            ids: eligibleMultiClassRegistrations,
          });
        }
      });
    }

    if (d.discountType === DiscountType.MultiStudent) {
      const eligibleMultiStudentRegistrations = registrations.filter((r) => {
        if (d.availableForAllOfferings) {
          if (isGroupRegistration(r) && organization.hasDepartments) {
            return r.class?.department?.id === d.department?.id;
          }

          return true;
        }

        if (isGroupRegistration(r)) {
          const price = calculateProratedRegistrationPrice({
            classItem: new ClassModel(r.class),
            endDate: moment(startDate).endOf("month").toDate(),
            startDate,
            organization,
          });
          let departmentEligible = true;

          if (organization.hasDepartments) {
            departmentEligible = r.class?.department?.id === d.department?.id;
          }

          // console.log("price", price);

          return (
            departmentEligible &&
            (price > 0 || r.class.chargeAmounts.length > 0) &&
            r.status !== GroupRegistrationStatus.Waitlist &&
            r.class?.discounts.find((cd) => cd.id === d.id)
          );
        }

        return false;
      });

      // console.log(
      //   "eligibleMultiStudentRegistrations",
      //   eligibleMultiStudentRegistrations
      // );

      const studentsInEligibleMultiStudentRegistrations =
        eligibleMultiStudentRegistrations.reduce((acc, r) => {
          if (isGroupRegistration(r)) {
            if (r.student && !acc.includes(r.student.id)) {
              acc.push(r.student.id);
            }
            if (
              r.roster &&
              intersection(
                r.roster.map((rs) => rs.id),
                acc
              ).length === 0
            ) {
              r.roster.forEach((rs) => acc.push(rs.id));
            }
          }
          return acc;
        }, []);

      // console.log(
      //   "eligibleMultiStudentRegistrations",
      //   eligibleMultiStudentRegistrations.length
      // );
      // console.log("students", studentsInEligibleMultiStudentRegistrations);

      if (
        studentsInEligibleMultiStudentRegistrations.length >= minForDiscount &&
        studentsInEligibleMultiStudentRegistrations.length <= maxForDiscount
      ) {
        const existingMultiStudentDiscountIndex = results.findIndex(
          (d) => d.discount.discountType === DiscountType.MultiStudent
        );
        const existingMultiStudentDiscount =
          results[existingMultiStudentDiscountIndex];

        if (existingMultiStudentDiscountIndex >= 0) {
          if (
            existingMultiStudentDiscount.discount.min > d.min ||
            existingMultiStudentDiscount.discount.id === d.id
          ) {
            results.splice(existingMultiStudentDiscountIndex, 1);
          }
        }

        results.push({
          discount: d,
          ids: studentsInEligibleMultiStudentRegistrations,
        });
      }
    }
  });

  return results;
};
