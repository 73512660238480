import { useEffect, useState } from "react";
import { useMediaQueries } from "src/utils/useMediaQueries";

export function useMainOffset() {
  const [offsetTop, setOffsetTop] = useState(50);
  const queries = useMediaQueries();

  useEffect(() => {
    getOffset();
  }, []);

  useEffect(() => {
    getOffset();
  }, [queries]);

  function getOffset() {
    const main = document.getElementById("main");

    if (main) {
      const style = getComputedStyle(main);
      const offset = parseInt(style.marginTop);

      setOffsetTop(offset);
    }
  }

  return offsetTop;
}
