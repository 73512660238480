import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import tw from "src/utils/tw";

interface Props {
  value?: string;
  defaultValue?: string;
  placeholder: string;
  readOnly?: boolean;
  className?: string;
  onChange?: (value) => void;
  onBlur?: (value) => void;
  maxLength?: number;
  name?: string;
  rows?: number;
  disabled?: boolean;
  allowClear?: boolean;
  onClear?: () => void;
  isPreview?: boolean;
}

export default function InputTextarea(props: Props) {
  const store = useStore();

  function onChange(e) {
    const value = e.target.value;

    if (props.onChange) {
      props.onChange(value);
    }
  }

  function onBlur(e) {
    const value = e.target.value;

    if (props.onBlur) {
      props.onBlur(value);
    }
  }

  function clearValue() {
    if (props.onChange) {
      props.onChange("");
    }

    if (props.onClear) {
      props.onClear();
    }
  }

  let borderColor = colors.action;

  if (!store.isAdminArea) {
    borderColor = store.theme.orgColor;
  }

  if (props.isPreview) {
    borderColor = store.theme.orgOrgColor;
  }

  return (
    <div className="relative">
      <div
        className={tw(
          "whitespace-pre-line",
          "p-4",
          "border",
          "invisible",
          props.className
        )}
        css={{
          minHeight: 100,
        }}
      >
        {props.value + "\n"}
      </div>
      <textarea
        placeholder={props.placeholder}
        value={props.value}
        onChange={onChange}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        name={props.name}
        defaultValue={props.defaultValue}
        onBlur={onBlur}
        rows={props.rows}
        disabled={props.disabled}
        className={tw(
          "absolute",
          "w-full",
          "left-0",
          "top-0",
          "right-0",
          "bottom-0",
          "border",
          "border-borderGray",
          "rounded",
          "bg-bgGray",
          "transition",
          "p-4",
          "placeholder-empty",
          "resize-none"
        )}
        css={{
          "&:hover": {
            borderColor,
          },
        }}
      />
      {props.allowClear && props.value && (
        <button
          className="absolute right-3 top-3 cursor-pointer"
          onClick={clearValue}
        >
          <i aria-hidden className="far fa-circle-xmark text-empty" />
        </button>
      )}
    </div>
  );
}
