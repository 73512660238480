import moment from "moment-timezone";
import AttendanceModel from "src/models/AttendanceModel";
import { CustomerServiceItemModel } from "src/models/CustomerServiceItemModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import InvoiceModel from "src/models/InvoiceModel";
import MembershipModel from "src/models/MembershipModel";
import PaymentModel from "src/models/PaymentModel";
import PrivateSerieModel from "src/models/PrivateSerieModel";
import {
  DepartmentPayload,
  Gender,
  GroupRegistrationStatus,
  HealthQuestionResponse,
  Level,
  MembershipStatus,
  PermissionType,
  PrivateSerieStatus,
  SettingType,
  UserAgreement,
} from "types/code-generator";
import { CreditModel } from "./CreditModel";
import EvaluationModel from "./EvaluationModel";
// import { EventModel } from "./EventModel";
import Stripe from "stripe";
import { UsaSwimmingMember } from "types/usa-swimming";
import { FileModel } from "./FileModel";
import GroupRegistrationModel from "./GroupRegistrationModel";
import OrganizationModel from "./OrganizationModel";
import { RecurringChargeModel } from "./RecurringChargeModel";
import { RecurringCreditModel } from "./RecurringCreditModel";
import { SettingModel } from "./SettingModel";
import TokenModel from "./TokenModel";

export interface Cart {
  students: Array<{ id: string; firstName: string }>;
  groupRegistrations: Array<{
    id: string;
    class: { id: string };
    status: string;
    student: { id: string; firstName: string };
  }>;
  privateSeries: [];
}

export interface StripeToken {
  id: string;
  brand: string;
  expMonth: number;
  expYear: number;
  name: string;
  last4: string;
  tokenId?: string;
  note?: string;
  error?: string;
  nextAction?: Stripe.SetupIntent.NextAction;
}

export const isUser = (user): user is UserModel => {
  return user.firstName !== undefined;
};

class UserModel {
  accountOwner: UserModel;
  active: boolean;
  address1: string;
  address2: string;
  allowAssignment: boolean;
  allowMarketing: boolean;
  attendancesAsAttended: AttendanceModel[];
  avatar: FileModel;
  balance: number = 0;
  bio: string;
  birthDate: Date;
  cart: Cart;
  city: string;
  createdAt: Date;
  credits: CreditModel[] = [];
  customNotes: string[];
  customerServiceItems: CustomerServiceItemModel[];
  departments: DepartmentPayload[];
  email: string = "";
  emergencyContactName: string;
  emergencyContactPhone: string;
  evaluations: EvaluationModel[];
  // events: EventModel[] = [];
  firstName: string = "";
  futureInvoiceBalance: number = 0;
  gender: Gender;
  groupRegistrations: GroupRegistrationModel[] = [];
  healthQuestionResponses: HealthQuestionResponse[] = [];
  // healthConcerns: string;
  hideInFilters: boolean = false;
  id: string;
  invoiceItems: InvoiceItemModel[];
  invoices: InvoiceModel[] = [];
  isStudent: boolean;
  jobCreditBalance: number;
  lastAccess: Date;
  lastName: string = "";
  level: Level;
  linkedAccounts: UserModel[];
  memberships: MembershipModel[] = [];
  membershipsRoster: MembershipModel[] = [];
  memberSince: Date;
  note: string;
  organization: OrganizationModel;
  organizationOwner: OrganizationModel;
  // organizations: OrganizationModel[] = [];
  payments: PaymentModel[];
  permissions: PermissionType[];
  phone: string;
  primaryAccount: UserModel;
  privateGroupRegistrations?: GroupRegistrationModel[];
  privateSeries: PrivateSerieModel[] = [];
  privateSerieAccountOwner: PrivateSerieModel[] = [];
  privateSerieInstructor: PrivateSerieModel[] = [];
  profile: UserModel;
  profiles: UserModel[];
  recurringCharges: RecurringChargeModel[] = [];
  recurringCredits: RecurringCreditModel[] = [];
  settings: SettingModel[] = [];
  state: string;
  stripeCustomerId: string;
  stripeToken: StripeToken;
  studentGroupRegistrations: GroupRegistrationModel[];
  students: UserModel[];
  tokens: TokenModel[];
  updatedAt: Date;
  usaSwimmingMember: UsaSwimmingMember;
  usaSwimmingMemberId: string;
  userAgreements: UserAgreement[];
  zip: string;

  constructor(data) {
    Object.assign(this, data);
  }

  get secondaryContacts() {
    return (
      this.settings &&
      this.settings.filter((s) => s.type.includes("SecondaryContact"))
    );
  }

  get secondaryContactEmails() {
    return (
      this.settings &&
      this.settings.filter((s) => s.type === SettingType.SecondaryContactEmail)
    );
  }

  get secondaryContactPhones() {
    return (
      this.settings &&
      this.settings.filter((s) => s.type === SettingType.SecondaryContactPhone)
    );
  }

  get notificationSettings() {
    return (
      this.settings &&
      this.settings.filter(
        (s) =>
          s.type === SettingType.NotificationAccount ||
          s.type === SettingType.NotificationCustomerCancellation ||
          s.type === SettingType.NotificationLessonTransferOrAssignment ||
          s.type === SettingType.NotificationNewLesson ||
          s.type === SettingType.NotificationMarketing ||
          s.type === SettingType.OrganizationUpdates ||
          s.type === SettingType.NotificationPrivateSeriePosted
      )
    );
  }

  getSetting(setting, departmentId = null) {
    if (departmentId) {
      return (
        this.settings &&
        this.settings.find(
          (s) =>
            s.type === setting &&
            s.department &&
            s.department.id === departmentId
        )
      );
    }
    return this.settings && this.settings.find((s) => s.type === setting);
  }

  get instructorNotificationSettings() {
    return (
      this.settings &&
      this.settings.filter(
        (s) =>
          s.type === SettingType.NotificationNewLesson ||
          s.type === SettingType.NotificationLessonTransferOrAssignment ||
          s.type === SettingType.NotificationCustomerCancellation
      )
    );
  }

  get isSuperAdmin() {
    return this.level && this.level === Level.SuperAdmin;
  }

  get isSales() {
    return this.level && this.level === Level.Sales;
  }

  get isSalesOrAbove() {
    return this.isSales || this.isSuperAdmin;
  }

  get isAdminOrAbove() {
    return (
      (this.level && this.level === Level.Admin) ||
      this.isSuperAdmin ||
      this.isSales
    );
  }

  get isInstructor() {
    return this.level && this.level === Level.Instructor;
  }

  get isStaff() {
    return (
      (this.level && this.level === Level.Instructor) || this.isAdminOrAbove
    );
  }

  get activeRecurringCharges() {
    const today = new Date().getTime();

    return (
      this.recurringCharges &&
      this.recurringCharges.filter(
        (c) => new Date(c.finalDate).getTime() > today
      )
    );
  }

  get allGroupRegistrations() {
    let result = this.groupRegistrations;

    if (this.level === Level.Student) {
      if (this.accountOwner && this.accountOwner.groupRegistrations) {
        result = this.accountOwner.groupRegistrations.filter(
          (r) =>
            (r.student && r.student.id === this.id) ||
            (r.roster && r.roster.find((rs) => rs.id === this.id))
        );
      }
    }

    return result;
  }

  get activeGroupRegistrations() {
    if (this.level === Level.Student) {
      return this.allGroupRegistrations.filter(
        (r) =>
          r.status === GroupRegistrationStatus.Approved ||
          r.status === GroupRegistrationStatus.Pending ||
          (r.status === GroupRegistrationStatus.Canceled &&
            r.finalDate &&
            moment().isSameOrBefore(moment(r.finalDate), "day"))
      );
    } else {
      return this.allGroupRegistrations.filter(
        (r) =>
          r.status === GroupRegistrationStatus.Approved ||
          r.status === GroupRegistrationStatus.Pending ||
          (r.status === GroupRegistrationStatus.Canceled &&
            r.finalDate &&
            moment().isSameOrBefore(moment(r.finalDate), "day"))
      );
    }
  }

  get activeMemberships() {
    return this.memberships?.filter(
      (r) =>
        r.status === MembershipStatus.Active ||
        r.status === MembershipStatus.Pending ||
        (r.finalDate && moment().isSameOrBefore(moment(r.finalDate), "day"))
    );
  }

  get allPrivateSeries() {
    let privateSeries = this.privateSeries || [];

    if (this.students && this.students.length > 0) {
      privateSeries = [
        ...privateSeries,
        ...this.students.reduce(
          (acc, s) => acc.concat(s.privateSeries || []),
          []
        ),
      ];
    }

    return privateSeries;
  }

  get activePrivateSeries() {
    return this.allPrivateSeries.filter(
      (r) =>
        r.status === PrivateSerieStatus.InProgress ||
        r.status === PrivateSerieStatus.Pending
    );
  }

  get waitlistGroupRegistrations() {
    if (this.level === Level.Student) {
      return this.allGroupRegistrations
        .filter((r) => r.status === GroupRegistrationStatus.Waitlist)
        .filter((r) => {
          if (r.student) {
            return r.student.id === this.id;
          } else if (r.roster) {
            return r.roster.find((rs) => rs.id === this.id);
          }
          return false;
        });
    } else {
      return this.allGroupRegistrations.filter(
        (r) => r.status === GroupRegistrationStatus.Waitlist
      );
    }
  }

  // hasVisited(value: string) {
  //   return this.events.find(
  //     (e) => e.type === EventType.Visited && e.value === value
  //   );
  // }
}

export default UserModel;
