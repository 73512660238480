/// <reference types="@emotion/react/types/css-prop" />
import "src/utils/logrocket";
import "src/utils/polyfills";

import "src/styles/font.css";
import "src/styles/global.css";
import "src/styles/tailwind.css";
import "src/styles/text.css";

import { createRoot } from "react-dom/client";
import App from "./App";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
