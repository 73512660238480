import { gql } from "graphql-tag";
import {
  createDiscount,
  createDiscountVariables,
  discounts,
  discountsVariables,
  updateDiscount,
  updateDiscountVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

const DiscountPayloadFragment = gql`
  fragment DiscountPayload on Discount {
    id
    amount
    amountType
    availableForAllOfferings
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    department {
      id
      title
    }
    discountType
    enabledByDefault
    max
    min
  }
`;

export const UpdateDiscountMutation = gql`
  mutation updateDiscount($data: DiscountCreateInput!, $where: UniqueInput!) {
    updateDiscount(data: $data, where: $where) {
      ...DiscountPayload
    }
  }
  ${DiscountPayloadFragment}
` as TypedDocumentNode<updateDiscount, updateDiscountVariables>;

export const DeleteDiscountMutation = gql`
  mutation deleteDiscount($id: String!) {
    deleteDiscount(where: { id: $id }) {
      id
    }
  }
`;

export const CreateDiscountMutation = gql`
  mutation createDiscount($data: DiscountCreateInput!) {
    createDiscount(data: $data) {
      ...DiscountPayload
    }
  }
  ${DiscountPayloadFragment}
` as TypedDocumentNode<createDiscount, createDiscountVariables>;

export const DiscountsQuery = gql`
  query discounts($organizationId: String!, $departmentId: String) {
    discounts(
      where: { organizationId: $organizationId, departmentId: $departmentId }
    ) {
      ...DiscountPayload
      counts {
        offerings
        usage
        total
      }
    }
  }
  ${DiscountPayloadFragment}
` as TypedDocumentNode<discounts, discountsVariables>;
