import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import tw from "src/utils/tw";

interface Props {
  to?: Location | string;
  className?: string;
  children: ReactNode;
}

export function MenuItem(props: Props) {
  const store = useStore();
  const location = useLocation();

  const isStaff =
    store.isAdminArea || (store.user?.isInstructor && store.isAccountArea);
  const isActive = props.to === location.pathname;

  // console.log("isStaff", isStaff);

  return (
    <Link
      to={props.to}
      className={tw(
        "flex border-t border-borderGray bg-white font-bold transition hover:bg-bgGray",
        props.className
      )}
      data-test={props["data-test"]}
    >
      <div className="flex flex-1 gap-4">
        <div
          className="h-full w-1.5 transition"
          css={{
            backgroundColor: isActive
              ? isStaff
                ? colors.main
                : store.theme.orgColor
              : "transparent",
          }}
        />
        <div
          className={tw(
            "flex-1 py-2.5 pr-4 text-organization",
            isActive && "text-dark"
          )}
        >
          {props.children}
        </div>
      </div>
    </Link>
  );
}
