import moment from "moment-timezone";

interface CheckCouponDataInput {
  coupon: {
    id: string;
    active: boolean;
    expirationDate: Date;
    invoiceItems?: {
      invoiceItem: {
        invoice: {
          userId: string;
        };
      };
    }[];
    maxAccountRedemptions?: number;
    startDate: Date;
  };
  userId?: string;
}

export function checkCouponData({ coupon, userId }: CheckCouponDataInput) {
  if (coupon.active === false) {
    return null;
  }

  const today = moment();

  if (coupon.startDate) {
    const couponStartDate = moment(coupon.startDate);

    if (today.isBefore(couponStartDate)) {
      return null;
    }
  }

  if (coupon.expirationDate) {
    const couponExpiration = moment(coupon.expirationDate);

    if (today.isAfter(couponExpiration)) {
      return null;
    }
  }

  if (coupon.maxAccountRedemptions && userId) {
    const couponAccountRedemptions =
      coupon.invoiceItems?.reduce((acc, iic) => {
        const usedByAccount = iic.invoiceItem?.invoice.userId === userId;

        if (usedByAccount) {
          acc += 1;
        }

        return acc;
      }, 0) || 0;

    if (couponAccountRedemptions >= coupon.maxAccountRedemptions) {
      return null;
    }
  }

  return coupon;
}
