import { useEffect, useState } from "react";

export function useFakeInput() {
  const [scrollY, setScrollY] = useState(window.scrollY);
  let fakeInput;

  useEffect(() => {
    fakeInput = document.createElement("input");
    fakeInput.setAttribute("type", "text");
    fakeInput.style.position = "absolute";
    fakeInput.style.opacity = "0";
    fakeInput.style.height = "0";

    document.body.prepend(fakeInput);
    setScrollY(window.scrollY);

    fakeInput.focus();

    return () => {
      window.scrollTo(0, scrollY);
    };
  }, []);

  return fakeInput;
}
