import { createClient } from "graphql-ws";
import { getToken } from "src/utils/getToken";
import { isServer } from "src/utils/isServer";
import sleep from "src/utils/sleep";

export const subscriptionClient = isServer
  ? null
  : createClient({
      url:
        process.env.NEXT_PUBLIC_APP_ENDPOINT.replace("http", "ws") +
        "-subscribe",
      connectionParams: {
        authToken: getToken(),
        referer: window.location.hostname,
      },
      shouldRetry: () => true,
      retryAttempts: 99,
      retryWait: async (retry) => {
        await sleep(10 * 1000);
        return;
      },
    });
