import Cookies from "js-cookie";
import moment from "moment-timezone";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CurrentOrganizationQuery,
  OrganizationQuery,
  UnreadItemsQuery,
  UnreadItemsUpdatedSubscription,
  UpdateOrganizationMutation,
} from "src/api/Organization";
import {
  CurrentUserQuery,
  UnattachedProfilesQuery,
  UserBillingSummaryQuery,
  UserQuery,
} from "src/api/User";
import OrganizationModel from "src/models/OrganizationModel";
import UserModel from "src/models/UserModel";
import { apiClient, useApi } from "src/stores/Api";
import { subscriptionClient } from "src/stores/Subscription";
import colors from "src/utils/colors";
import { staffArea } from "src/utils/constants";
import { getCurrentHost } from "src/utils/getCurrentHost";
import { getToken, removeToken, setToken } from "src/utils/getToken";
import helmet from "src/utils/helmet";
import logrocket from "src/utils/logrocket";
import { orgRedirects } from "src/utils/orgRedirects";
import {
  IntegrationType,
  Level,
  OrganizationStatus,
  UnreadItemsPayload,
  UserBillingSummaryPayload,
  unreadItemsUpdated,
  updateOrganizationVariables,
} from "types/code-generator";

export interface StoreProps {
  store: StoreType;
}

// counts: { allClasses: number; allOfferings: number } = {
//   allClasses: 0,
//   allOfferings: 0,
// };

type StoreType = ReturnType<typeof Store>;

const defaultUnreadItems = {
  allClasses: 0,
  allOfferings: 0,
  customerServiceItems: 0,
  enrolledParticipants: 0,
  inProgressClasses: 0,
  inProgressPrivateSeries: 0,
  membershipOfferings: 0,
  passOfferings: 0,
  pendingGroupRegistrations: 0,
  pendingInvoiceItems: 0,
  pendingMemberships: 0,
  pendingPrivateSeries: 0,
  postedPrivateSeries: 0,
  upcomingClasses: 0,
  waitlistAvailableClasses: 0,
};

function Store() {
  const { currentHost, isApp, isDemo } = getCurrentHost();

  console.log("currentHost", currentHost);

  const [user, setUser] = useState<UserModel>(null);
  const [userLevel, setUserLevel] = useState(null);
  const [superAdmin, setSuperAdmin] = useState<UserModel>(null);
  const [userTimezone] = useState(moment.tz.guess());
  const [organization, setOrganization] = useState<OrganizationModel>(null);
  const [unreadItems, setUnreadItems] =
    useState<UnreadItemsPayload>(defaultUnreadItems);
  const [billingSummary, setBillingSummary] =
    useState<UserBillingSummaryPayload>(null);
  const [ready, setReady] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string>(null);
  const location = useLocation();
  const unreadItemsSubscription = useRef(null);
  // const history = useHistory();

  const departmentsData = window.sessionStorage.getItem("departments");
  const departments = departmentsData ? JSON.parse(departmentsData) : [];

  useApi(UnreadItemsQuery, {
    skip: !organization || !user?.isAdminOrAbove,
    variables: {
      organization: organization?.id,
      departments: departments.map((d) => d.id),
    },
    refetchOnWindowFocus: true,
    refetchInterval: 60 * 30 * 1000,
    onSuccess(data) {
      if (data?.unreadItems) {
        setUnreadItems(data.unreadItems);

        checkLocalTime({
          local: new Date(),
          remote: new Date(data.unreadItems.now),
        });
      }
    },
  });

  function checkLocalTime({ local, remote }: { local: Date; remote: Date }) {
    const localTime = local.getTime();
    const remoteTime = remote.getTime();
    const diff = (localTime - remoteTime) / 1000;

    if (Math.abs(diff) > 60 * 5) {
      // console.log("diff", diff);
      moment.now = () => remoteTime;
    }
  }

  useEffect(() => {
    // subscribeToVersions();
    boot();
  }, []);

  useEffect(() => {
    processCurrentUser();
  }, [user]);

  useEffect(() => {
    if (organization && !user) {
      checkCurrentUser();
    }
  }, [organization]);

  // useEffect(() => {
  //   if (user && user.isAdminOrAbove && organization && !subscribed) {
  //     subscribeToUnreadItems();
  //   }
  // }, [user, subscribed]);

  // useEffect(() => {
  //   if (user?.isAdminOrAbove && organization) {
  //     subscribeToUnreadItems(user);
  //   }
  // }, [organization, user]);

  function boot() {
    if (location.pathname === "/callback-stripe") {
      setReady(true);
    } else {
      checkOrganization();
    }
  }

  async function checkOrganization() {
    if (Object.keys(orgRedirects).includes(currentHost)) {
      let url = `https://${orgRedirects[currentHost]}.captyn.com${location.pathname}${location.search}`;

      if (process.env.NODE_ENV === "development") {
        url = `https://${orgRedirects[currentHost]}.local.localhost:3000${location.pathname}${location.search}`;
      }

      if (process.env.NEXT_PUBLIC_STAGE === "demo") {
        url = `https://${orgRedirects[currentHost]}.nytpac.com${location.pathname}${location.search}`;
      }

      window.location.href = url;
      return;
    }

    const currentOrganizationQuery = await apiClient(CurrentOrganizationQuery, {
      variables: {
        subdomain: currentHost,
      },
    });

    if (currentOrganizationQuery.organization) {
      const currentOrganization = new OrganizationModel({
        ...currentOrganizationQuery.organization,
        id: isApp ? "app" : currentOrganizationQuery.organization.id,
      });

      setOrganization(currentOrganization);

      checkLocalTime({
        local: new Date(),
        remote: new Date(currentOrganizationQuery.organization.now),
      });

      moment.tz.setDefault(currentOrganization.timezone);
      helmet.setOrganization(currentOrganization);

      console.log("organization", currentOrganization);

      if (currentOrganization.status === OrganizationStatus.Pending) {
        setIsPending(true);
      }
    } else {
      console.log("currentOrganization error");
      setReady(true);
      setError(`Error fetching Organization information.`);
    }
  }

  async function getCurrentUser() {
    const userToken = getToken();

    if (!userToken) {
      return;
    }

    try {
      const currentUserQuery = await apiClient(CurrentUserQuery);

      if (currentUserQuery.me) {
        const user = currentUserQuery.me.user;
        setCurrentUser({ user, token: currentUserQuery.me.token });
      }
    } catch (e) {
      setReady(true);
    }
  }

  function setCurrentUser({ token = null, user }) {
    if (process.env.NODE_ENV !== "production") {
      // console.log("token", token);
      console.log("user", user);
    }

    if (token) {
      removeToken();
      setToken(token);
    }

    const newUser = new UserModel(user);
    setUser(newUser);

    if (!userLevel) {
      setUserLevel(newUser.level);
    }

    // if (newUser.isAdminOrAbove && organization) {
    //   subscribeToUnreadItems(newUser);
    // }

    setReady(true);
  }

  function checkCurrentUser() {
    const userToken = getToken();

    if (userToken) {
      getCurrentUser();
    } else {
      setReady(true);
    }
  }

  function processCurrentUser() {
    if (!user) {
      return;
    }

    if (!isApp) {
      // verifyUserBelongsToOrganization();
      getUserBillingSummary();
    }

    // if (isDemo && !user.isSalesOrAbove) {
    //   signOut();
    //   history.push("/login");
    // }

    if (
      Cookies.get("viewingAs") &&
      user &&
      user.isSalesOrAbove &&
      !superAdmin
    ) {
      setReady(false);
      fetchViewingAsUser();
    }

    if (user.level === Level.Kiosk && !sessionStorage.getItem("kiosk")) {
      signOut();
    }

    logrocket.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      ...(user as any),
    });

    // if (
    //   this.store.user.isStaff &&
    //   this.session.accountOwner &&
    //   this.session.accountOwner.id !== this.store.user.id &&
    //   process.env.NODE_ENV !== "development"
    // ) {
    //   this.session.clearSession();
    // }
  }

  async function fetchViewingAsUser() {
    setSuperAdmin(new UserModel(user));

    let userData = await apiClient(UserQuery, {
      variables: {
        id: Cookies.get("viewingAs"),
      },
    });

    if (userData.user) {
      if (!isApp) {
        const profile = userData.user.profiles.find(
          (p) => p.organization.id === organization.id
        );

        if (profile) {
          userData = await apiClient(UserQuery, {
            variables: {
              id: profile.id,
            },
          });
        }
      }

      const newUser = new UserModel(userData.user);

      setCurrentUser({ user: newUser, token: null });
      setUserLevel(newUser.level);
    }
  }

  async function getUserBillingSummary() {
    if (!user || user?.level === Level.Kiosk || user?.isSalesOrAbove) {
      return;
    }

    const summary = await apiClient(UserBillingSummaryQuery, {
      variables: {
        id: user.id,
      },
    });

    // console.log("summary", summary);

    setBillingSummary(summary?.userBillingSummary);
  }

  function checkForUnattachedProfiles() {
    return apiClient(UnattachedProfilesQuery, {
      variables: {
        email: user.email,
      },
    })
      .then((res) => res.users)
      .catch((e) => console.log("checkForUnattachedProfiles error", e));
  }

  function updateUser() {
    if (!user) {
      return;
    }

    const userQuery = apiClient(UserQuery, {
      variables: {
        id: user.id,
      },
    });

    userQuery
      .then((res) => {
        // console.log("res", res);
        if (res.user) {
          const user = res.user;
          setCurrentUser({ user, token: null });
        }
      })
      .catch((e) => {
        console.log("updateUser error", e);
      });
  }

  function signOut() {
    // console.log("signout");
    removeToken();

    setTimeout(() => {
      setUser(null);
      setUserLevel(null);
    }, 16);
  }

  function subscribeToUnreadItems(newUser = null) {
    let currentUser = user;

    if (newUser) {
      currentUser = newUser;
    }

    if (!currentUser?.isAdminOrAbove) {
      return;
    }

    if (unreadItemsSubscription.current) {
      unreadItemsSubscription.current();
    }

    unreadItemsSubscription.current =
      subscriptionClient.subscribe<unreadItemsUpdated>(
        {
          query: UnreadItemsUpdatedSubscription.loc.source.body,
          variables: {
            organization: organization.id,
            departments: departments.map((d) => d.id),
          },
        },
        {
          next: ({ data }) => {
            // console.log("subscribe data", data);
            // console.log("unreadItems", unreadItems);

            if (data?.unreadItemsUpdated) {
              checkLocalTime({
                local: new Date(),
                remote: new Date(data.unreadItemsUpdated.now),
              });
            }

            setUnreadItems((current) => {
              const newUnreadItems = {
                ...current,
              };

              for (const d in data?.unreadItemsUpdated) {
                const count = data?.unreadItemsUpdated[d];

                if (count !== null) {
                  newUnreadItems[d] = count;
                }
              }

              // console.log("newUnreadItems", newUnreadItems);

              return newUnreadItems;
            });
          },
          error: (e) => {
            // console.log("unreadItems error", e);
            if (unreadItemsSubscription.current) {
              unreadItemsSubscription.current();
            }
          },
          complete: () => {
            // console.log("complete");
          },
        }
      );
  }

  // function subscribeToVersions() {
  //   subscriptionClient.subscribe<versionUpdated>(
  //     {
  //       query: VersionUpdatedSubscription.loc.source.body,
  //     },
  //     {
  //       next: ({ data }) => {
  //         console.log("data", data);

  //         if (data?.versionUpdated) {
  //           const update = history.listen((args) => {
  //             const url = new URL(window.location.href);
  //             const newUrl = `${url.origin}${args.pathname}`;
  //             console.log("newUrl", newUrl);
  //             window.location.href = newUrl;
  //             update();
  //           });
  //         }
  //       },
  //       error: (e) => {
  //         console.log("subscribeToVersions error", e);
  //       },
  //       complete: () => {
  //         console.log("complete");
  //       },
  //     }
  //   );
  // }

  async function updateOrganization(
    variables: updateOrganizationVariables = null
  ) {
    let newOrganization: OrganizationModel;

    if (variables) {
      const updateOrganization = await apiClient(UpdateOrganizationMutation, {
        variables,
      });

      if (updateOrganization.updateOrganization) {
        newOrganization = new OrganizationModel(
          updateOrganization.updateOrganization
        );
      }
    } else {
      const currentOrganization = await apiClient(OrganizationQuery, {
        variables: {
          id: organization.id,
        },
      });

      if (currentOrganization.organization) {
        newOrganization = new OrganizationModel(
          currentOrganization.organization
        );
      }
    }

    setOrganization(newOrganization);
    return newOrganization;
  }

  const isAdminArea = useMemo(() => {
    let isAdminArea =
      location.pathname.indexOf("/admin") === 0 ||
      location.pathname === "/start" ||
      location.pathname.indexOf("/start/") === 0;

    // if (user?.isStaff && location.pathname.indexOf("/account") === 0) {
    //   isAdminArea = true;
    // }

    return isAdminArea;
  }, [location, user]);

  const isAccountArea = useMemo(() => {
    const isAccountArea = location.pathname.indexOf("/account") === 0;

    return isAccountArea;
  }, [location]);

  const isStaffArea = useMemo(() => {
    return staffArea.some((regex) => regex.test(location.pathname));
  }, [location]);

  const isSuperAdminArea = useMemo(() => {
    const isSuperAdminArea = location.pathname.indexOf("/super-admin") === 0;

    return isSuperAdminArea;
  }, [location]);

  const theme = useMemo(() => {
    let orgColor = colors.org;
    let actionColor = colors.action;

    if (organization?.color?.length > 6) {
      orgColor = organization.color;
    }

    const themeData = {
      ...colors,
      orgColor,
      orgOrgColor: orgColor,
      actionColor,
    };

    if (isAdminArea || isSuperAdminArea) {
      themeData.actionColor = colors.action;
      themeData.orgColor = colors.action;

      if (organization?.integrations.includes(IntegrationType.FleetFeet)) {
        console.log("orgColor", orgColor);
        themeData.actionColor = orgColor;
        themeData.orgColor = orgColor;
      }
    }

    document.documentElement.style.setProperty(
      "--organization",
      themeData.orgColor
    );

    return themeData;
  }, [organization, location, user]);

  const unread = useMemo(() => {
    return (
      unreadItems?.customerServiceItems +
      unreadItems?.pendingGroupRegistrations +
      unreadItems?.pendingInvoiceItems +
      unreadItems?.pendingMemberships +
      unreadItems?.pendingPrivateSeries +
      unreadItems?.waitlistAvailableClasses
    );
  }, [unreadItems]);

  return {
    billingSummary,
    checkForUnattachedProfiles,
    error,
    getCurrentUser,
    isAccountArea,
    isAdminArea,
    isApp,
    isDemo,
    isPending,
    isStaffArea,
    isSuperAdminArea,
    // location,
    organization,
    ready,
    setCurrentUser,
    setOrganization,
    setSuperAdmin,
    setUserLevel,
    signOut,
    subscribeToUnreadItems,
    superAdmin,
    theme,
    unread,
    unreadItems,
    updateOrganization,
    updateUser,
    user,
    userLevel,
    userTimezone,
  };
}

const StoreContext = React.createContext<StoreType>(null);

export const StoreProvider = (props) => {
  const store = Store();

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(StoreContext);
  return store;
};

export function withStore(Component) {
  return function WithStoreComponent(props) {
    return (
      <StoreContext.Consumer>
        {(storeContext) => <Component {...props} store={storeContext} />}
      </StoreContext.Consumer>
    );
  };
}
