import logrocket from "logrocket";

let interactionCount = 0;

// Function to initialize LogRocket
function initializeLogRocket() {
  if (process.env.NEXT_PUBLIC_LOGROCKET_KEY) {
    logrocket.init(process.env.NEXT_PUBLIC_LOGROCKET_KEY, {
      // console: {
      //   shouldAggregateConsoleErrors: true,
      // },
      network: {
        requestSanitizer: (request) => {
          if ((request.body as any)?.variables?.data?.password) {
            (request.body as any).variables.data.password = "";
          }

          return request;
        },
      },
    });
  } else {
    // console.log("starting log rocket");
  }
}

// Event listeners for keyboard and mouse events
function interactionHandler() {
  interactionCount += 1;

  if (interactionCount >= 2) {
    initializeLogRocket();
    // Remove event listeners after initializing LogRocket
    document.removeEventListener("click", interactionHandler);
    document.removeEventListener("keydown", interactionHandler);
  }
}

// Adding event listeners to track user interactions
document.addEventListener("click", interactionHandler);
document.addEventListener("keydown", interactionHandler);

export default logrocket;
