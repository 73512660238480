import { gql } from "graphql-tag";
import {
  createStudentInfoRequest,
  createStudentInfoRequestVariables,
  deleteStudentInfoRequest,
  deleteStudentInfoRequestVariables,
  studentInfoRequest,
  studentInfoRequests,
  studentInfoRequestsVariables,
  studentInfoRequestVariables,
  updateStudentInfoRequest,
  updateStudentInfoRequestVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const StudentInfoRequestPayloadFragment = gql`
  fragment StudentInfoRequestPayload on StudentInfoRequest {
    answers
    availableForAllOfferings
    createdAt
    description
    department {
      id
      title
    }
    enabledByDefault
    id
    noQuestion
    order
    required
    singleResponse
    slug
    type
    yesQuestion
  }
`;

export const UpdateStudentInfoRequestMutation = gql`
  mutation updateStudentInfoRequest(
    $data: StudentInfoRequestCreateInput!
    $where: UniqueInput!
  ) {
    updateStudentInfoRequest(data: $data, where: $where) {
      ...StudentInfoRequestPayload
    }
  }
  ${StudentInfoRequestPayloadFragment}
` as TypedDocumentNode<
  updateStudentInfoRequest,
  updateStudentInfoRequestVariables
>;

export const DeleteStudentInfoRequestMutation = gql`
  mutation deleteStudentInfoRequest($id: String!) {
    deleteStudentInfoRequest(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<
  deleteStudentInfoRequest,
  deleteStudentInfoRequestVariables
>;

export const CreateStudentInfoRequestMutation = gql`
  mutation createStudentInfoRequest($data: StudentInfoRequestCreateInput!) {
    createStudentInfoRequest(data: $data) {
      ...StudentInfoRequestPayload
    }
  }
  ${StudentInfoRequestPayloadFragment}
` as TypedDocumentNode<
  createStudentInfoRequest,
  createStudentInfoRequestVariables
>;

export const StudentInfoRequestsQuery = gql`
  query studentInfoRequests($where: StudentInfoRequestWhereInput!) {
    studentInfoRequests(where: $where, orderBy: { order: asc }) {
      ...StudentInfoRequestPayload
    }
  }
  ${StudentInfoRequestPayloadFragment}
` as TypedDocumentNode<studentInfoRequests, studentInfoRequestsVariables>;

export const StudentInfoRequestQuery = gql`
  query studentInfoRequest($id: String!) {
    studentInfoRequest(where: { id: $id }) {
      ...StudentInfoRequestPayload
    }
  }
  ${StudentInfoRequestPayloadFragment}
` as TypedDocumentNode<studentInfoRequest, studentInfoRequestVariables>;
