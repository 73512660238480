import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";
import { isServer } from "src/utils/isServer";
import "url-search-params-polyfill";

if (!isServer) {
  smoothscroll.polyfill();

  (function () {
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: null };
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    }

    // @ts-ignore
    window.CustomEvent = CustomEvent;
  })();
}
