import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "src/stores/Store";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import AvatarModal from "../modals/AvatarModal";

interface Props {
  user: {
    id: string;
    avatar: {
      url: string;
    };
    firstName: string;
    lastName: string;
  };
  preview?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  icon?: boolean;
  showInitials?: boolean;
  className?: string;
  active?: boolean;
}

export default function UserAvatar(props: Props) {
  const store = useStore();
  const history = useHistory();

  if (!props.user) {
    return null;
  }

  let editLink = `/account/students/${props.user.id}/edit`;

  if (store.isAdminArea) {
    editLink = `/admin/users/${props.user.id}/edit`;
  }

  function promptPreviewAvatar() {
    if (!props.preview) {
      return;
    }

    modals.show(AvatarModal, {
      user: props.user,
      handleChange: () => {
        history.push(editLink);
      },
    });
  }

  let width = 53;
  let text = "text-xl";

  if (props.small) {
    width = 45;
    text = "text-md";
  } else if (props.extraSmall) {
    width = 40;
    text = "text-sm";
  } else if (props.icon) {
    width = 24;
    text = "text-xs";
  }

  const noBorder =
    props.user.avatar ||
    (!props.user.avatar && props.preview) ||
    (!props.user.avatar && props.showInitials);

  return (
    <React.Fragment>
      <div
        css={{
          width,
          height: width,
        }}
        className={tw(
          "overflow-hidden rounded-full bg-white text-center",
          props.active && "border-3 border-organization",
          !noBorder && "border border-borderGray",
          props.className
        )}
      >
        {props.user.avatar && (
          <div
            className={`${props.preview ? "cursor-pointer" : ""} h-full w-full`}
            onClick={promptPreviewAvatar}
          >
            <img
              src={props.user.avatar.url}
              alt={`${props.user.firstName} ${props.user.lastName}`}
              className="h-full w-full overflow-visible object-cover"
            />
          </div>
        )}

        {!props.user.avatar && !props.preview && !props.showInitials && (
          <Link
            to={editLink}
            className="relative flex h-full items-center justify-center bg-white"
          >
            <i
              className="far fa-image-portrait"
              css={{ color: store.theme.orgColor }}
            />
          </Link>
        )}

        {((!props.user.avatar && props.preview) || props.showInitials) && (
          <div
            className={tw(
              "relative flex h-full items-center justify-center leading-none text-organization",
              text
            )}
            // css={{
            //   background: store.isAdminArea
            //     ? colors.bgAction
            //     : transparentize(0.9, store.theme.orgColor),
            // }}
          >
            <div className="absolute inset-0 bg-organization opacity-5" />
            <div>
              {props.user.firstName?.slice(0, 1).toUpperCase()}
              {props.user.lastName?.slice(0, 1).toUpperCase()}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
