import ItemCard from "src/components/items/item/ItemCard";
import { ProfileSwitchModal } from "src/components/modals/ProfileSwitchModal";
import { useStore } from "src/stores/Store";
import { modals } from "src/utils/modals";
import { Level } from "types/code-generator";

interface Props {}

export function ProfileSwitcher(props: Props) {
  const store = useStore();

  function switchProfile() {
    modals.show(ProfileSwitchModal, {});
  }

  if (!store.user) {
    return null;
  }

  let level = store.user.level.toString();

  if (store.user.isAdminOrAbove) {
    level = store.organization.terminology?.administrator || "Administrator";
  } else if (store.user.isInstructor) {
    level = store.organization.terminology?.staff || "Staff";
  } else if (store.user.level === Level.AccountOwner) {
    level = store.organization.terminology?.customer || "Customer";
  }

  const userLabel = (
    <>
      <strong className="block truncate">{store.organization.title}</strong>
      <div className="text-sm">
        {store.user.firstName} {store.user.lastName}, {level}
      </div>
    </>
  );

  if (!store.user.profile || store.user.profile?.profiles.length === 1) {
    return <div className="mb-2 px-4 pt-4">{userLabel}</div>;
  }

  return (
    <div className="px-4 pt-4">
      <ItemCard
        className="flex cursor-pointer items-center gap-2 border-b-3 px-2 py-2 hover:border-darkGray"
        onClick={switchProfile}
      >
        <div className="full flex h-12 w-12 items-center justify-center rounded-full border border-b-3 border-borderGray">
          <i className="far fa-arrows-cross text-organization" />
        </div>
        <div className="flex-1 truncate">{userLabel}</div>
      </ItemCard>
    </div>
  );
}
