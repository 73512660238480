import Decimal from "decimal.js-light";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import { AmountType, fees } from "types/code-generator";
import { v4 as uuid } from "uuid";
import calculateInvoiceItemBalance from "./calculateInvoiceItemBalance";

interface CalculateFeesInput {
  invoiceItems: InvoiceItemModel[];
  fees: fees["fees"];
}

// interface CalculateFeesOutput {
//   id: string;
//   amount: number;
//   description: string;
//   fee: {
//     id: string;
//     amount: number;
//     amountType: AmountType;
//     title: string;
//   };
//   coupons?: Array<{
//     amount: number;
//     coupon: {
//       id: string;
//     };
//   }>;
// }

export default ({
  invoiceItems,
  fees,
}: CalculateFeesInput): InvoiceItemModel[] => {
  const feesToAdd = {};

  for (const fee of fees) {
    for (const invoiceItem of invoiceItems) {
      if (invoiceItem.credit) {
        continue;
      }

      let feeAmount = fee.amount;
      let invoiceItemAmount = calculateInvoiceItemBalance(invoiceItem);
      // let coupon;

      if (fee.amountType === AmountType.Percent) {
        const amount = fee.amount || 0;

        const rawAmount = new Decimal(
          invoiceItemAmount.totalOwed * (amount / 100)
        );

        feeAmount = rawAmount.toInteger().toNumber();
      }

      const groupRegistrationHasFee =
        invoiceItem.groupRegistration?.class?.fees?.find(
          (f) => f.id === fee.id
        );

      const privateSerieHasFee = invoiceItem.privateSerie?.offering?.fees?.find(
        (f) => f.id === fee.id
      );

      const membershipHasFee = invoiceItem.membership?.offering?.fees?.find(
        (f) => f.id === fee.id
      );

      if (
        groupRegistrationHasFee ||
        privateSerieHasFee ||
        membershipHasFee ||
        fee.availableForAllOfferings
      ) {
        // console.log("amount", amount);
        // console.log("invoiceItem", invoiceItem);
        // if (feeAmount > 0 && invoiceItem.coupons?.length > 0) {
        //   invoiceItem.coupons.forEach((iic) => {
        //     let couponAmount = 0;

        //     if (iic.coupon.amountType === AmountType.Dollar) {
        //       couponAmount += iic.coupon.amount;
        //     } else {
        //       const rawAmount = new Decimal(
        //         feeAmount * (iic.coupon.amount / 100)
        //       );
        //       couponAmount += rawAmount.toInteger().toNumber();
        //     }

        //     if (couponAmount > feeAmount) {
        //       couponAmount = feeAmount;
        //     }

        //     coupon = {
        //       amount: couponAmount,
        //       coupon: iic.coupon,
        //     };
        //   });
        // }

        if (feeAmount > 0) {
          if (feesToAdd[fee.id]) {
            feesToAdd[fee.id].amount += feeAmount;
          } else {
            feesToAdd[fee.id] = {
              id: fee.id,
              amount: feeAmount,
              description: fee.title,
              fee,
            };
          }
        }

        // if (coupon) {
        //   if (feesToAdd[fee.id].coupons) {
        //     feesToAdd[fee.id].coupons.push(coupon);
        //   } else {
        //     feesToAdd[fee.id].coupons = [coupon];
        //   }
        // }
      }
    }
  }

  const feeInvoiceItems = Object.keys(feesToAdd).map((f) => ({
    ...feesToAdd[f],
    id: uuid(),
  }));

  return feeInvoiceItems;
};
