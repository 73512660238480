import { TypedQueryDocumentNode } from "graphql";
import { gql } from "graphql-tag";
import { ClassPayloadFragment, ListClassPayloadFragment } from "src/api/Class";
import {
  createGroupRegistration,
  createGroupRegistrationVariables,
  filteredGroupRegistrations,
  filteredGroupRegistrationsForUser,
  filteredGroupRegistrationsForUserVariables,
  filteredGroupRegistrationsVariables,
  groupRegistration,
  groupRegistrationVariables,
  groupRegistrationsCounts,
  groupRegistrationsCountsVariables,
  pendingGroupRegistrations,
  pendingGroupRegistrationsVariables,
  updateGroupRegistration,
  updateGroupRegistrationVariables,
  updateManyGroupRegistrations,
  updateManyGroupRegistrationsVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { InvoiceItemPayloadFragment } from "./InvoiceItem";

export const GroupRegistrationPayloadFragment = gql`
  fragment GroupRegistrationPayload on GroupRegistration {
    accountOwner {
      id
      credits {
        id
        amount
      }
      email
      firstName
      invoices(where: { status: { not: Error } }) {
        id
        status
        invoiceItems {
          ...InvoiceItemPayload
        }
      }
      groupRegistrations {
        id
        createdAt
        events {
          id
          groupRegistration {
            id
            createdAt
            status
          }
          type
        }
        finalDate
        status
      }
      lastName
      memberships {
        id
        status
        finalDate
        offering {
          id
          price
          priceLevels {
            id
            price
          }
        }
        roster {
          id
        }
      }
      note
      stripeCustomerId
      stripeToken
      students {
        id
      }
    }
    class {
      ...ClassPayload
    }
    coupons {
      id
      amount
      amountType
      availableForAllOfferings
      expirationDate
    }
    customerInfoRequestResponses {
      id
      answer
      question
      user {
        id
      }
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    customerRequest {
      id
      description
      finalDate
      status
      type
    }
    events {
      id
      createdAt
      date
      groupRegistration {
        id
        createdAt
      }
      type
      by {
        id
        email
        firstName
        lastName
      }
      to {
        id
        email
        firstName
        lastName
      }
      value
    }
    finalDate
    id
    invoiceItems {
      ...InvoiceItemPayload
    }
    note
    organization {
      id
      title
      subdomain
      departments {
        id
      }
    }
    reason
    roster {
      id
      firstName
      lastName
      birthDate
      gender
      studentGroupRegistrations {
        id
        status
        class {
          id
        }
      }
    }
    status
    student {
      id
      avatar {
        id
        url
      }
      firstName
      lastName
      birthDate
      gender
      profile {
        id
      }
      studentGroupRegistrations {
        id
        status
        class {
          id
        }
      }
    }
    studentInfoRequestResponses {
      id
      description
      additionalResponse
      studentInfoRequest {
        id
      }
    }
    unread
    updatedAt
  }
  ${ClassPayloadFragment}
  ${InvoiceItemPayloadFragment}
`;

const GroupRegistrationListPayloadFragment = gql`
  fragment GroupRegistrationListPayload on GroupRegistration {
    id
    accountOwner {
      id
      email
      firstName
      lastName
    }
    class {
      ...ListClassPayload
    }
    createdBy {
      id
      firstName
      lastName
    }
    status
    student {
      id
      firstName
      lastName
      birthDate
      gender
    }
  }
  ${ListClassPayloadFragment}
`;

export const UpdateGroupRegistrationMutation = gql`
  mutation updateGroupRegistration(
    $data: GroupRegistrationCreateInput!
    $where: UniqueInput!
  ) {
    updateGroupRegistration(data: $data, where: $where) {
      ...GroupRegistrationPayload
    }
  }
  ${GroupRegistrationPayloadFragment}
` as TypedDocumentNode<
  updateGroupRegistration,
  updateGroupRegistrationVariables
>;

export const UpdateManyGroupRegistrationsMutation = gql`
  mutation updateManyGroupRegistrations(
    $where: GroupRegistrationWhereInput
    $data: GroupRegistrationCreateInput
    $filter: String
    $selected: [String]
  ) {
    updateManyGroupRegistrations(
      where: $where
      data: $data
      filter: $filter
      selected: $selected
    ) {
      count
    }
  }
` as TypedDocumentNode<
  updateManyGroupRegistrations,
  updateManyGroupRegistrationsVariables
>;

export const DeleteGroupRegistrationMutation = gql`
  mutation deleteGroupRegistration($id: String!) {
    deleteGroupRegistration(where: { id: $id }) {
      id
    }
  }
`;

export const CreateGroupRegistrationMutation = gql`
  mutation createGroupRegistration($data: GroupRegistrationCreateInput!) {
    createGroupRegistration(data: $data) {
      ...GroupRegistrationPayload
    }
  }
  ${GroupRegistrationPayloadFragment}
` as TypedDocumentNode<
  createGroupRegistration,
  createGroupRegistrationVariables
>;

export const GroupRegistrationsQuery = gql`
  query groupRegistrations($organizationId: String!) {
    groupRegistrations(
      where: {
        organizationId: $organizationId
        status: { in: [Pending, Waitlist] }
      }
      orderBy: { createdAt: desc }
    ) {
      ...GroupRegistrationListPayload
    }
  }
  ${GroupRegistrationListPayloadFragment}
`;

export const PendingGroupRegistrationsForUserQuery = gql`
  query pendingGroupRegistrationsForUser(
    $organizationId: String!
    $userId: String!
  ) {
    groupRegistrations(
      where: {
        organizationId: $organizationId
        accountOwnerId: $userId
        status: { in: [Pending, Waitlist] }
      }
      orderBy: { createdAt: desc }
    ) {
      ...GroupRegistrationPayload
    }
  }
  ${GroupRegistrationPayloadFragment}
`;

export const PendingGroupRegistrationsQuery = gql`
  query pendingGroupRegistrations($organizationId: String!, $filter: String) {
    groupRegistrations(
      where: {
        OR: [
          { status: { equals: Pending } }
          {
            status: { equals: Approved }
            customerRequest: { status: { equals: Pending } }
          }
        ]
        organizationId: $organizationId
      }
      filter: $filter
      orderBy: { createdAt: desc }
    ) {
      id
      accountOwner {
        id
        email
        firstName
        lastName
      }
      class {
        id
        classTimes {
          date
          day
          startTime
          endTime
          instructors {
            firstName
            lastName
          }
          location {
            title
          }
        }
        department {
          id
        }
        endDate
        maxAge
        maxEnrollment
        minAge
        paymentFrequency
        paymentRate
        price
        program {
          id
          title
        }
        prorateExcludedDates
        prorateInProgressClasses
        prorateStartEnd
        publishOnWebsite
        publishDate
        registrationClose
        registrationOpen
        registrations {
          id
          finalDate
          roster {
            id
            firstName
            lastName
          }
          status
          student {
            id
            firstName
            lastName
          }
        }
        startDate
        status
        tags {
          id
          title
        }
        title
        unpublishDate
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      customerRequest {
        id
        createdAt
        description
        finalDate
        status
        type
      }
      events {
        id
        by {
          id
          firstName
          lastName
        }
        createdAt
        type
        value
      }
      finalDate
      roster {
        id
        firstName
        lastName
      }
      status
      student {
        id
        birthDate
        firstName
        gender
        lastName
      }
      studentInfoRequestResponses {
        id
        description
        studentInfoRequest {
          id
          slug
        }
      }
    }
  }
` as TypedDocumentNode<
  pendingGroupRegistrations,
  pendingGroupRegistrationsVariables
>;

export const GroupRegistrationQuery = gql`
  query groupRegistration($id: String!) {
    groupRegistration(where: { id: $id }) {
      ...GroupRegistrationPayload
    }
  }
  ${GroupRegistrationPayloadFragment}
` as TypedDocumentNode<groupRegistration, groupRegistrationVariables>;

export const FilteredGroupRegistrationsQuery = gql`
  query filteredGroupRegistrations(
    $where: GroupRegistrationWhereInput!
    $filter: String
    $skip: Int
    $take: Int
    $orderBy: DefaultOrderByInput
  ) {
    groupRegistrations(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      accountOwner {
        id
        email
        firstName
        lastName
        phone
        stripeCustomerId
        stripeToken
        userAgreements {
          id
          createdAt
          agreement {
            id
          }
        }
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      customerRequest {
        id
        description
        finalDate
        status
        type
      }
      dateApproved
      events {
        id
        by {
          id
          firstName
          lastName
        }
        createdAt
        date
        groupRegistration {
          id
          createdAt
        }
        type
        value
      }
      finalDate
      roster {
        id
        birthDate
        firstName
        lastName
        gender
        healthConcerns
        note
      }
      status
      student {
        id
        accountOwner {
          id
          phone
          email
          firstName
          lastName
          stripeToken
        }
        avatar {
          id
          url
        }
        birthDate
        firstName
        lastName
        gender
        healthConcerns
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        membershipsRoster {
          id
          offeringId
        }
        note
        phone
        usaSwimmingMemberId
        usaSwimmingMember
      }
      studentInfoRequestResponses {
        id
        description
        additionalResponse
        studentInfoRequest {
          id
          slug
        }
      }
      updatedAt
    }
  }
` as TypedDocumentNode<
  filteredGroupRegistrations,
  filteredGroupRegistrationsVariables
>;

export const GroupRegistrationsCountsQuery = gql`
  query groupRegistrationsCounts(
    $where: GroupRegistrationWhereInput!
    $filter: String
  ) {
    groupRegistrationsCounts(where: $where, filter: $filter) {
      count
    }
  }
` as TypedQueryDocumentNode<
  groupRegistrationsCounts,
  groupRegistrationsCountsVariables
>;

export const ExportGroupRegistrationsMutation = gql`
  mutation exportGroupRegistrations(
    $where: GroupRegistrationWhereInput!
    $filter: String
  ) {
    exportGroupRegistrations(where: $where, filter: $filter)
  }
`;

export const FilteredGroupRegistrationsForUserQuery = gql`
  query filteredGroupRegistrationsForUser(
    $where: GroupRegistrationWhereInput!
    $filter: String
    $skip: Int
    $take: Int
  ) {
    groupRegistrations(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
    ) {
      id
      accountOwner {
        id
        email
        firstName
        lastName
      }
      coupons {
        id
        amount
        amountType
        code
        expirationDate
        frequency
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      customerRequest {
        id
        description
        finalDate
        status
        type
      }
      class {
        id
        agreements {
          id
        }
        classTimes {
          id
          date
          day
          startTime
          endTime
          instructors {
            id
            firstName
            lastName
          }
          location {
            id
            title
          }
        }
        department {
          id
          title
        }
        endDate
        instructors {
          id
          firstName
          lastName
        }
        maxAge
        maxEnrollment
        minAge
        paymentFrequency
        paymentRate
        price
        program {
          id
          title
        }
        prorateExcludedDates
        prorateInProgressClasses
        prorateStartEnd
        publishOnWebsite
        publishDate
        registrationClose
        registrationOpen
        registrations {
          id
          status
          student {
            id
            firstName
            lastName
          }
          finalDate
        }
        startDate
        status
        studentInfoRequests {
          id
          slug
        }
        tags {
          id
          title
        }
        title
        type
        unpublishDate
      }
      dateApproved
      events {
        id
        createdAt
        date
        groupRegistration {
          id
          createdAt
        }
        type
        value
        by {
          id
          firstName
          lastName
          level
        }
        to {
          id
        }
      }
      finalDate
      organization {
        id
      }
      roster {
        id
        firstName
        lastName
      }
      status
      student {
        id
        birthDate
        firstName
        gender
        lastName
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        note
      }
      studentInfoRequestResponses {
        id
        description
        additionalResponse
        studentInfoRequest {
          id
          slug
        }
      }
      updatedAt
    }
  }
` as TypedDocumentNode<
  filteredGroupRegistrationsForUser,
  filteredGroupRegistrationsForUserVariables
>;
