import {
  create,
  hide,
  Provider,
  register,
  remove,
  show,
  useModal as useModalHook,
} from "@ebay/nice-modal-react";

export const ModalProvider = Provider;

export const useModal = (ComponentOrId?: any) => {
  const modal = useModalHook(ComponentOrId);

  const update = (props) => modal.show({ ...modal.args, ...props });

  return {
    update,
    ...modal,
  };
};

export const modals = {
  create: create as <P>(Comp: React.ComponentType<P>) => React.FC<P>,
  show: show as <T extends any>(modal: React.FC<T>, args?: T) => Promise<T>,
  hide,
  register,
  remove,
};
