import ClassModel from "src/models/ClassModel";
import GroupRegistrationModel from "src/models/GroupRegistrationModel";
import UserModel from "src/models/UserModel";
import { MembershipStatus } from "types/code-generator";

interface CalculateMembershipRegistrationPriceInput {
  offering: ClassModel;
  user: UserModel;
  registration?: GroupRegistrationModel;
}

export function calculateMembershipRegistrationPrice({
  offering,
  user,
  registration,
}: CalculateMembershipRegistrationPriceInput) {
  let membershipPrice = offering.price;
  let applicableUserMemberships = offering.membershipPrices?.filter((m) =>
    user?.memberships?.find(
      (um) =>
        um.status === MembershipStatus.Active &&
        (um.offeringId === m.membershipOfferingId ||
          um.offering?.id === m.membershipOfferingId)
    )
  );

  if (registration) {
    applicableUserMemberships = offering.membershipPrices?.filter((m) =>
      user?.memberships?.find(
        (um) =>
          um.status === MembershipStatus.Active &&
          (um.offeringId === m.membershipOfferingId ||
            um.offering?.id === m.membershipOfferingId) &&
          um.roster?.find(
            (s) =>
              s.id === registration.student?.id ||
              registration.roster?.find((rs) => rs.id === s.id)
          )
      )
    );
  }

  const applicableUserMembershipsIds = applicableUserMemberships.map(
    (m) => m.membershipOfferingId
  );

  if (applicableUserMemberships.length > 0) {
    for (const mp of offering.membershipPrices) {
      if (applicableUserMembershipsIds.includes(mp.membershipOfferingId)) {
        if (membershipPrice > mp.price) {
          membershipPrice = mp.price;
        }
      }
    }
  }

  return membershipPrice;
}
