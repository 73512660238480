import { ReactNode } from "react";
import AnimateHeight from "react-animate-height";
import tw from "src/utils/tw";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function SlideDown(props: Props) {
  return (
    <AnimateHeight duration={250} height={props.children ? "auto" : 0}>
      {props.children}
    </AnimateHeight>
  );
  return (
    <div
      className={tw("grid transition-all", props.className)}
      style={{
        gridTemplateRows: props.children ? "1fr" : "0fr",
      }}
    >
      <div className={tw("overflow-hidden px-px")}>{props.children}</div>
    </div>
  );
}
