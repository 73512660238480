import { SendEmailMutation } from "src/api/Email";
import { useForm } from "src/components/forms/Form";
import { InputItem } from "src/components/inputs/InputItem";
import { InputText } from "src/components/inputs/InputText";
import InputTextarea from "src/components/inputs/InputTextarea";
import { apiClient } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import message from "src/utils/message";
import { modals, useModal } from "src/utils/modals";
import { sendEmailVariables } from "types/code-generator";
import { BorderButton } from "../buttons/BorderButton";
import Modal from "./Modal";

interface Props {
  title?: string;
}

export const ContactSupportModal = modals.create((props: Props) => {
  const store = useStore();
  const modal = useModal();
  const form = useForm();

  async function send() {
    const body = form.getFieldValue("message");
    let replyTo = form.getFieldValue("email");
    let name = replyTo;
    let html = `
      <p>${replyTo} has a question/message:</p>
      <p>${body}</p>
    `;

    if (store.user) {
      replyTo = store.user.email;
      name = `${store.user.firstName} ${store.user.lastName}`;
      html = `
        <p><a href="https://${store.organization.subdomain}.captyn.com/admin/users/${store.user.id}">${name}</a> (${replyTo}) has a question/message:</p>
        <p>${body}</p>
      `;
    }

    const variables: sendEmailVariables = {
      data: {
        users: [],
        // additionalEmails,
        sendToOrganization: true,
        subject: `Message from ${name}`,
        replyTo,
        organizationId: store.organization.id,
        html,
      },
    };

    await apiClient(SendEmailMutation, {
      variables,
    });

    modal.hide();

    message.success("Email sent");
  }

  let title = "Send Support Message";

  if (props.title) {
    title = props.title;
  }

  return (
    <Modal
      visible={modal.visible}
      handleCancel={modal.hide}
      afterClose={modal.remove}
      title={title}
      footer={
        <>
          <BorderButton type="submit" onClick={send}>
            Send
          </BorderButton>
          <BorderButton onClick={modal.hide}>Cancel</BorderButton>
        </>
      }
    >
      {!store.user && (
        <InputItem
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter an email" }]}
          form={form}
        >
          <InputText placeholder="Your email address" type="email" />
        </InputItem>
      )}

      <InputItem
        label="Question/Message"
        name="message"
        rules={[{ required: true, message: "Please enter a message" }]}
        form={form}
      >
        <InputTextarea placeholder="Your message" />
      </InputItem>
    </Modal>
  );
});
