import tw from "src/utils/tw";
import { useSearchParams } from "src/utils/useSearchParams";

interface Props {
  offering: {
    tags: {
      id: string;
      title: string;
      order: number;
    }[];
  };
  className?: string;
}

export function OfferingTag(props) {
  return (
    <div
      className={tw(
        "mb-1 mr-1 inline-block rounded bg-borderGray px-2 py-1.5 text-2xs font-bold uppercase leading-none text-empty",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

export default function OfferingTags(props: Props) {
  const params = useSearchParams();

  if (!props.offering?.tags || props.offering?.tags.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap items-center py-1">
      {[...(props.offering?.tags || [])]
        .sort((a, b) => a.order - b.order)
        .map((t) => {
          return (
            <OfferingTag
              className={tw(
                params.getAll("tag").includes(t.id) && "bg-paleMain",
                props.className
              )}
              key={t.id}
            >
              {t.title}
            </OfferingTag>
          );
        })}
    </div>
  );
}
