import moment from "moment-timezone";
import { CreateReferralMutation } from "src/api/ReferralTemplate";
import { BorderButton } from "src/components/buttons/BorderButton";
import { InputItem } from "src/components/inputs/InputItem";
import { InputText } from "src/components/inputs/InputText";
import InputTextarea from "src/components/inputs/InputTextarea";
import { ReferralModal } from "src/components/modals/ReferralModal";
import { apiClient } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import { dateFormatMed } from "src/utils/constants";
import formatMoney from "src/utils/formatMoney";
import message from "src/utils/message";
import { modals, useModal } from "src/utils/modals";
import {
  createReferralVariables,
  userReferralTemplate,
} from "types/code-generator";
import { Form, useForm } from "../forms/Form";
import { ModalProps } from "./Modal";

interface Props extends ModalProps {
  type: "learnMore" | "newReferral" | "dismiss";
  userReferralTemplate: userReferralTemplate["userReferralTemplate"];
}

export const UserReferralModal = modals.create((props: Props) => {
  const form = useForm();
  const modal = useModal();
  const store = useStore();

  function handleCancel() {
    if (props.handleCancel) {
      props.handleCancel();
    }

    modal.hide();
  }

  async function handleOk() {
    if (props.type === "dismiss") {
      return props.handleOk();
    }

    if (props.handleOk) {
      props.handleOk();
    }

    if (props.type === "newReferral") {
      await onSubmit();
    }

    modal.hide();
  }

  function afterClose() {
    modal.remove();
  }

  async function onSubmit() {
    form.validateFields(async (err, values) => {
      if (err) {
        console.log("err", err);
        return;
      }

      const variables: createReferralVariables = {
        data: {
          name: values.name,
          email: values.email,
          message: {
            create: {
              message: values.message.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              subject: values.subject,
            },
          },
        },
      };

      try {
        await apiClient(CreateReferralMutation, {
          variables,
        });

        message.success("Referral sent");
        handleCancel();
      } catch (e) {
        message.error(e);
      }
    });
  }

  let title = "How it works";
  let confirmText = "Send";
  let cancelText = "Close";
  const largest = props.userReferralTemplate.referralTemplate.milestones.reduce(
    (acc, m) => acc + m.amount,
    0
  );

  let body = (
    <>
      <p className="text-main">
        We appreciate you and love that you are interested in sharing Captyn! We
        want to make the referral process easier… and thank you with a reward.
      </p>
      <div
        css={{
          ["& *"]: {
            color: "#fff",
          },
          ["& ul"]: {
            listStyleType: "disc",
            padding: "0 0 0 2.5rem",
          },
          ["& li"]: {
            margin: "0 0 .5rem 0",
          },
        }}
        dangerouslySetInnerHTML={{
          __html:
            props.userReferralTemplate?.referralTemplate?.learnMore?.replace(
              /<p><br><\/p>/g,
              ""
            ),
        }}
      />
    </>
  );

  if (props.type === "dismiss") {
    title = "Hide this offer?";
    confirmText = "Add a referral";
    cancelText = "Hide offering";
    body = (
      <>
        <div>
          <p className="text-white">We appreciate you!</p>

          <p className="text-white">
            Your referral offer for ${formatMoney(largest)} will remain
            available to you from your menu until{" "}
            {moment(
              props.userReferralTemplate?.referralTemplate?.stopShowing
            ).format(dateFormatMed)}
            .
          </p>

          <p className="pt-28 text-white">
            Remember, <strong>one simple referral</strong> could earn you $
            {formatMoney(largest)}!
          </p>
        </div>
      </>
    );
  } else if (props.type === "newReferral") {
    title = "💛 New Referral";
    confirmText = "Send";
    cancelText = "Cancel";

    body = (
      <>
        <p className="text-main">
          Enter the details below and add a custom message to your referral if
          you’d like. The message will be sent from you, we’ll be cc’d, and we
          will follow-up in a simple group message to set up a demo.
        </p>

        <Form onSubmit={onSubmit}>
          <InputItem
            form={form}
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter a name",
              },
            ]}
            label={<span className="text-white">New referral name</span>}
            reverse
          >
            <InputText placeholder="Name" />
          </InputItem>

          <InputItem
            form={form}
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter an email",
              },
            ]}
            label={<span className="text-white">New referral email</span>}
            reverse
          >
            <InputText placeholder="Email" />
          </InputItem>

          <InputItem
            form={form}
            name="subject"
            rules={[
              {
                required: true,
                message: "Please enter a subject",
              },
            ]}
            label={<span className="text-white">Subject</span>}
            initialValue={"A quick introduction"}
            reverse
          >
            <InputText placeholder="Email" />
          </InputItem>

          <InputItem
            form={form}
            name="message"
            initialValue={`Hey there!\n\nWe have been using Captyn and love it, thought you all should chat!\n\nBest,\n${store.user.firstName}`}
            label={
              <div className="font-normal text-white">
                <strong>Message from you</strong>
                <div className="text-sm">Feel free to edit our template.</div>
              </div>
            }
            reverse
          >
            <InputTextarea placeholder="Message" />
          </InputItem>
        </Form>

        <div className="mb-6 text-white">
          This <strong>single step</strong> could earn you{" "}
          <strong>${formatMoney(largest)}!</strong>
        </div>
      </>
    );
  }

  return (
    <ReferralModal
      visible={modal.visible}
      handleCancel={handleCancel}
      afterClose={afterClose}
      footer={
        <>
          {props.type !== "learnMore" && (
            <BorderButton
              type="submit"
              onClick={handleOk}
              disabled={form.isSubmitting}
              className="flex-1"
            >
              {confirmText}
            </BorderButton>
          )}
          <BorderButton
            onClick={handleCancel}
            disabled={form.isSubmitting}
            className="flex-1"
          >
            {cancelText}
          </BorderButton>
        </>
      }
      title={title}
    >
      {body}
    </ReferralModal>
  );
});
