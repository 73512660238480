import { modals, useModal } from "src/utils/modals";
import { BorderButton } from "../buttons/BorderButton";
import Modal from "./Modal";

interface ConfirmModalProps {
  body: string | React.ReactNode;
  title?: string;
  buttonText?: string;
  cancelText?: string;
  onlyOk?: boolean;
  disabled?: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  afterClose?: () => void;
}

export const ConfirmModal = modals.create((props: ConfirmModalProps) => {
  const modal = useModal();

  function handleOk() {
    if (props.handleOk) {
      props.handleOk();
    }

    modal.hide();
  }

  function handleCancel() {
    if (props.handleCancel) {
      props.handleCancel();
    }

    modal.hide();
  }

  function afterClose() {
    if (props.afterClose) {
      props.afterClose();
    }

    modal.remove();
  }

  return (
    <Modal
      visible={modal.visible}
      afterClose={afterClose}
      handleCancel={!props.disabled && handleCancel}
      hideClose={true}
      footer={
        <>
          <BorderButton
            type="submit"
            onClick={handleOk}
            disabled={props.disabled}
          >
            {props.buttonText ? props.buttonText : "Confirm"}
          </BorderButton>
          {!props.onlyOk && (
            <BorderButton onClick={handleCancel} disabled={props.disabled}>
              {props.cancelText ? props.cancelText : "Cancel"}
            </BorderButton>
          )}
        </>
      }
      title={props.title ? props.title : "Are you sure?"}
    >
      <div className="mb-4">{props.body}</div>
    </Modal>
  );
});
