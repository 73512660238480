import moment from "moment-timezone";
import getUserAge from "src/utils/getUserAge";

interface Props {
  student: {
    birthDate?: Date | string;
  };
  hideUnits?: boolean;
}

export default function StudentAge(props: Props) {
  if (!props.student || !props.student.birthDate) {
    return null;
  }

  const birthDate = moment(props.student.birthDate);

  if (!birthDate.isValid()) {
    return null;
  }

  const ageData = getUserAge(props.student.birthDate);

  return (
    <>
      {ageData.ageNumber}
      {!props.hideUnits && ` ${ageData.ageUnit}`}
    </>
  );
}
