import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import InvoiceModel from "src/models/InvoiceModel";
import calculateInvoiceItemBalance from "src/utils/calculateInvoiceItemBalance";

interface InvoiceItemCredit {
  amount?: number;
}

interface InvoiceItemCreateInput {
  amount?: number;
  credits?: {
    create?: InvoiceItemCredit[];
  };
  creditId?: string;
}

interface UserCredit {
  amount: number;
  startingAmount: number;
  description: string;
  id: string;
}

const calculateCreditsForInvoiceItem = ({
  userCredits,
  invoiceItem,
  numberOfInvoiceItems,
  // applyAvailableCredit,
}: {
  userCredits: UserCredit[];
  invoiceItem: InvoiceItemModel;
  numberOfInvoiceItems: number;
  // applyAvailableCredit: boolean;
}) => {
  // console.log("numberOfInvoiceItems", numberOfInvoiceItems);
  // console.log("applyAvailableCredit", applyAvailableCredit);
  // console.log("userCredits", userCredits);

  const initialCreate =
    invoiceItem.credits
      ?.filter((c) => c.credit && !c.id)
      .map((c) => {
        return {
          amount: c.amount,
          creditId: c.credit?.id,
        };
      }) || [];

  const currentInvoiceItemMutation: InvoiceItemCreateInput = {
    credits: {
      create: initialCreate,
    },
  };

  const currentInvoiceItem = {
    ...invoiceItem,
    credits: invoiceItem.credits || [],
  };

  let creditAmount = 0;
  const invoiceItemTotals = calculateInvoiceItemBalance(invoiceItem);
  let invoiceItemAmount =
    invoiceItemTotals.totalOwed - invoiceItemTotals.totalPaid;

  // console.log("invoiceItemTotals", invoiceItemTotals);
  // console.log("invoiceItemAmount", invoiceItemAmount);

  if (userCredits.length > 0 && invoiceItemAmount > 0) {
    for (const [i, c] of userCredits.entries()) {
      if (c.amount <= 0) {
        continue;
      }
      const maxAvailableCredit = c.amount;

      // console.log("invoiceItemAmount", invoiceItemAmount);
      // console.log("maxAvailableCredit", maxAvailableCredit);

      if (maxAvailableCredit >= invoiceItemAmount) {
        userCredits[i].amount -= invoiceItemAmount;
        creditAmount = invoiceItemAmount;
        invoiceItemAmount = 0;
      } else {
        invoiceItemAmount -= maxAvailableCredit;
        creditAmount = maxAvailableCredit;
        userCredits[i].amount -= maxAvailableCredit;
      }

      // console.log("creditAmount", creditAmount);

      const invoiceCredit = {
        amount: creditAmount,
        credit: {
          id: c.id,
        },
      };

      if (c.description) {
        invoiceCredit.credit["description"] = c.description;
      }

      const invoiceCreditMutation: InvoiceItemCreateInput = {
        amount: creditAmount,
        creditId: c.id,
      };

      if (creditAmount > 0) {
        if (Array.isArray(currentInvoiceItemMutation.credits.create)) {
          currentInvoiceItemMutation.credits.create.push(invoiceCreditMutation);
        }

        currentInvoiceItem.credits.push(invoiceCredit);
      }
    }
  }

  const result: {
    invoiceItemUpdateMutation;
    updatedInvoiceItem: InvoiceItemModel;
  } = {
    invoiceItemUpdateMutation: {
      data: currentInvoiceItemMutation,
      where: {
        id: invoiceItem.id,
      },
    },
    updatedInvoiceItem: currentInvoiceItem,
  };

  // console.log("result", result);

  return result;
};

export default function calculateInvoiceCreditMutations(invoice: InvoiceModel) {
  const { user, invoiceItems } = invoice;

  let userCredits =
    user.credits
      ?.filter((c) => c.amount > 0)
      .map((c) => ({
        ...c,
        startingAmount: c.amount,
      })) || [];

  const result: {
    invoiceUpdateMutation;
    updateCreditsMutations;
    updatedInvoice: InvoiceModel;
  } = {
    invoiceUpdateMutation: {
      data: {
        invoiceItems: {
          update: [],
        },
      },
      where: {
        id: invoice.id,
      },
    },
    updateCreditsMutations: [],
    updatedInvoice: new InvoiceModel({
      ...invoice,
      invoiceItems: [],
    }),
  };

  const outstandingBalanceInvoiceItem = invoiceItems.find(
    (i) => i.description === "Outstanding Account Balance"
  );

  if (outstandingBalanceInvoiceItem) {
    const { invoiceItemUpdateMutation, updatedInvoiceItem } =
      calculateCreditsForInvoiceItem({
        userCredits,
        invoiceItem: outstandingBalanceInvoiceItem,
        numberOfInvoiceItems: invoiceItems.length,
      });

    result.invoiceUpdateMutation.data.invoiceItems.update.push(
      invoiceItemUpdateMutation
    );

    result.updatedInvoice.invoiceItems.push(updatedInvoiceItem);
  }

  // console.log("allUserCredits", allUserCredits);

  invoiceItems
    .filter((i) => i.description !== "Outstanding Account Balance")
    .sort((a, b) => {
      const invoiceItemATotals = calculateInvoiceItemBalance(a);
      const invoiceItemBTotals = calculateInvoiceItemBalance(b);
      const invoiceItemABalance =
        invoiceItemATotals.totalOwed - invoiceItemATotals.totalPaid;
      const invoiceItemBBalance =
        invoiceItemBTotals.totalOwed - invoiceItemBTotals.totalPaid;

      return invoiceItemABalance - invoiceItemBBalance;
    })
    .forEach((invoiceItem, invoiceItemsIndex) => {
      // console.log("invoiceItem", invoiceItem);

      // let applyAvailableCredit = invoiceItemsIndex === invoiceItems.length - 1;

      // if (allUserCredits >= invoice.getBalance()) {
      //   applyAvailableCredit = true;
      // }

      const { invoiceItemUpdateMutation, updatedInvoiceItem } =
        calculateCreditsForInvoiceItem({
          userCredits,
          invoiceItem,
          numberOfInvoiceItems: invoiceItems.filter((ii) => ii.amount > 0)
            .length,
          // applyAvailableCredit,
        });

      // console.log("updatedInvoiceItem", updatedInvoiceItem);

      result.invoiceUpdateMutation.data.invoiceItems.update.push(
        invoiceItemUpdateMutation
      );

      result.updatedInvoice.invoiceItems.push(updatedInvoiceItem);
    });

  // if (invoice.user.id === "cl7rxxpk81300852wlpas5ee3h9q") {
  // console.log("userCredits", userCredits);
  // }

  userCredits.forEach((c) => {
    if (c.amount !== c.issuedAmount) {
      const credit = {
        data: {
          amount: c.amount,
        },
        where: {
          id: c.id,
        },
      };
      result.updateCreditsMutations.push(credit);
    }
  });

  result.updatedInvoice = new InvoiceModel({
    ...result.updatedInvoice,
    user: {
      ...result.updatedInvoice.user,
      credits: userCredits.map((c) => {
        const credit = {
          ...c,
        };

        delete credit.startingAmount;

        return credit;
      }),
    },
  });

  return result;
}
