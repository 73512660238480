import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import colors from "src/utils/colors";
import compose from "src/utils/compose";

interface Props extends RouteComponentProps {
  type?: string;
  className?: string;
  bgColor?: string;
  borderBottom?: boolean;
  borderTop?: boolean;
  noPadding?: boolean;
  children: ReactNode;
}

interface State {}

class Section extends React.Component<Props, State> {
  render() {
    return (
      <section className={this.props.className}>{this.props.children}</section>
    );
  }
}

const listStyles = css`
  background: ${colors.bgGray};
`;

const borderBottomStyles = css`
  border-bottom: 1px solid ${colors.borderGray};
`;

const borderTopStyles = css`
  border-top: 1px solid ${colors.borderGray};
`;

const StyledSection = styled(Section)`
  margin: auto;
  padding: ${(props) => (props.noPadding ? 0 : "1rem 0")};
  ${(props) => props.location.pathname === "/admin/settings" && listStyles};
  background: ${(props: Props) =>
    props.bgColor ? props.bgColor : "transparent"};
  ${(props) => props.borderBottom && borderBottomStyles};
  ${(props) => props.borderTop && borderTopStyles};
`;

export default compose(withRouter)(StyledSection);
