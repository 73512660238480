import { gql } from "graphql-tag";
import { withApiMutation } from "src/stores/Api";
import {
  addStripeExpressAccount,
  addStripeExpressAccountVariables,
  deleteStripeExpressAccount,
  deleteStripeExpressAccountVariables,
  deleteStripeSource,
  deleteStripeSourceVariables,
  disconnectStripeAccount,
  disconnectStripeAccountVariables,
  paymentIntent,
  paymentIntentVariables,
  stripeAccount,
  stripeAccountVariables,
  stripeAccounts,
  stripeAccountsVariables,
  stripeCustomer,
  stripeCustomerVariables,
  upsertStripeCustomer,
  upsertStripeCustomerVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

const ConnectStripeAccountMutation = gql`
  mutation connectStripeAccount($data: ConnectStripeAccountInput!) {
    connectStripeAccount(data: $data) {
      id
    }
  }
`;

const StripeAccountPayload = gql`
  fragment StripeAccountPayloadFragment on StripeAccountPayload {
    stripeAccountId
    verified
    link
    balance {
      available
      instantAvailable
    }
    bankAccount {
      bankName
      last4
      routingNumber
    }
  }
`;

export const StripeAccountQuery = gql`
  query stripeAccount($organizationId: ID!, $standard: Boolean) {
    stripeAccount(organizationId: $organizationId, standard: $standard) {
      ...StripeAccountPayloadFragment
    }
  }
  ${StripeAccountPayload}
` as TypedDocumentNode<stripeAccount, stripeAccountVariables>;

export const StripeCustomerQuery = gql`
  query stripeCustomer($stripeCustomerId: ID!) {
    stripeCustomer(stripeCustomerId: $stripeCustomerId)
  }
` as TypedDocumentNode<stripeCustomer, stripeCustomerVariables>;

export const PaymentIntentMutation = gql`
  mutation paymentIntent($userId: ID!, $amount: Int, $setupIntentId: ID) {
    paymentIntent(
      userId: $userId
      amount: $amount
      setupIntentId: $setupIntentId
    )
  }
` as TypedDocumentNode<paymentIntent, paymentIntentVariables>;

export const StripeAccountsQuery = gql`
  query stripeAccounts($organizationId: ID!) {
    stripeAccounts(organizationId: $organizationId) {
      ...StripeAccountPayloadFragment
    }
  }
  ${StripeAccountPayload}
` as TypedDocumentNode<stripeAccounts, stripeAccountsVariables>;

export const AddStripeExpressAccountMutation = gql`
  mutation addStripeExpressAccount($organizationId: ID!) {
    addStripeExpressAccount(organizationId: $organizationId) {
      ...StripeAccountPayloadFragment
    }
  }
  ${StripeAccountPayload}
` as TypedDocumentNode<
  addStripeExpressAccount,
  addStripeExpressAccountVariables
>;

export const DeleteStripeExpressAccountMutation = gql`
  mutation deleteStripeExpressAccount(
    $organizationId: ID!
    $stripeExpressAccountId: ID!
  ) {
    deleteStripeExpressAccount(
      organizationId: $organizationId
      stripeExpressAccountId: $stripeExpressAccountId
    )
  }
` as TypedDocumentNode<
  deleteStripeExpressAccount,
  deleteStripeExpressAccountVariables
>;

export const DisconnectStripeAccountMutation = gql`
  mutation disconnectStripeAccount($data: DisconnectStripeAccountInput!) {
    disconnectStripeAccount(data: $data) {
      success
    }
  }
` as TypedDocumentNode<
  disconnectStripeAccount,
  disconnectStripeAccountVariables
>;

export const UpsertStripeCustomerMutation = gql`
  mutation upsertStripeCustomer($data: UpsertStripeCustomerInput!) {
    upsertStripeCustomer(data: $data) {
      id
      stripeCustomerId
      stripeToken
    }
  }
` as TypedDocumentNode<upsertStripeCustomer, upsertStripeCustomerVariables>;

export const DeleteStripeSourceMutation = gql`
  mutation deleteStripeSource($data: DeleteStripeSourceInput!) {
    deleteStripeSource(data: $data) {
      success
    }
  }
` as TypedDocumentNode<deleteStripeSource, deleteStripeSourceVariables>;

export const ConnectStripeAccount = withApiMutation(
  ConnectStripeAccountMutation,
  {
    name: "connectStripeAccount",
  }
);
