type ToastError =
  | string
  | { errors: Array<{ message: string }> }
  | { message: string }
  | { networkError: { result: { errors: Array<{ message: string }> } } }
  | unknown;

export function getErrorString(e: ToastError) {
  if (!e) {
    return;
  }

  const error = e as any;

  if (typeof error === "string") {
    return error;
  }

  if ("errors" in error) {
    const errors = error.errors.map((e) => e.message || e).join(" • ");
    return errors;
  }

  if ("networkError" in error) {
    // console.log("error.networkError", error.networkError);

    const errors = error.networkError?.result?.errors || [];
    return errors.map((e) => e.message).join(" • ");
  }

  if ("message" in error) {
    return error.message;
  }
}
