import { ReactNode } from "react";
import { Link } from "react-router-dom";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  type?: string;
  onClick?: () => void;
  to?: any;
  auto?: boolean;
  isEmpty?: boolean;
  children?: ReactNode;
}

export default function HeaderButton(props: Props) {
  const styles = tw(
    "inline-block",
    "align-bottom",
    "text-organization",
    "h-[36px]",
    "w-[36px]",
    "overflow-hidden",
    "leading-[32px]",
    "text-center",
    "rounded-full",
    "border",
    "border-borderGray",
    "bg-white",
    "ml-2",
    "p-0",
    "decoration-none",
    "cursor-pointer",
    "transition",
    "focus:decoration-none",
    "hover:decoration-none hover:border-organization",
    props.type === "dark" &&
      "text-white border-transparent bg-organization hover:text-white hover:bg-organization",
    props.auto && "w-auto rounded px-2 text-sm",
    props.isEmpty && "bg-borderHoverGray",
    props.className
  );

  if (props.to) {
    return (
      <Link to={props.to} className={styles}>
        {props.children}
      </Link>
    );
  }

  if (props.onClick) {
    return (
      <span onClick={props.onClick} className={styles}>
        {props.children}
      </span>
    );
  }

  return <span className={styles}>{props.children}</span>;
}
