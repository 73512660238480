import AddonModel from "src/models/AddonModel";
import AgreementModel from "src/models/AgreementModel";
import BillingCategoryModel from "src/models/BillingCategoryModel";
import CouponModel from "src/models/CouponModel";
import MembershipOfferingModel from "src/models/MembershipOfferingModel";
import PrivateSerieModel from "src/models/PrivateSerieModel";
import { ZoneModel } from "src/models/ZoneModel";
import { ParticipantTypes, PrivateOfferingTypes } from "src/utils/constants";
import {
  AgeType,
  Day,
  DepartmentPayload,
  Fee,
  Frequency,
  OfferingStatus,
  PaymentFrequency,
  TimeType,
  communications,
  discounts,
  tags,
} from "types/code-generator";
import { ChargeModel } from "./ChargeModel";
import ClassTimeModel from "./ClassTimeModel";
import { CreditModel } from "./CreditModel";
import LocationModel from "./LocationModel";
import OrganizationModel from "./OrganizationModel";
import PriceAdjustmentModel from "./PriceAdjustment";
import ProgramModel from "./ProgramModel";
import StudentInfoRequestModel from "./StudentInfoRequestModel";
import UserModel from "./UserModel";

export const isOffering = (item): item is OfferingModel =>
  item instanceof OfferingModel;

class OfferingModel {
  additionalFeeAmount: number = null;
  additionalFeeBillingCategory: BillingCategoryModel = null;
  additionalFeeTitle: string = null;
  addons: AddonModel[] = [];
  agreements: AgreementModel[] = [];
  allowAdditionalStudents: boolean = false;
  allowExtension: boolean = true;
  allowNonMembers: boolean = true;
  applyAnnualFee: boolean = false;
  atAccountHome: boolean = false;
  availableDays: Day[] = [
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
  ];
  billingCategory: BillingCategoryModel;
  charges: ChargeModel[] = [];
  classTimes: ClassTimeModel[] = [];
  collectTaxes: boolean = false;
  communications: communications["communications"] = [];
  coupons: CouponModel[] = [];
  credits: CreditModel[] = [];
  customerInfoRequests: string[] = [];
  department: DepartmentPayload = null;
  description: string = null;
  discounts: discounts["discounts"] = [];
  duration: number = 30;
  endDate: Date = null;
  excludedDates: Date[] = [];
  extensionDiscounts: discounts["discounts"] = [];
  extensionFreePresentation: string;
  extensionMinAttendances: number = null;
  extensionMaxAttendances: number = null;
  extensionPrice: number = null;
  extensionPriceAdjustments: PriceAdjustmentModel[] = [];
  fees: Fee[] = [];
  firstLessonStart: Date = null;
  firstLessonStartDays: number = null;
  freePresentation: string;
  frequency: Frequency = Frequency.OneTime;
  hideDefaultStaff: boolean = true;
  instructor: UserModel = null;
  id: string = null;
  location: LocationModel;
  maxAge: number = null;
  maxAgeType: AgeType = null;
  maxInitial: number;
  membershipOfferings: MembershipOfferingModel[] = [];
  minAge: number = null;
  minAgeType: AgeType = null;
  minAttendances: number = null;
  minParticipants: number = null;
  maxParticipants: number = null;
  package: boolean = false;
  paymentFrequency: PaymentFrequency = PaymentFrequency.Upfront;
  order: number;
  organization: OrganizationModel;
  participantType: string = ParticipantTypes.Participant;
  pass: boolean = false;
  price: number = null;
  priceAdjustments: PriceAdjustmentModel[] = [];
  priceForAdditionalStudents: number = null;
  privateSeries: PrivateSerieModel[] = [];
  program: ProgramModel = null;
  prorateExcludedDates: boolean = true;
  publishDate: Date = null;
  publishOnWebsite: boolean = true;
  requestStartDate: boolean = true;
  registrationClose: Date = null;
  registrationOpen: Date = null;
  restrictReschedule: number = null;
  restrictRescheduleType: TimeType = null;
  startDate: Date = null;
  status: OfferingStatus;
  studentInfoRequests: StudentInfoRequestModel[] = [];
  tags: tags["tags"] = [];
  template: boolean = false;
  title: string = null;
  type: string = "Private Lesson";
  unpublishDate: Date = null;
  zone: ZoneModel = null;

  constructor(data) {
    Object.assign(this, data);

    if (data) {
      if (!data.type) {
        this.type = PrivateOfferingTypes["Private Lesson"];
      }
      if (!data.participantType) {
        this.participantType = ParticipantTypes.Participant;
      }
    }
  }

  get active() {
    return (
      this.status === OfferingStatus.InProgress ||
      this.status === OfferingStatus.Upcoming
    );
  }
}

export default OfferingModel;
