import styled from "@emotion/styled";
import colors from "src/utils/colors";

const MenuTitle = styled("h2")`
  font-size: 0.9rem;
  padding: 1rem;
  margin: 0;
  font-weight: normal;
`;

const MenuButtonContainer = styled("div")`
  padding: 1rem 1rem 0 1rem;
  & button {
    width: 100%;
  }
`;

const MenuItemContainer = styled("div")`
  border-bottom: 1px solid ${colors.borderGray};
  margin: 0 0 1rem 0;
`;

export { MenuButtonContainer, MenuTitle, MenuItemContainer };
