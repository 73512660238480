import gql from "graphql-tag";
import moment from "moment-timezone";
import { ReferralPayloadFragment } from "src/api/ReferralTemplate";
import { UpdateUserMutation } from "src/api/User";
import { BorderButton } from "src/components/buttons/BorderButton";
import { UserReferralModal } from "src/components/modals/UserReferralModal";
import { UserReferralsProgress } from "src/components/referrals/UserReferralsProgress";
import { UserReferralTemplateQuery } from "src/routes/admin/AdminLanding";
import { apiClient, useApi } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import { sm } from "src/styles/utility";
import colors from "src/utils/colors";
import formatMoney from "src/utils/formatMoney";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import {
  UserReferralStatus,
  updateUserVariables,
  userReferrals,
  userReferralsVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

interface Props {
  small?: boolean;
}

export function UserReferralTemplate(props: Props) {
  const store = useStore();
  const today = moment().startOf("day");

  const queryUserReferralTemplate = useApi(UserReferralTemplateQuery, {
    skip: !store.user || (store.user && !store.user.isStaff),
    variables: {
      where: {
        userId: store.user?.id,
        referralTemplate: {
          stopShowing: {
            gte: today.toDate(),
          },
        },
      },
    },
    queryKey: ["userReferralTemplate", store.user?.id],
  });

  const queryUserReferrals = useApi(UserReferralsQuery, {
    skip: !store.user || (store.user && !store.user.isStaff),
    variables: {
      userId: store.user.id,
    },
  });

  const userReferralTemplate = queryUserReferralTemplate.userReferralTemplate;
  const userReferrals = queryUserReferrals.user?.referrals || [];

  function learnMore() {
    modals.show(UserReferralModal, {
      userReferralTemplate,
      type: "learnMore",
    });
  }

  function dismiss() {
    modals.show(UserReferralModal, {
      userReferralTemplate,
      type: "dismiss",
      handleCancel: async () => {
        const variables: updateUserVariables = {
          data: {
            referralTemplate: {
              update: {
                status: UserReferralStatus.Dismissed,
              },
            },
          },
          where: {
            id: store.user.id,
          },
        };

        await apiClient(UpdateUserMutation, {
          variables,
        });

        queryUserReferralTemplate.refetch();
      },
      handleOk: () => {
        addReferral();
      },
    });
  }

  function addReferral() {
    modals.show(UserReferralModal, {
      userReferralTemplate,
      type: "newReferral",
      handleCancel: () => {
        queryUserReferrals.refetch();
      },
    });
  }

  if (!userReferralTemplate) {
    return null;
  }

  const firstReferralDeadline = moment(
    userReferralTemplate.referralTemplate?.firstReferralDeadline
  );
  const daysLeft = firstReferralDeadline.diff(today, "days");
  const largest = userReferralTemplate.referralTemplate.milestones.reduce(
    (acc, m) => acc + m.amount,
    0
  );
  let payouts = 0;

  for (const r of userReferrals) {
    const milestones = r.referralTemplate.milestones.sort(
      (a, b) => a.amount - b.amount
    );

    for (const [i, m] of milestones.entries()) {
      if (i <= r.milestoneProgress) {
        payouts += m.amount;
      }
    }
  }

  if (
    props.small &&
    userReferralTemplate?.status === UserReferralStatus.Dismissed
  ) {
    return (
      <div className="relative overflow-hidden rounded-lg bg-dark p-4">
        <p className="mb-2 font-bold text-white">
          💛
          <br />
          Refer organizations to Captyn and get paid in just one step.
        </p>

        <p className="text-white">Up to ${formatMoney(largest)} per referral</p>

        <div className="flex items-center gap-4">
          <BorderButton type="submit" onClick={addReferral} className="flex-1">
            Refer now
          </BorderButton>

          <button
            type="button"
            onClick={learnMore}
            className="flex-1 text-white underline"
          >
            Learn More
          </button>
        </div>

        <div className="absolute -right-10 -top-10 h-20 w-20 rotate-45 bg-organization" />
      </div>
    );
  }

  if (
    userReferralTemplate.status === UserReferralStatus.Dismissed ||
    (userReferralTemplate.status === UserReferralStatus.Active && props.small)
  ) {
    return null;
  }

  return (
    <>
      <div
        className={tw(
          "relative flex overflow-hidden rounded-t-lg bg-dark sm:flex-col",
          userReferrals.length > 0 ? "rounded-b-none" : "rounded-b-lg"
        )}
        css={{
          background: `linear-gradient(-60deg, ${colors.action} 0%, ${colors.action} 33%, ${colors.dark} 33%, ${colors.dark} 100%);`,
          [`@media (${sm})`]: {
            background: colors.dark,
          },
        }}
      >
        <div className="flex-1 px-6 py-4">
          <h2 className="mb-0 text-white sm:pr-4 sm:pt-2">
            💛 Refer organizations to Captyn and get paid!
          </h2>

          <p className="text-main">
            It’s easy. Send an invite to someone you think would love to see
            Captyn.
          </p>

          <div className="mb-3">
            <BorderButton type="submit" onClick={addReferral}>
              Refer now in one step!
            </BorderButton>
          </div>

          <p className="mb-0 text-white">
            You’ve started {userReferrals.length} referral
            {userReferrals.length !== 1 && "s"}.{" "}
            <span className="pl-1">💪 Keep going!</span>
          </p>

          {userReferrals.length === 0 && daysLeft >= 0 && (
            <p className="mb-0 text-sm text-empty">
              <i className="far fa-hourglass-clock" />{" "}
              {daysLeft.toLocaleString()} day{daysLeft !== 1 && "s"} to go
            </p>
          )}
        </div>

        <div className="overflow-visible pr-11 text-center sm:bg-organization sm:pr-0">
          <img
            src="/img/captyn-symbol-white.svg"
            alt="Captyn"
            className="-mt-1 w-16 sm:-mt-px"
          />

          {userReferrals.length === 0 && (
            <>
              <p className="mb-0 text-white">We’ll pay you up to</p>
              <h2 className="mb-0 text-4xl text-white">
                ${formatMoney(largest)}
              </h2>
              <p className="mb-0 text-white">per referral</p>
              <button
                type="button"
                onClick={learnMore}
                className="mb-4 text-main underline"
              >
                Learn More
              </button>
            </>
          )}

          {userReferrals.length > 0 && (
            <>
              <p className="mb-0 text-white">Earned so far…</p>
              <h2 className="mb-0.5 text-4xl text-white">
                ${formatMoney(payouts)}
              </h2>
              <button
                type="button"
                onClick={learnMore}
                className="mb-4 text-main underline"
              >
                Learn More
              </button>
            </>
          )}

          <button
            type="button"
            className="absolute right-4 top-2"
            onClick={dismiss}
          >
            <i className="far fa-xmark text-white opacity-50" />
          </button>
        </div>
      </div>

      {userReferrals.length > 0 && (
        <div className="mb-12">
          <UserReferralsProgress
            userReferrals={userReferrals}
            userReferralTemplate={userReferralTemplate}
          />
        </div>
      )}
    </>
  );
}

const UserReferralsQuery = gql`
  query userReferrals($userId: String) {
    user(where: { id: $userId }) {
      referrals {
        ...ReferralPayload
      }
    }
  }
  ${ReferralPayloadFragment}
` as TypedDocumentNode<userReferrals, userReferralsVariables>;
