import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import { PrivateSerieAttendanceStatus } from "types/code-generator";
import LocationModel from "./LocationModel";
import PrivateSerieModel from "./PrivateSerieModel";
import UserModel from "./UserModel";
import { ZoneModel } from "./ZoneModel";

export const isPrivateSerieAttendance = (
  attendance
): attendance is PrivateSerieAttendanceModel =>
  attendance instanceof PrivateSerieAttendanceModel;

export default class PrivateSerieAttendanceModel {
  atAccountHome: boolean = false;
  automated: boolean = false;
  completedBy: UserModel;
  completedOn: Date;
  createdAt: Date;
  date: Date = new Date();
  duration: number;
  id: string;
  instructor: UserModel;
  invoiceItems: InvoiceItemModel[] = [];
  location: LocationModel;
  note: string;
  number: number;
  privateSerie: PrivateSerieModel;
  scheduledBy: UserModel;
  scheduledOn: Date;
  status: PrivateSerieAttendanceStatus;
  studentsAdded: UserModel[] = [];
  studentsAttended: UserModel[] = [];
  time: string;
  updatedAt: Date;
  verifiedBy: UserModel;
  verifiedOn: Date;
  zone: ZoneModel;

  constructor(data: PrivateSerieAttendanceModel | any) {
    Object.assign(this, data);

    if (data?.privateSerie) {
      this.privateSerie = new PrivateSerieModel(data.privateSerie);
    }
  }
}
