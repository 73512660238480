import { Link, useHistory } from "react-router-dom";
import { BorderButton } from "src/components/buttons/BorderButton";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { useUI } from "src/stores/UI";
import colors from "src/utils/colors";
import {
  MenuButtonContainer,
  MenuItemContainer,
  MenuTitle,
} from "./MenuComponents";
import { MenuContainer } from "./MenuContainer";
import { MenuItem } from "./MenuItem";

export default function SuperAdminMenu(props) {
  const store = useStore();
  const ui = useUI();
  const session = useSession();
  const history = useHistory();
  const orgName = store.organization.title;

  return (
    <>
      <MenuContainer>
        <MenuTitle>
          <strong>{orgName}</strong>
          <br />
          {store.user.firstName}, Super Admin
        </MenuTitle>

        <MenuItemContainer>
          <MenuItem to="/super-admin">Home</MenuItem>
          <MenuItem to="/super-admin/organizations">Organizations</MenuItem>
          <MenuItem to="/super-admin/users">Captyn Accounts</MenuItem>
          <MenuItem to="/super-admin/invoices">Invoices</MenuItem>
          <MenuItem to="/super-admin/referrals">Referrals</MenuItem>
          <MenuItem to="/super-admin/settings">Settings</MenuItem>
          <MenuItem to="/account/settings">My Profile</MenuItem>
        </MenuItemContainer>

        {store.user && (
          <MenuButtonContainer>
            <BorderButton
              onClick={() => {
                store.signOut();
                session.clearSession();
                history.push("/login");
              }}
              bgColor={colors.lightGray}
            >
              Sign Out
            </BorderButton>
          </MenuButtonContainer>
        )}

        <div className="py-4 text-center text-xs text-empty">
          <Link to="/terms-of-service" className="text-empty">
            <strong>Terms of Service</strong>
          </Link>{" "}
          •{" "}
          <Link to="/privacy-policy" className="text-empty">
            <strong>Privacy Policy</strong>
          </Link>
        </div>
      </MenuContainer>
      {ui.menuOpen && (
        <div
          className="fixed right-0 top-0 h-full w-[calc(100vw-300px)] bg-transparent"
          onClick={() => {
            ui.toggleMenu();
          }}
        />
      )}
    </>
  );
}
