import { DiscountType, Frequency, PaymentType } from "types/code-generator";

export interface Balance {
  totalBillable: number;
  totalCaptyn: number;
  totalCard: number;
  totalCardRefund: number;
  totalCash: number;
  totalCashRefund: number;
  totalClassDiscounts: number;
  totalCoupons: number;
  totalCredits: number;
  totalDiscounts: number;
  totalEnrollments: number;
  totalFees: number;
  totalLineItemFees: number;
  totalOwed: number;
  totalPaid: number;
  totalRefunded: number;
  totalStudentDiscounts: number;
  totalTaxes: number;
}

function sum(array) {
  if (!array || array.length === 0) {
    return 0;
  }
  return array.reduce((acc, p) => acc + p.amount, 0);
}

export default function calculateInvoiceItemBalance(invoiceItem: {
  amount: number;
  captyn?: Frequency;
  credit?: { amount: number };
  description: string;
  discounts?: { amount: number; discount: { discountType: DiscountType } }[];
  fee?: {
    id: string;
    amount: number;
  };
  groupRegistration?: {
    id: string;
  };
  membership?: {
    id: string;
  };
  omit?: boolean;
  payments?: {
    amount: number;
    payment: { stripeFee: number; stripeFeeAmount: number; type: PaymentType };
  }[];
  refunds?: { amount: number; type: PaymentType }[];
  coupons?: { amount: number }[];
  credits?: { amount: number }[];
  privateSerie?: {
    id: string;
  };
}): Balance {
  let totalBillable = invoiceItem.amount || 0;

  if ((invoiceItem as any).omit) {
    totalBillable = 0;
  }

  // if (invoiceItem.credit) {
  //   totalBillable = 0;
  // }

  const totalPaid = sum(invoiceItem.payments);

  const totalFees = invoiceItem.payments
    ? invoiceItem.payments.reduce((acc, iip) => {
        let stripeFee = 0.03;

        if (iip.payment?.stripeFee || iip.payment?.stripeFee === 0) {
          stripeFee = iip.payment.stripeFee;
        }

        return acc + iip.amount * stripeFee;
      }, 0)
    : 0;

  let totalCard = 0;
  let totalCash = 0;

  if (invoiceItem.payments) {
    invoiceItem.payments.forEach((p) => {
      if (p.payment && p.payment.type === PaymentType.Card) {
        totalCard = totalCard + p.amount;
        return;
      }
      if (p.payment && p.payment.type === PaymentType.Cash) {
        totalCash = totalCash + p.amount;
        return;
      }
    });
  }

  let totalRefunded = 0;
  let totalCardRefund = 0;
  let totalCashRefund = 0;

  if (invoiceItem.refunds) {
    invoiceItem.refunds.forEach((r) => {
      totalRefunded = totalRefunded + r.amount;

      if (r.type === PaymentType.Card) {
        totalCardRefund = totalCardRefund + r.amount;
        return;
      }

      if (r.type === PaymentType.Cash) {
        totalCashRefund = totalCashRefund + r.amount;
        return;
      }
    });
  }

  let totalDiscounts = 0;
  let totalClassDiscounts = 0;
  let totalStudentDiscounts = 0;

  if (invoiceItem.discounts) {
    invoiceItem.discounts.forEach((d) => {
      totalDiscounts = totalDiscounts + d.amount;

      if (d.discount && d.discount.discountType === DiscountType.MultiClass) {
        totalClassDiscounts = totalClassDiscounts + d.amount;
        return;
      }

      if (d.discount && d.discount.discountType === DiscountType.MultiStudent) {
        totalStudentDiscounts = totalStudentDiscounts + d.amount;
        return;
      }
    });
  }

  const totalEnrollments =
    invoiceItem.groupRegistration ||
    invoiceItem.privateSerie ||
    invoiceItem.membership
      ? invoiceItem.amount
      : 0;

  const totalCoupons = sum(invoiceItem.coupons);
  const totalCredits = sum(invoiceItem.credits);

  // const totalCaptyn =
  //   invoiceItem.payments && invoiceItem.captyn
  //     ? invoiceItem.payments.reduce((acc, iip) => {
  //         const stripeFee = 0.03;
  //         const received = iip.amount - iip.amount * stripeFee;

  //         return acc + received;
  //       }, 0)
  //     : 0;
  const totalCaptyn = invoiceItem.captyn ? invoiceItem.amount : 0;

  let totalOwed = totalBillable - totalDiscounts - totalCoupons - totalCredits;

  if (invoiceItem.credit && !(invoiceItem as any).omit) {
    totalOwed -= invoiceItem.credit.amount;
  }

  // if (totalBillable > 0 && totalOwed < 0) {
  //   totalOwed = 0;
  // }

  let totalTaxes = 0;

  if (invoiceItem.description === "Sales Tax") {
    totalTaxes = invoiceItem.amount || 0;
  }

  let totalLineItemFees = 0;

  if (invoiceItem.fee) {
    totalLineItemFees = invoiceItem.amount || 0;
  }

  const totals = {
    totalBillable,
    totalCaptyn,
    totalCard,
    totalCardRefund,
    totalCash,
    totalCashRefund,
    totalClassDiscounts,
    totalCoupons,
    totalCredits,
    totalDiscounts,
    totalEnrollments,
    totalFees,
    totalLineItemFees,
    totalOwed,
    totalPaid,
    totalRefunded,
    totalStudentDiscounts,
    totalTaxes,
  };

  // console.log("totals", totals);

  return totals;
}
