import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface WithUIProps {
  ui: UI;
}

type UI = ReturnType<typeof UIStore>;

function UIStore() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [scrolls, setScrolls] = useState({});
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location.pathname]);

  function toggleMenu() {
    setMenuOpen((current) => {
      return !current;
    });
  }

  return {
    filterOpen,
    menuOpen,
    scrolls,
    setFilterOpen,
    setMenuOpen,
    setScrolls,
    toggleMenu,
  };
}

const UIContext = createContext<UI>(null);

export const UIProvider = (props) => {
  const ui = UIStore();

  return <UIContext.Provider value={ui}>{props.children}</UIContext.Provider>;
};

export function withUI(Component) {
  return function WithUIComponent(props) {
    const ui = useUI();

    return <Component {...props} ui={ui} />;
  };
}

export const useUI = () => {
  const ui = useContext(UIContext);
  return ui;
};
