import { ReactNode } from "react";
import tw from "src/utils/tw";

interface Props {
  background?: string;
  error?: boolean;
  children: ReactNode;
}

export default function ManageIcon(props: Props) {
  return (
    <div
      className={tw(
        "flex h-12 w-12 items-center justify-center rounded-full text-2xl",
        props.error ? "bg-paleError" : !props.background && "bg-paleAction",
        !props.error && "text-organization"
      )}
      css={{
        background: props.background,
      }}
    >
      {props.children}
    </div>
  );
}
