import { BorderButton } from "src/components/buttons/BorderButton";
import { CheckboxGroup } from "src/components/forms/CheckboxGroup";
import { Form, useForm } from "src/components/forms/Form";
import { InputItem } from "src/components/inputs/InputItem";
import Modal, { ModalProps } from "src/components/modals/Modal";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { modals, useModal } from "src/utils/modals";

interface Props extends ModalProps {}

export const DepartmentSelectModal = modals.create((props: Props) => {
  const modal = useModal();
  const store = useStore();
  const form = useForm();
  const session = useSession();

  function validateDepartments(rule, value, callback) {
    const errors = [];

    const departments = form.getFieldValue("departments");

    if (departments.length === 0) {
      callback("Please select at least one department.");
    }

    callback(errors);
  }

  function selectAll() {
    let departments = store.user.departments
      .filter((d) => d.organization.id === store.organization.id)
      .map((d) => d.id);

    if (store.user.isSalesOrAbove) {
      departments = store.organization.departments.map((d) => d.id);
    }

    form.setFieldsValue({
      departments,
    });
  }

  function selectNone() {
    form.setFieldsValue({
      departments: [],
    });
  }

  function onSubmit() {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log("values", values);

      let newDepartments = store.user.departments.filter((d) => {
        return values.departments.includes(d.id);
      });

      if (store.user.isSalesOrAbove) {
        newDepartments = store.organization.departments.filter((d) => {
          return values.departments.includes(d.id);
        });
      }

      session.setDepartments(newDepartments);

      modal.hide();
    });
  }

  let options =
    store.user?.departments
      ?.slice()
      .sort((a, b) => a.title?.localeCompare(b.title))
      // .filter((d) => d.organization.id === store.organization.id)
      .map((d) => {
        return {
          label: d.title,
          value: d.id,
        };
      }) || [];

  if (store.user?.isSalesOrAbove) {
    options = store.organization.departments
      .slice()
      .sort((a, b) => a.title?.localeCompare(b.title))
      .map((d) => {
        return {
          label: d.title,
          value: d.id,
        };
      });
  }

  const checkboxes = form.getFieldDecorator("departments", {
    initialValue: session.departments.map((d) => d.id),
    rules: [
      {
        validator: validateDepartments,
      },
    ],
  })(<CheckboxGroup border options={options} className="items-center" />);

  return (
    <Modal
      visible={modal.visible}
      handleCancel={modal.hide}
      afterClose={modal.remove}
      footer={
        <>
          <BorderButton type="submit" onClick={onSubmit}>
            Apply
          </BorderButton>

          {form.getFieldValue("departments").length ===
          session.departments.length ? (
            <BorderButton onClick={selectNone}>Select None</BorderButton>
          ) : (
            <BorderButton onClick={selectAll}>Select All</BorderButton>
          )}

          <BorderButton onClick={modal.hide}>Cancel</BorderButton>
        </>
      }
      title="Select departments to filter by"
    >
      <Form onSubmit={onSubmit}>
        <div className="-mt-4 mb-4">
          <InputItem>{checkboxes}</InputItem>
        </div>
      </Form>
    </Modal>
  );
});
