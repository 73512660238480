import { gql } from "graphql-tag";
import {
  createTag,
  createTagVariables,
  deleteTag,
  deleteTagVariables,
  tags,
  tagsVariables,
  updateTag,
  updateTagVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const TagPayloadFragment = gql`
  fragment TagPayload on Tag {
    id
    hideInFilters
    order
    title
    department {
      id
      title
    }
    offerings {
      id
    }
    classes {
      id
    }
  }
`;

export const UpdateTagMutation = gql`
  mutation updateTag($data: TagCreateInput!, $where: UniqueInput!) {
    updateTag(data: $data, where: $where) {
      ...TagPayload
    }
  }
  ${TagPayloadFragment}
` as TypedDocumentNode<updateTag, updateTagVariables>;

export const DeleteTagMutation = gql`
  mutation deleteTag($id: String!) {
    deleteTag(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<deleteTag, deleteTagVariables>;

export const CreateTagMutation = gql`
  mutation createTag($data: TagCreateInput!) {
    createTag(data: $data) {
      ...TagPayload
    }
  }
  ${TagPayloadFragment}
` as TypedDocumentNode<createTag, createTagVariables>;

export const TagsQuery = gql`
  query tags($where: TagWhereInput) {
    tags(where: $where, orderBy: { order: asc }) {
      ...TagPayload
    }
  }
  ${TagPayloadFragment}
` as TypedDocumentNode<tags, tagsVariables>;
