import OrganizationModel from "src/models/OrganizationModel";
import PrivateSerieModel from "src/models/PrivateSerieModel";
import calculateCouponsForRegistration from "src/utils/calculateCouponsForRegistration";
import { calculatePricingForPrivateSerie } from "src/utils/calculatePricingForPrivateSerie";
import { coupons } from "types/code-generator";
import { v4 as uuid } from "uuid";

interface GetInvoiceItemForPrivateSerieInput {
  registration: PrivateSerieModel;
  coupons: coupons["coupons"];
  organization: OrganizationModel;
  startDate: Date;
}

export function getInvoiceItemForPrivateSerie({
  registration,
  coupons,
  organization,
  startDate,
}: GetInvoiceItemForPrivateSerieInput) {
  const pricingData = calculatePricingForPrivateSerie({
    privateSerie: registration,
    organization,
  });
  const description = `${
    registration.offering.title ?? registration.offering.type
  }: ${registration.numberOfLessons} Lesson${
    registration.numberOfLessons !== 1 ? "s" : ""
  }`;

  const registrationCoupons = calculateCouponsForRegistration({
    coupons,
    registration,
    startDate,
    organization,
  });

  const invoiceItem = {
    id: uuid(),
    amount: pricingData.price,
    description,
    discounts: pricingData.discounts,
    privateSerie: registration,
    coupons: registrationCoupons,
  };

  return invoiceItem;
}
