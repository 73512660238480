import { FileModel } from "src/models/FileModel";
import { Balance } from "src/utils/calculateInvoiceItemBalance";
import { InvoiceStatus } from "types/code-generator";
import calculateInvoiceBalance from "../utils/calculateInvoiceBalance";
import { InvoiceItemModel } from "./InvoiceItemModel";
import OrganizationModel from "./OrganizationModel";
import UserModel from "./UserModel";

export const isInvoice = (item): item is InvoiceModel => {
  return (item && item.invoiceItems) || item instanceof InvoiceModel;
};

export default class InvoiceModel {
  automated: boolean;
  createdAt: Date;
  file: FileModel;
  id: string;
  invoiceItems: InvoiceItemModel[] = [];
  invoiceDate: Date;
  noPayment: boolean;
  organization: OrganizationModel;
  receipt: FileModel;
  serviceRunId: string;
  status: InvoiceStatus;
  updatedAt: Date;
  user: UserModel;

  constructor(data: InvoiceModel | {}) {
    Object.assign(this, data);
  }

  getTotals(): Balance {
    return calculateInvoiceBalance(this);
  }

  getBalance() {
    const totals = this.getTotals();
    return parseInt((totals.totalOwed - totals.totalPaid).toString());
  }
}
