import { gql } from "graphql-tag";
import { updateOnboard, updateOnboardVariables } from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const OnboardPayloadFragment = gql`
  fragment OnboardPayload on Onboard {
    id
    department {
      id
      title
    }
    organization {
      id
    }
    status
    type
    updatedAt
    user {
      id
      firstName
      lastName
    }
  }
`;

export const OnboardsForUser = gql`
  query onboardsForUser($userId: String, $organizationId: String) {
    onboards(
      where: {
        userId: $userId
        OR: [{ organizationId: $organizationId }, { organizationId: null }]
      }
    ) {
      ...OnboardPayload
    }
  }
  ${OnboardPayloadFragment}
`;

export const UpdateOnboardMutation = gql`
  mutation updateOnboard($data: OnboardCreateInput!, $where: UniqueInput!) {
    updateOnboard(where: $where, data: $data) {
      ...OnboardPayload
    }
  }
  ${OnboardPayloadFragment}
` as TypedDocumentNode<updateOnboard, updateOnboardVariables>;
