import React from "react";

export default function lazyImport(importPromise, errCallback = errorCallback) {
  return React.lazy(() => {
    return componentLoader(importPromise, 3).catch(() => {
      window.alert("Your Captyn has been updated to the latest version.");
      errCallback();
    });
  });
}

const errorCallback = () => {
  window.location.reload();
};

function componentLoader(lazyComponent, attemptsLeft): any {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }

          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 5000);
      });
  });
}
