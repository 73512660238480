import { useModal } from "@ebay/nice-modal-react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { OrganizationsQuery } from "src/api/Organization";
import { BorderButton } from "src/components/buttons/BorderButton";
import { InputText } from "src/components/inputs/InputText";
import List from "src/components/layout/List";
import Loading from "src/components/layout/Loading";
import Modal from "src/components/modals/Modal";
import OrganizationModel from "src/models/OrganizationModel";
import { useApi } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import { modals } from "src/utils/modals";
import { useFakeInput } from "src/utils/useFakeInput";
import { Level, OrganizationStatus } from "types/code-generator";

interface Props {}

export const OrganizationsModal = modals.create((props: Props) => {
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const modal = useModal();
  const store = useStore();
  const fakeInput = useFakeInput();
  const history = useHistory();

  const organizationsQuery = useApi(OrganizationsQuery);

  useEffect(() => {
    if (modal.visible && fakeInput && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
      fakeInput.remove();
    }
  }, [modal.visible, inputRef.current, fakeInput]);

  async function handleOrganizationClick(organization) {
    let url = `//${organization.subdomain}.captyn.com`;

    if (process.env.NODE_ENV === "development") {
      url = `//${organization.subdomain}.local.localhost:3000`;
    }

    if (store.isDemo) {
      url = `//${organization.subdomain}.nytpac.com`;
    }

    window.location.href = url;
  }

  function handleChange(newValue) {
    if (newValue !== value) {
      setValue(newValue);
    }
  }

  function clearValue() {
    if (inputRef.current?.input) {
      inputRef.current.input.focus();
    }

    setValue(null);
  }

  function renderOrganization(org: OrganizationModel, index) {
    return (
      <div
        className="cursor-pointer border-b border-borderGray p-4 hover:bg-bgGray"
        key={org.id}
      >
        <div className="flex">
          <div className="flex-1" onClick={() => handleOrganizationClick(org)}>
            {org.title}
            <div className="text-sm text-empty">{org.contactName}</div>
          </div>

          <BorderButton
            round
            onClick={() => {
              modal.hide();
              history.push(`/super-admin/organizations/${org.id}`);
            }}
            className="relative z-10"
          >
            <i aria-hidden className="far fa-gear text-organization" />
          </BorderButton>
        </div>
      </div>
    );
  }

  const organizations = organizationsQuery.organizations || [];
  const filteredOrganizations = organizations
    .filter((o) => {
      if (store.user?.level === Level.Sales) {
        return (
          o.accountManager?.id === store.user.id ||
          o.salesPerson?.id === store.user.id
        );
      } else {
        return o.status !== OrganizationStatus.Demo;
      }
    })
    .filter((o) => {
      if (value) {
        return (
          o.title.toLowerCase().includes(value.toLowerCase()) ||
          o.subdomain.includes(value.toLowerCase())
        );
      } else {
        return true;
      }
    })
    .sort((a, b) => a.title?.localeCompare(b.title));

  return (
    <Modal
      visible={modal.visible}
      handleCancel={modal.hide}
      afterClose={modal.remove}
      large
      hideHeader
    >
      <div className="flex h-full flex-1 sm:flex-col">
        <div className="flex-1 border-borderGray bg-paleAction p-4 sm:flex-none sm:border-b sm:pb-3 md:border-r">
          <div className="flex">
            <div className="relative flex-1">
              <InputText
                placeholder="Find in list"
                value={value}
                onChange={handleChange}
                autoFocus
                ref={inputRef}
                className="rounded-full"
                allowClear
              />
              {(organizationsQuery.loading || value) && (
                <div className="absolute bottom-0 right-4 top-0 flex items-center">
                  {organizationsQuery.loading ? (
                    <div>
                      <Loading small css={{ padding: 0 }} />
                    </div>
                  ) : value ? (
                    <div
                      className="cursor-pointer text-empty"
                      onClick={clearValue}
                    >
                      <i aria-hidden className="far fa-circle-xmark" />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="ml-4 w-12">
              <BorderButton round bgColor={colors.white} onClick={modal.hide}>
                <i aria-hidden className="far fa-xmark text-organization" />
              </BorderButton>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-auto">
          <List
            rows={filteredOrganizations}
            renderRow={renderOrganization}
            loading={organizationsQuery.loading}
            emptyMessage={"No organizations found matching that term."}
          />
        </div>
      </div>
    </Modal>
  );
});
