import { gql } from "graphql-tag";

export const ClassTimePayloadFragment = gql`
  fragment ClassTimePayload on ClassTime {
    id
    active
    date
    day
    endTime
    location {
      id
      address1
      city
      state
      title
      zip
    }
    startTime
    zone {
      id
      title
    }
  }
`;
