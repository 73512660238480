import { debounce } from "lodash-es";
import { ReactNode, useEffect, useRef } from "react";
import { useUI } from "src/stores/UI";
import tw from "src/utils/tw";
import { useIntersectionObserver } from "src/utils/useIntersectionObserver";

interface Props {
  rows: any[];
  renderRow: (item, index) => any;
  onEndReached?: (rows: any[]) => void;
  className?: string;
  loading?: boolean;
  useWindowScroll?: boolean;
  emptyMessage?: string | ReactNode;
  scrollKey?: string;
  scrollOffset?: number;
  triggerOnNoScroll?: boolean;
  children?: ReactNode;
}

function List(props: Props) {
  const ui = useUI();

  useEffect(() => {
    if (!props.scrollKey) {
      return;
    }

    const list = document.querySelector(`#${props.scrollKey}`);
    const key = ui.scrolls[props.scrollKey];

    if (!list) {
      return;
    }

    if (key && key !== 0) {
      list.scrollTo(0, key);
      ui.scrolls[props.scrollKey] = 0;
    }

    return () => {
      const list = document.querySelector(`#${props.scrollKey}`);
      if (list) {
        ui.scrolls[props.scrollKey] = list.scrollTop;
      }
    };
  }, [props.scrollKey]);

  // useEffect(() => {
  //   if (
  //     rows.length === 10 &&
  //     onEndReached &&
  //     !loading
  //   ) {
  //     onEndReached(rows);
  //   }
  // }, [rows]);

  const onBottom = debounce(() => {
    if (props.onEndReached) {
      props.onEndReached(props.rows);
    }
  }, 500);

  const bottomRef = useRef(null);

  useIntersectionObserver({
    target: bottomRef,
    onIntersect: onBottom,
    // enabled: !small && !useWindowScroll,
  });

  if (props.rows?.length === 0 && !props.loading) {
    return (
      <div
        className={tw(
          "container-type-size overflow-auto sm:h-full md:pb-14",
          props.className
        )}
      >
        <div className="p-4 text-sm text-empty">
          <strong>
            {typeof props.emptyMessage !== "undefined"
              ? props.emptyMessage
              : "No items to display."}
          </strong>
        </div>
        <div ref={bottomRef} className="h-px" />
      </div>
    );
  }

  return (
    <div
      id={props.scrollKey || "scrolls"}
      className={tw(
        "container-type-size pb-14",
        !props.useWindowScroll && "h-full overflow-auto",
        props.className
      )}
    >
      {props.children}
      {props.rows?.map((r, i) => props.renderRow(r, i))}
      <div ref={bottomRef} className="h-px" />
    </div>
  );
}

export default List;
