import React, { ReactElement, ReactNode } from "react";
import tw from "src/utils/tw";

interface Props {
  className?: string;
  disabled?: boolean;
  border?: boolean;
  value?: string | boolean;
  onChange?: (values) => void;
  children: ReactNode;
}

export function InputRadioGroup(props: Props) {
  function getControls(children: React.ReactNode, recursively: boolean) {
    let controls: React.ReactElement<any, any>[] = [];
    const childrenArray = React.Children.toArray(children);

    for (let i = 0; i < childrenArray.length; i++) {
      if (!recursively && controls.length > 0) {
        break;
      }

      const child = childrenArray[i] as React.ReactElement<any, any>;

      if (!child.props) {
        continue;
      }

      // console.log("child.type", child);

      if ("value" in child.props) {
        controls.push(child);
      } else if (child.props.children) {
        controls = controls.concat(
          getControls(child.props.children, recursively)
        );
      }
    }
    return controls;
  }

  let value = props.value;

  if (props["data-__meta"]?.initialValue && props.value === undefined) {
    value = props["data-__meta"].initialValue;
  }

  function onChange(newValue) {
    // console.log("newValue", newValue);
    props.onChange(newValue);
  }

  const children = getControls(props.children, true);

  const childElements = children.map((c, i) => {
    const element = c as ReactElement;

    const newElement = React.cloneElement(element, {
      ...element.props,
      onChange: () => onChange(element.props.value),
      checked: value === element.props.value,
      className: tw(element.props.className),
    });

    return (
      <div
        key={i}
        className={tw(props.border && "border-b border-borderGray", "py-2")}
      >
        {newElement}
      </div>
    );
  });

  return (
    <div
      className={tw(
        props.border && "mb-4 border-t border-borderGray",
        props.className
      )}
    >
      {childElements}
    </div>
  );
}
