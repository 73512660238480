import { Transition, TransitionChild } from "@headlessui/react";
import { ReactNode, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { BorderButton } from "src/components/buttons/BorderButton";
import { md, sm } from "src/styles/utility";
import colors from "src/utils/colors";
import { isServer } from "src/utils/isServer";
import tw from "src/utils/tw";
import { useMediaQueries } from "src/utils/useMediaQueries";

export interface ModalProps {
  visible?: boolean;
  handleCancel?: () => void;
  handleOk?: (any?: any) => void;
  children?: ReactNode;
  title?: string | ReactNode;
  footer?: ReactNode;
  afterClose?: () => void;
  hideClose?: boolean;
  className?: string;
  disabled?: boolean;
  // render?: () => ReactNode;
  hideHeader?: boolean;
  large?: boolean;
  medium?: boolean;
  fixedHeight?: boolean;
  headerComponent?: ReactNode;
}

export default function Modal(props: ModalProps) {
  const container = useMemo(() => {
    return isServer
      ? null
      : document.body.appendChild(document.createElement("div"));
  }, []);

  useEffect(() => {
    return () => {
      container?.remove();
    };
  }, []);

  return ReactDOM.createPortal(<ModalBody {...props} />, container);
}

function ModalBody(props: ModalProps) {
  const { visualHeight, small } = useMediaQueries();

  let height;
  let maxHeight = "calc(100vh - 40px)";

  if (small) {
    maxHeight = "calc(100vh - 100px - env(safe-area-inset-bottom))";
  }

  if (props.large) {
    height = `calc(${visualHeight}px - 2rem - env(safe-area-inset-bottom))`;
    maxHeight = "none";
  } else if (props.fixedHeight) {
    if (small) {
      if (visualHeight > 500) {
        height = `calc(${visualHeight}px - env(safe-area-inset-bottom))`;
      }
    } else {
      height = `calc(${visualHeight * 0.95}px - env(safe-area-inset-bottom))`;
      maxHeight = "1000px";
    }
  }

  return (
    <Transition
      show={props.visible}
      className={tw("fixed inset-0 z-40", props.className)}
      afterLeave={props.afterClose}
      as="div"
    >
      <div
        className={tw("min-h-screen", !props.large && "px-4", "text-center")}
      >
        <TransitionChild
          className="fixed inset-0 bg-dark bg-opacity-90 transition duration-200 data-[closed]:opacity-0"
          onClick={
            props.hideClose || props.disabled ? undefined : props.handleCancel
          }
          as="div"
        />

        <span
          className="inline-block h-screen align-middle sm:align-top"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          className={tw(
            props.large && "max-w-7xl align-top",
            props.medium && "max-w-4xl align-top",
            !props.large && !props.medium && "max-w-lg align-middle",
            !props.fixedHeight && "mt-4",
            "inline-block w-full transform text-left transition-all duration-200 data-[closed]:scale-95 data-[closed]:opacity-0"
          )}
          as="div"
        >
          <div
            className={tw(
              "bg-white",
              !props.large && "rounded-xl shadow-xl",
              "overflow-hidden",
              "flex",
              "flex-col",
              "xl:rounded-xl"
            )}
            css={{
              minHeight: 300,
              maxHeight,
              [`@media (${md})`]: {
                height,
                maxHeight,
              },
              [`@media (${sm})`]: {
                minHeight: 0,
                maxHeight,
                height,
              },
            }}
          >
            {!props.hideHeader && (
              <div className="flex items-center border-b border-borderGray bg-bgGray px-4 py-2">
                <div className="flex-1 text-lg font-bold">{props.title}</div>

                <div className="h-12">
                  {!props.hideClose && (
                    <BorderButton
                      round
                      bgColor={colors.white}
                      disabled={props.disabled}
                      onClick={props.handleCancel}
                    >
                      <i className="far fa-xmark text-organization" />
                    </BorderButton>
                  )}
                </div>
              </div>
            )}

            {props.hideHeader && (
              <>
                <div className="relative flex h-full flex-col overflow-hidden">
                  {props.children}
                </div>
              </>
            )}

            {!props.hideHeader && (
              <div className="relative flex-1 overflow-auto p-4">
                {props.children}
              </div>
            )}

            {props.footer && (
              <div
                className="relative flex items-center gap-4 px-4"
                css={{
                  minHeight: 70,
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                {props.footer}
              </div>
            )}
          </div>
        </TransitionChild>
      </div>
    </Transition>
  );
}
