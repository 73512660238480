import React from "react";
import { modals, useModal } from "src/utils/modals";
import { BorderButton } from "../buttons/BorderButton";
import Modal from "./Modal";

interface Props {
  user: {
    id: string;
    avatar: {
      url: string;
    };
    firstName: string;
    lastName: string;
  };
  handleCancel?: () => void;
  handleChange?: () => void;
}

const AvatarModal = modals.create((props: Props) => {
  const modal = useModal();

  function onCancel() {
    if (props.handleCancel) {
      props.handleCancel();
    }

    modal.hide();
  }

  function onChange() {
    props.handleChange();
  }

  return (
    <Modal
      handleCancel={onCancel}
      visible={modal.visible}
      afterClose={modal.remove}
      footer={
        <React.Fragment>
          <BorderButton onClick={onCancel}>Close</BorderButton>
          {props.handleChange && (
            <BorderButton onClick={onChange}>Change Photo</BorderButton>
          )}
        </React.Fragment>
      }
    >
      <div className="text-center">
        <img
          src={props.user?.avatar?.url}
          alt={`${props.user?.firstName} ${props.user?.lastName}`}
          css={{
            maxHeight: 500,
          }}
        />
      </div>
    </Modal>
  );
});

export default AvatarModal;
