import { useEffect, useRef, useState } from "react";
import tw from "src/utils/tw";

interface Props {
  delay?: number;
  className?: string;
  small?: boolean;
  noPadding?: boolean;
}

function Loading(props: Props) {
  const [delaying, setDelaying] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        if (isMounted.current) {
          setDelaying(false);
        }
      }, props.delay);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (props.delay && delaying) {
    return null;
  }

  let dot = "rounded-full absolute bg-organization";

  if (props.small) {
    dot += " w-[5px] h-[5px]";
  } else {
    dot += " w-[7px] h-[7px]";
  }

  return (
    <div
      className={tw(
        "flex",
        "items-center",
        "justify-center",
        !props.noPadding && "py-20",
        props.className
      )}
    >
      <div
        className={tw(
          "animate-spin",
          "relative",
          props.small ? "h-3.5 w-3.5" : "h-5 w-5"
        )}
      >
        <div className={tw(dot, "opacity-20", "left-0", "top-0")} />
        <div className={tw(dot, "opacity-50", "right-0", "top-0")} />
        <div className={tw(dot, "opacity-70", "left-0", "bottom-0")} />
        <div className={tw(dot, "right-0", "bottom-0")} />
      </div>
    </div>
  );
}

export default Loading;
