import moment from "moment-timezone";
import ClassModel from "src/models/ClassModel";
import { Frequency, RateType } from "types/code-generator";
import calculateNumberOfClassesFromDate from "./calculateNumberOfClassesFromDate";

interface CalculateProratedRegistrationPriceInput {
  classItem: {
    id: string;
    endDate: Date;
    excludedDates: Date[];
    paymentFrequency: Frequency;
    paymentRate: RateType;
    price: number;
    prorateExcludedDates: boolean;
    prorateInProgressClasses: boolean;
    prorateStartEnd: boolean;
    startDate: Date;
  };
  endDate: Date;
  startDate: Date;
  organization: {
    timezone: string;
  };
}

export default ({
  classItem: classItemData,
  endDate,
  startDate,
  organization,
}: CalculateProratedRegistrationPriceInput) => {
  const today = moment(startDate);
  const classItem = new ClassModel(classItemData);
  const offeringPrice = classItem.price;
  const offeringStartDate = moment(classItem.startDate).tz(
    organization.timezone
  );
  let offeringEndDate =
    classItem.endDate && moment(classItem.endDate).tz(organization.timezone);

  let userStartDate = moment(startDate).tz(organization.timezone);

  if (!moment(startDate).tz()) {
    userStartDate = moment.tz(
      moment(startDate).format("YYYY-MM-DD"),
      "YYYY-MM-DD",
      organization.timezone
    );
  }

  let userEndDate = moment(endDate).tz(organization.timezone);

  if (offeringStartDate.isAfter(userStartDate, "day")) {
    userStartDate = offeringStartDate.startOf("day");
    // userEndDate = offeringStartDate.clone().endOf("month");
  }

  if (offeringEndDate?.isBefore(userEndDate)) {
    userEndDate = offeringEndDate;
  }

  const monthlyStartDate = userStartDate.clone().startOf("month");
  let monthlyEndDate = userEndDate.clone().endOf("month");

  if (!classItem.prorateStartEnd) {
    monthlyEndDate = userEndDate.clone();
  }

  // if (offeringStartDate.isAfter(monthlyStartDate, "day")) {
  //   monthlyStartDate = offeringStartDate.startOf("day");
  // }

  // if (classItem.id === "cl5k6v733702784u7papcl5n4zu") {
  //   console.log("now", moment().toISOString());
  //   console.log("userStartDate", userStartDate.format());
  //   console.log("userEndDate", userEndDate.format());
  //   console.log("offeringStartDate", offeringStartDate.format());
  //   console.log("offeringEndDate", offeringEndDate.format());
  // }

  let totalNumberOfClasses = 0;
  let numberOfClassesRemaining = 0;

  if (classItem.paymentFrequency === Frequency.OneTime) {
    totalNumberOfClasses = calculateNumberOfClassesFromDate({
      classItem,
      endDate: offeringEndDate,
      getTotalNumber: true,
      startDate: offeringStartDate,
      organization,
    });

    numberOfClassesRemaining = calculateNumberOfClassesFromDate({
      classItem,
      endDate: offeringEndDate,
      startDate: userStartDate,
      organization,
    });
  } else {
    // console.log("monthlyStartDate", monthlyStartDate.format());
    // console.log("monthlyEndDate", monthlyEndDate.format());

    totalNumberOfClasses = calculateNumberOfClassesFromDate({
      classItem,
      endDate: monthlyEndDate,
      getTotalNumber: true,
      startDate: monthlyStartDate,
      organization,
    });

    numberOfClassesRemaining = calculateNumberOfClassesFromDate({
      classItem,
      endDate: userEndDate,
      startDate: userStartDate,
      organization,
    });
  }

  // if (classItem.id === "cl5k6sgjp678164u7papqogh663") {
  //   console.log("classItem", classItem);
  //   console.log("totalNumberOfClasses", totalNumberOfClasses);
  //   console.log("numberOfClassesRemaining", numberOfClassesRemaining);
  // }

  let pricePerClass = offeringPrice / totalNumberOfClasses;

  if (classItem.paymentRate === RateType.Variable) {
    pricePerClass = offeringPrice;
  }

  let due = offeringPrice;

  if (classItem.paymentRate === RateType.Variable) {
    due = pricePerClass * numberOfClassesRemaining;

    if (offeringStartDate.isAfter(today) && !classItem.prorateStartEnd) {
      due = pricePerClass * totalNumberOfClasses;
    }
  }

  const monthlyStandardProrate =
    classItem.paymentFrequency === Frequency.Monthly &&
    classItem.prorateInProgressClasses;

  const oneTimeStandardProrate =
    classItem.prorateInProgressClasses &&
    classItem.paymentFrequency === Frequency.OneTime &&
    offeringStartDate.isSameOrBefore(today);

  const monthlyStartEndProrate =
    classItem.paymentFrequency === Frequency.Monthly &&
    classItem.prorateStartEnd &&
    (offeringStartDate?.isAfter(today) ||
      offeringEndDate?.isBefore(monthlyEndDate));

  if (
    monthlyStandardProrate ||
    oneTimeStandardProrate ||
    monthlyStartEndProrate
  ) {
    due = Math.floor(pricePerClass * numberOfClassesRemaining);
  }

  if (
    classItem.excludedDates?.length > 0 &&
    classItem.prorateExcludedDates &&
    !classItem.prorateInProgressClasses &&
    !classItem.prorateStartEnd &&
    classItem.paymentFrequency === Frequency.Monthly
  ) {
    numberOfClassesRemaining = calculateNumberOfClassesFromDate({
      classItem,
      endDate: monthlyEndDate,
      startDate: monthlyStartDate,
      organization,
    });

    // console.log("numberOfClassesRemaining", numberOfClassesRemaining);

    due = pricePerClass * numberOfClassesRemaining;
  }

  if (
    offeringStartDate.isAfter(startDate, "month") &&
    classItem.paymentFrequency === Frequency.Monthly
  ) {
    due = 0;
  }

  if (!classItem.endDate && classItem.paymentFrequency === Frequency.OneTime) {
    // Incompatible with prorating
    due = classItem.price;
  }

  if (isNaN(due) || due === Infinity) {
    due = 0;
  }

  return Math.ceil(due);
};
