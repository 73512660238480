const normalizePhoneNumber = (phone) => {
  let newPhone = phone.replace("+1", "").replace(/[^\d]/g, "");

  if (newPhone[0] === "1") {
    newPhone = newPhone.replace("1", "");
  }

  return newPhone;
};

export const formatPhoneNumber = (phone) => {
  const normalized = normalizePhoneNumber(phone);
  return normalized.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};
