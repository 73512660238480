import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function useMergeState<T>(
  initial: T
): [T, Dispatch<SetStateAction<Partial<T>>>] {
  const [state, set] = useState<T>(initial);

  const setState = useCallback(
    (newState) => set((state) => ({ ...state, ...newState })),
    [set]
  );

  return [state, setState];
}
