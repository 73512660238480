import React, { ReactNode, Ref } from "react";
import tw from "src/utils/tw";

export interface InputTextProps {
  value?: string | number;
  defaultValue?: string | number;
  placeholder?: string;
  readOnly?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: (value) => void;
  className?: string;
  onChange?: (value) => void;
  type?: "tel" | "email" | "password" | "number" | "search";
  min?: number;
  max?: number;
  prefix?: ReactNode | any;
  suffix?: ReactNode | any;
  maxLength?: number;
  disabled?: boolean;
  name?: string;
  onSubmit?: (e) => void;
  selectTextOnFocus?: boolean;
  autoFocus?: boolean;
  autoComplete?:
    | "current-password"
    | "new-password"
    | "one-time-code"
    | "username"
    | "off"
    | "stop";
  allowClear?: boolean;
  allowClearEmpty?: boolean;
  onClear?: () => void;
  small?: boolean;
  onPressEnter?: () => void;
  id?: string;
  pattern?: string;
  required?: boolean;
}

export const InputText = React.forwardRef(
  (props: InputTextProps, ref: Ref<any>) => {
    function onChange(e) {
      let value = e.target.value;

      if (props.type === "number" && value) {
        value = parseInt(value);
      }

      if (props.onChange) {
        props.onChange(value);
      }
    }

    function onBlur(e) {
      const value = e.target?.value;

      if (props.onBlur) {
        props.onBlur(value);
      }
    }

    function onFocus(e) {
      if (props.selectTextOnFocus) {
        e.target.setSelectionRange(0, 9999);
      }

      if (props.onFocus) {
        props.onFocus();
      }
    }

    function clearValue() {
      if (props.onChange) {
        props.onChange("");
      }

      if (props.onClear) {
        props.onClear();
      }
    }

    function onKeyPress(e) {
      // console.log("e.keyCode", e.keyCode);
      if (e.keyCode === 13 && props.onPressEnter) {
        props.onPressEnter();
      }
    }

    const clearButton = (
      <button
        className="absolute bottom-0 right-4 top-0 z-20 m-auto cursor-pointer leading-none"
        onClick={clearValue}
        type="button"
      >
        <i aria-hidden className="far fa-circle-xmark text-empty" />
      </button>
    );

    return (
      <div className="group relative">
        {props.prefix && (
          <div className="absolute left-4 top-2.5 mt-0.5 w-5 text-center">
            {props.prefix}
          </div>
        )}
        <input
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          className={tw(
            "appearance-none",
            "border",
            "border-borderGray",
            "rounded",
            "transition",
            "px-4",
            "w-full",
            "placeholder-empty",
            props.small ? "h-9" : "h-12",
            props.prefix ? "pl-10" : "pl-4",
            props.suffix ? "pr-10" : "pr-4",
            props.disabled
              ? "cursor-not-allowed bg-disabledGray text-empty opacity-70"
              : "bg-bgGray group-hover:border-organization group-focus:border-organization",
            props.type === "search" && "rounded-full pr-[55px]",
            props.className
          )}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          id={props.id}
          max={props.max}
          maxLength={props.maxLength}
          min={props.min}
          name={props.name || undefined}
          onBlur={onBlur}
          onChange={onChange}
          onClick={props.onClick}
          onFocus={onFocus}
          onKeyDown={onKeyPress}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          ref={ref}
          type={props.type && props.type !== "search" ? props.type : "text"}
          value={props.value ?? ""}
          data-test={props["data-test"] || undefined}
          pattern={props.pattern}
          required={props.required ?? undefined}
        />
        {props.suffix && (
          <div className="absolute right-0 top-0 h-full">
            {typeof props.suffix === "string" && (
              <div className="flex h-full items-center justify-center pr-4">
                {props.suffix}
              </div>
            )}

            {typeof props.suffix !== "string" && props.suffix}
          </div>
        )}
        {props.allowClear &&
          (props.value || props.allowClearEmpty) &&
          clearButton}
        {props.type === "search" && (
          <div>
            {props.value && (
              <div className="absolute bottom-0 right-6 top-0 z-0 m-auto h-4 leading-none">
                {clearButton}
              </div>
            )}
            <div className="absolute bottom-0 right-4 top-0 m-auto h-4 leading-none">
              <i
                aria-hidden
                className="far fa-magnifying-glass text-organization"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);
