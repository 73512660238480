import { css } from "@emotion/react";
import moment from "moment-timezone";
import React from "react";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import InvoiceModel from "src/models/InvoiceModel";
import colors from "src/utils/colors";
import formatMoney from "src/utils/formatMoney";
import Container from "./Container";
import {
  LineItem,
  LineItemButton,
  LineItemLabel,
  LineItemTotal,
} from "./LineItems";

interface Props {
  invoice: InvoiceModel;
  removeInvoiceItem?: (invoiceItem: InvoiceItemModel) => void;
  fullWidth?: boolean;
  totalString?: string;
  totalType?: string;
  small?: boolean;
  showTotal?: boolean;
}

export default function PaymentTable(props: Props) {
  const today = moment();
  let totalString = "Total";

  // console.log("invoice", invoice);

  if (
    props.invoice.invoiceDate &&
    moment(props.invoice.invoiceDate).isSame(today, "day")
  ) {
    totalString = "Total Due Today";
  }

  if (props.totalString) {
    totalString = props.totalString;
  }

  const total = props.invoice.getTotals()[props.totalType || "totalOwed"];
  const totalStyle = css`
    color: ${total < 0 ? colors.success : colors.dark};
  `;
  const totalAmount = (
    <span css={totalStyle}>
      {total < 0 && "- "}${formatMoney(Math.abs(total))}
    </span>
  );

  const subtotalItem = props.invoice.invoiceItems.find(
    (ii) => ii.description === "Subtotal"
  );
  const fees = props.invoice.invoiceItems.filter(
    (ii) =>
      !ii.credit &&
      ii.description !== "Session Discount" &&
      ii.description !== "Subtotal"
  );
  const credits = props.invoice.invoiceItems
    .filter((ii) => ii.credit || ii.description === "Session Discount")
    .sort((a, b) => a.amount - b.amount);

  const invoiceItems = [subtotalItem, ...credits, ...fees].filter(Boolean);

  // const invoiceItems = [...props.invoice.invoiceItems].sort((a, b) => {
  //   const aIsCredit =
  //     a.credit ||
  //     a.recurringCredit ||
  //     a.discounts?.length > 0 ||
  //     (a.coupons?.length > 0 && !a.fee) ||
  //     a.amount < 0;
  //   const bIsCredit =
  //     b.credit ||
  //     b.recurringCredit ||
  //     b.discounts?.length > 0 ||
  //     (b.coupons?.length > 0 && !b.fee) ||
  //     b.amount < 0;

  //   if (aIsCredit && !bIsCredit) {
  //     return 1;
  //   } else if (bIsCredit && !aIsCredit) {
  //     return -1;
  //   } else if ((!aIsCredit && !bIsCredit) || (aIsCredit && bIsCredit)) {
  //     return b.amount - a.amount;
  //   }

  //   return 0;
  // });

  return (
    <Container className={props.fullWidth ? "-ml-4 -mr-4" : "mb-4 ml-6"}>
      {invoiceItems
        .filter((a) => !a.credit)
        .map((i) => {
          let description = i.description;
          let meta;
          const isRemoveable =
            (i.charge ||
              (i.credit && i.credit.billingCategory) ||
              i.addon ||
              i.description.includes("Adding")) &&
            !(i as any).notRemoveable;
          const isCredit =
            i.credit ||
            i.recurringCredit ||
            (i.discounts && i.discounts.length > 0) ||
            (i.coupons && i.coupons.length > 0 && !i.fee) ||
            i.amount < 0;
          const amountStyle = css`
            color: ${isCredit ? colors.success : colors.dark};
          `;

          if (i.charge || i.credit) {
            description = i.charge ? "One-time Charge" : "One-time Credit";

            if (i.charge) {
              meta = (
                <div className="text-sm">
                  {i.charge && i.charge.billingCategory && (
                    <div>{i.charge.billingCategory.title}</div>
                  )}
                  {i.charge.description}
                </div>
              );
            } else {
              meta = (
                <div className="text-sm">
                  {i.credit && i.credit.billingCategory && (
                    <div>{i.credit.billingCategory.title}</div>
                  )}
                  {i.credit.description}
                </div>
              );
            }
          }

          if (i.addon) {
            description = `Add-on: ${i.description} • ${i.quantity}`;

            if (i.description) {
              description += ` (${i.description})`;
            }
          }

          return (
            <React.Fragment key={i.id}>
              <LineItem>
                {isRemoveable && (
                  <LineItemButton onClick={() => props.removeInvoiceItem(i)}>
                    <i
                      aria-hidden
                      className="far fa-trash-can text-organization"
                    />
                  </LineItemButton>
                )}
                <LineItemLabel>
                  {description}
                  {meta}
                </LineItemLabel>
                <span css={amountStyle}>
                  {isCredit && "- "}${formatMoney(Math.abs(i.amount))}
                </span>
              </LineItem>
            </React.Fragment>
          );
        })}

      {(props.invoice.invoiceItems.length > 0 || props.showTotal) && (
        <>
          {(!props.showTotal || props.invoice.invoiceItems.length > 0) && (
            <hr />
          )}

          {invoiceItems
            .filter((a) => a.credit)
            .map((i) => {
              const isRemoveable = i.credit && i.credit.billingCategory;
              const description = "One-time Credit";

              const meta = (
                <div className="text-sm">
                  {i.credit && i.credit.billingCategory && (
                    <div>{i.credit.billingCategory.title}</div>
                  )}
                  {i.credit.description}
                </div>
              );

              return (
                <React.Fragment key={i.id}>
                  <LineItem>
                    {isRemoveable && (
                      <LineItemButton
                        onClick={() => props.removeInvoiceItem(i)}
                      >
                        <i
                          aria-hidden
                          className="far fa-trash-can text-organization"
                        />
                      </LineItemButton>
                    )}
                    <LineItemLabel>
                      {description}
                      {meta}
                    </LineItemLabel>
                    <span className="text-success">
                      - ${formatMoney(Math.abs(i.amount))}
                    </span>
                  </LineItem>
                </React.Fragment>
              );
            })}
          <LineItem>
            <LineItemLabel>{totalString}</LineItemLabel>
            {props.small && totalAmount}
            {!props.small && <LineItemTotal>{totalAmount}</LineItemTotal>}
          </LineItem>
        </>
      )}

      {props.invoice.invoiceItems.find(
        (ii) => !!ii.credit && !!ii.credit.billingCategory
      ) && (
        <p className="text-empty">
          Credits are not processed as refunds. The credit is applied to account
          balance.
        </p>
      )}
    </Container>
  );
}
