import styled from "@emotion/styled";
import colors from "src/utils/colors";

export const LineItem = styled("div")`
  display: flex;
  /* align-items: flex-start; */
  position: relative;
  padding: 0 0 0.25rem 0;
  color: ${colors.dark};
  // align-items: center;
`;

interface LineItemLabelProps {
  dark?: boolean;
}

export const LineItemLabel = styled("span")`
  flex: 1;
  /* color: ${colors.darkGray}; */
  /* color: ${(props: LineItemLabelProps) => props.dark && colors.dark}; */
`;

export const LineItemButton = styled("button")`
  color: #999;
  /* position: absolute; */
  /* left: 0; */
  cursor: pointer;
  line-height: 1;
  background: none;
  padding: 0;
  border: 0;
  display: inline-block;
  height: 1rem;
  margin: 0.25rem 1rem 0 0;
`;

export const LineItemTotal = styled("div")`
  font-weight: bold;
  font-size: 1.25rem;
  color: ${colors.dark};
`;
