import { ReactNode } from "react";
import tw from "src/utils/tw";

interface Props {
  onChange?: (value) => void;
  name?: string;
  checked?: boolean;
  value?: string | boolean | number;
  children?: ReactNode;
  label?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  small?: boolean;
}

export default function InputRadio(props: Props) {
  function onChange(e) {
    if (props.onChange) {
      props.onChange(props.value);
    }
  }

  const outerSize = props.small
    ? { width: 26, height: 26 }
    : { width: 41, height: 41 };
  const innerSize = props.small
    ? { width: 16, height: 16 }
    : { width: 25, height: 25 };

  return (
    <div>
      <label
        htmlFor={`${props.name}-${props.value?.toString()}`}
        className={tw(
          "flex",
          !props.className?.includes("items-") && "items-center",
          "cursor-pointer",
          props.className
        )}
      >
        <div className="mr-3">
          <div className="relative" css={outerSize}>
            <input
              type="radio"
              name={props.name}
              checked={props.checked || false}
              id={`${props.name}-${props.value?.toString() || ""}`}
              onChange={onChange}
              css={outerSize}
              className={tw(
                "appearance-none",
                "border",
                "rounded-full",
                "transition",
                "cursor-pointer",
                "outline-none",
                !props.disabled && "hover:border-dark",
                "p-0",
                props.checked ? "border-dark" : "border-borderGray",
                props.disabled && "bg-bgGray"
              )}
              disabled={props.disabled}
            />
            <div
              className={tw(
                "absolute",
                // "z-10",
                "left-0",
                "top-0",
                "right-0",
                "bottom-0",
                "m-auto",
                "rounded-full",
                "transition",
                "transform",
                "pointer-events-none",
                props.checked ? "scale-100" : "scale-50",
                props.checked ? "bg-organization" : "bg-transparent"
              )}
              css={{
                ...innerSize,
              }}
            />
          </div>
        </div>
        <div className={tw("flex-1", props.disabled && "text-darkGray")}>
          {props.label}
        </div>
      </label>
      {props.children && (
        <div
          css={{ marginLeft: "3.5rem" }}
          tabIndex={props.disabled ? -1 : undefined}
          className={tw(props.disabled && "pointer-events-none opacity-50")}
        >
          {props.children}
        </div>
      )}
    </div>
  );
}
