import gql from "graphql-tag";
import { groupBy, uniqBy } from "lodash-es";
import moment from "moment-timezone";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { GroupRegistrationsCountsQuery } from "src/api/GroupRegistration";
import { UpdateOrganizationMutation } from "src/api/Organization";
import { ReferralTemplatePayloadFragment } from "src/api/ReferralTemplate";
import { BorderButton } from "src/components/buttons/BorderButton";
import { BorderCard } from "src/components/items/BorderCard";
import ManageIcon from "src/components/items/ManageIcon";
import Container from "src/components/layout/Container";
import Loading from "src/components/layout/Loading";
import Section from "src/components/layout/Section";
import { OrganizationPaymentAlert } from "src/components/organizations/OrganizationPaymentAlert";
import { UserReferralTemplate } from "src/components/referrals/UserReferralTemplate";
import { SettingOnboardsProgress } from "src/components/settings/SettingOnboardProgress";
import { apiClient, useApi } from "src/stores/Api";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { sm } from "src/styles/utility";
import colors from "src/utils/colors";
import {
  ClassTypes,
  PrivateOfferingTypes,
  dateFormat,
} from "src/utils/constants";
import formatMoney from "src/utils/formatMoney";
import helmet from "src/utils/helmet";
import tw from "src/utils/tw";
import {
  BetaAccessType,
  CustomerServiceItemType,
  EventType,
  GroupRegistrationStatus,
  SortOrder,
  StripeType,
  manageAttendancesCounts,
  manageAttendancesCountsVariables,
  manageCustomerServiceItems,
  manageCustomerServiceItemsVariables,
  managePrivateSerieAttendancesCounts,
  nextAttendance,
  nextAttendanceVariables,
  nextPrivateSerieAttendance,
  nextPrivateSerieAttendanceVariables,
  updateOrganizationVariables,
  userReferralTemplate,
  userReferralTemplateVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

interface Props {}

export default function AdminLanding(props: Props) {
  const store = useStore();
  const session = useSession();
  const date = new Date(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    helmet.setPageTitle("Admin");
  }, []);

  const nextAttendanceVariables: nextAttendanceVariables = {
    where: {
      active: true,
      date: {
        gt: date,
      },
      organizationId: store.organization.id,
    },
    orderBy: {
      date: SortOrder.asc,
    },
    take: 1,
  };

  if (store.organization.hasDepartments) {
    nextAttendanceVariables.where.class = {
      departmentId: {
        in: session.departments.map((d) => d.id),
      },
    };
  }

  const queryNextAttendance = useApi(NextAttendanceQuery, {
    variables: nextAttendanceVariables,
  });

  const nextPrivateSerieAttendanceVariables: nextPrivateSerieAttendanceVariables =
    {
      where: {
        date: {
          gt: date,
        },
        organizationId: store.organization.id,
      },
      orderBy: {
        date: SortOrder.asc,
      },
      take: 1,
    };

  if (store.organization.hasDepartments) {
    nextPrivateSerieAttendanceVariables.where.privateSerie = {
      offering: {
        departmentId: {
          in: session.departments.map((d) => d.id),
        },
      },
    };
  }

  const queryNextPrivateSerieAttendance = useApi(
    NextPrivateSerieAttendanceQuery,
    {
      variables: nextPrivateSerieAttendanceVariables,
    }
  );

  const queryCustomerServiceItems = useApi(CustomerServiceItemsQuery, {
    variables: {
      where: {
        organizationId: store.organization.id,
        unread: true,
        type: {
          in: [
            CustomerServiceItemType.OutstandingBalance,
            CustomerServiceItemType.NoPaymentInformation,
            CustomerServiceItemType.ExpiredCard,
          ],
        },
      },
    },
  });

  const queryAttendancesCounts = useApi(AttendancesCountsQuery);

  const queryPrivateSerieAttendancesCounts = useApi(
    PrivateSerieAttendancesCountsQuery
  );

  const queryWaitlistRegistrationsCounts = useApi(
    GroupRegistrationsCountsQuery,
    {
      variables: {
        where: {
          organizationId: store.organization.id,
          status: {
            equals: GroupRegistrationStatus.Waitlist,
          },
        },
      },
    }
  );

  function renderProgramming() {
    let summary = `${store.unreadItems.inProgressClasses?.toLocaleString()} in-progress • ${store.unreadItems.upcomingClasses?.toLocaleString()} upcoming • ${store.unreadItems.enrolledParticipants.toLocaleString()} enrolled`;

    if (
      store.unreadItems.allClasses === 0 &&
      store.unreadItems.allOfferings === 0
    ) {
      summary = "Create your first offering";
    }

    return (
      <BorderCard to="/admin/group-offerings">
        <div className="flex items-center">
          <ManageIcon>
            <i aria-hidden className="far fa-sitemap" />
          </ManageIcon>
          <div className="flex-1 overflow-hidden pl-4">
            <p className="mb-0">
              <strong>Programming</strong>
            </p>
            <p className="mb-0 block truncate text-sm text-empty">{summary}</p>
          </div>
        </div>
      </BorderCard>
    );
  }

  function renderPrivateLessons() {
    const pending =
      store.unreadItems.pendingPrivateSeries +
      store.unreadItems.postedPrivateSeries;

    return (
      <BorderCard
        to={
          store.unreadItems.allOfferings > 0
            ? "/admin/custom-private-lessons"
            : "/admin/first-offering"
        }
      >
        <div className="flex items-center">
          <ManageIcon>
            <i aria-hidden className="far fa-clipboard-list-check" />
          </ManageIcon>
          <div className="flex-1 pl-4">
            <p className="mb-0">
              <strong>Custom {store.organization.privateTerminology}</strong>
            </p>
            <p className="mb-0 text-sm text-empty">
              {pending} pending • {store.unreadItems.inProgressPrivateSeries}{" "}
              in-progress
            </p>
          </div>
          {store.unreadItems.allOfferings === 0 && (
            <BorderButton type="submit">Get Started</BorderButton>
          )}
        </div>
      </BorderCard>
    );
  }

  function renderMemberships() {
    const membershipOfferings = store.unreadItems.membershipOfferings;

    return (
      <BorderCard
        to={
          membershipOfferings > 0
            ? "/admin/membership-offerings"
            : "/admin/first-offering"
        }
      >
        <div className="flex items-center">
          <ManageIcon>
            <i aria-hidden className="far fa-certificate" />
          </ManageIcon>
          <div className="flex-1 overflow-hidden pl-4">
            <div className="flex items-center gap-2 text-dark">
              <strong>Memberships</strong>
            </div>
            <p className="mb-0 block truncate text-sm text-empty">{`${membershipOfferings} active`}</p>
          </div>
          {membershipOfferings === 0 && (
            <BorderButton type="submit">Get Started</BorderButton>
          )}
        </div>
      </BorderCard>
    );
  }

  function renderBillingAlerts() {
    const customerServiceItems =
      queryCustomerServiceItems.customerServiceItems || [];

    const groupedCustomerServiceItems = groupBy(
      customerServiceItems,
      (i) => i?.user?.id
    );

    const unreadCustomerServiceItems = Object.keys(
      groupedCustomerServiceItems
    ).length;

    let outstandingBalanceItems = customerServiceItems.filter(
      (c) =>
        c.type === CustomerServiceItemType.OutstandingBalance ||
        c.type === CustomerServiceItemType.NoPaymentInformation
    );

    outstandingBalanceItems = uniqBy(outstandingBalanceItems, "user.id");

    const outstandingBalance = outstandingBalanceItems.reduce(
      (acc, c) => acc + Math.max(c.user?.balance, 0),
      0
    );

    return (
      <BorderCard className="overflow-hidden">
        <div>
          {!store.organization.stripeAccountId &&
            !store.organization.stripeExpressAccountId && (
              <Link
                to="/admin/bank"
                className="-mx-4 -mt-4 mb-2 flex items-center gap-2 bg-error px-4 pb-1.5 pt-2.5 text-sm text-white"
              >
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white">
                  <i
                    aria-hidden
                    className="far fa-triangle-exclamation text-error"
                  />
                </div>
                <div>
                  Stripe not connected. Tap here to start collecting payments.
                </div>
              </Link>
            )}
          <Link to="/admin/outstanding-balances">
            <div className="flex">
              <ManageIcon error={unreadCustomerServiceItems > 0}>
                <i
                  aria-hidden
                  className={tw(
                    "far",
                    unreadCustomerServiceItems > 0
                      ? "fa-circle-exclamation text-error"
                      : "fa-check"
                  )}
                />
              </ManageIcon>
              <div className="flex-1 pl-4">
                <p className="mb-0">
                  <strong>
                    {unreadCustomerServiceItems === 0
                      ? "No"
                      : unreadCustomerServiceItems.toLocaleString()}{" "}
                    Billing Alert{unreadCustomerServiceItems !== 1 && "s"}
                  </strong>
                </p>
                <p className="mb-0 text-sm text-empty">
                  {unreadCustomerServiceItems} account
                  {unreadCustomerServiceItems !== 1 && "s"} • $
                  {formatMoney(outstandingBalance)} due
                  {unreadCustomerServiceItems === 0 && " • Woohoo!"}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </BorderCard>
    );
  }

  function renderToday() {
    const orgHasOfferings =
      store.unreadItems.allClasses > 0 || store.unreadItems.allOfferings > 0;

    if (!orgHasOfferings) {
      return null;
    }

    const attendancesCounts =
      queryAttendancesCounts.attendancesCounts?.today || {};
    const privateSerieAttendancesCounts =
      queryPrivateSerieAttendancesCounts.privateSerieAttendancesCounts?.today ||
      {};
    const hasItemsToday =
      Object.keys(attendancesCounts).length > 0 ||
      Object.keys(privateSerieAttendancesCounts).length > 0;
    const today = moment();
    let nextActivity: Date;
    let countdown = 0;

    const nextAttendance = queryNextAttendance.attendances[0];
    const nextPrivateSerieAttendance =
      queryNextPrivateSerieAttendance.privateSerieAttendances[0];
    const hasVisitedToday = store.organization.events.find(
      (e) => e.type === EventType.Visited && e.value === "Today"
    );

    const counts = {};
    const allTypes = { ...ClassTypes, ...PrivateOfferingTypes };

    for (const t in allTypes) {
      counts[t] = attendancesCounts[t] || 0;
    }

    for (const t in PrivateOfferingTypes) {
      counts[t] = privateSerieAttendancesCounts[t] || 0;
    }

    const summaries = [];

    for (const k in counts) {
      if (counts[k] === 0) {
        continue;
      }

      if (counts[k] > 1 && k === ClassTypes.Class) {
        summaries.push(`${counts[k]} ${k}es`);
      } else if (counts[k] > 1) {
        summaries.push(`${counts[k]} ${k}s`);
      } else if (counts[k] === 1) {
        summaries.push(`${counts[k]} ${k}`);
      }
    }

    if (summaries.length === 0) {
      summaries.push("Nothing today");

      if (nextAttendance) {
        summaries.push(
          `Next activity ${moment.utc(nextAttendance.date).format(dateFormat)}`
        );
        nextActivity = nextAttendance.date;
      } else if (nextPrivateSerieAttendance) {
        summaries.push(
          `Next activity ${moment
            .utc(nextPrivateSerieAttendance.date)
            .format(dateFormat)}`
        );
        nextActivity = nextPrivateSerieAttendance.date;
      } else {
        summaries.push("Nothing upcoming");
      }
    }

    if (nextActivity) {
      const today = moment().utc().startOf("day");
      countdown = moment(nextActivity).diff(today, "days");
    }

    const highlightToday =
      hasItemsToday &&
      ((hasVisitedToday &&
        moment(hasVisitedToday.createdAt).isSame(today, "day")) ||
        !hasVisitedToday);

    return (
      <div>
        <BorderCard
          css={{
            background: highlightToday
              ? `linear-gradient(120deg, #fff 0%, #fff 90%, ${colors.main} 90%, ${colors.main} 100%);`
              : undefined,
            [`@media (${sm})`]: {
              background: highlightToday
                ? `linear-gradient(120deg, #fff 0%, #fff 80%, ${colors.main} 80%, ${colors.main} 100%);`
                : undefined,
            },
          }}
          to="/admin/schedule"
          onClick={async () => {
            if (hasItemsToday && !hasVisitedToday) {
              const variables: updateOrganizationVariables = {
                data: {
                  events: {
                    create: [
                      {
                        type: EventType.Visited,
                        value: "Today",
                      },
                    ],
                  },
                },
                where: {
                  id: store.organization.id,
                },
              };

              await apiClient(UpdateOrganizationMutation, { variables });
              await store.updateOrganization();
            }
          }}
        >
          <div className="flex items-center">
            <ManageIcon>
              <i aria-hidden className="far fa-calendar-check" />
            </ManageIcon>
            <div className="flex-1 pl-4">
              <p className="mb-0">
                <strong>Today</strong>
              </p>
              <p className="mb-0 text-sm text-empty">{summaries.join(" • ")}</p>
            </div>

            {highlightToday && (
              <>
                <div className="flex items-center gap-2 rounded-full bg-organization px-4 py-2 text-sm text-white">
                  <i className="far fa-party-horn text-white" />{" "}
                  <strong>It’s go time!</strong>
                </div>
              </>
            )}

            {countdown > 0 && !highlightToday && (
              <>
                <div className="flex items-center gap-2 py-2 text-sm">
                  <i className="far fa-alarm-clock text-organization" />{" "}
                  <strong>
                    {countdown.toLocaleString()} day{countdown !== 1 && "s"}{" "}
                    away
                  </strong>
                </div>
              </>
            )}
          </div>
        </BorderCard>
      </div>
    );
  }

  function renderAlerts() {
    const stripeAlert =
      store.organization.stripeAlert &&
      (store.organization.stripeAccountId ||
        store.organization.stripeExpressAccountId);
    const pending = store.unread;
    const waitlist =
      queryWaitlistRegistrationsCounts?.groupRegistrationsCounts?.count || 0;

    let content = [];

    if (stripeAlert) {
      let message = "Stripe requires urgent attention";

      if (store.organization.stripe === StripeType.Express) {
        message = "A bank connection requires urgent attention";
      }

      content.push(
        <Link
          to="/admin/bank"
          key="stripe"
          className="flex flex-1 items-center gap-2 px-4 py-4 text-dark transition hover:bg-white"
        >
          <div className="flex h-11 w-11 items-center justify-center rounded-full bg-error text-xl">
            <i className="far fa-triangle-exclamation text-white" />
          </div>
          <strong className="flex-1">{message}</strong>
        </Link>
      );
    }

    if (pending > 0) {
      content.push(
        <Link
          to="/admin/pending"
          key="pending"
          className="flex flex-1 items-center gap-2 px-4 py-4 text-dark transition hover:bg-white"
        >
          <div className="flex h-11 w-11 items-center justify-center rounded-full bg-organization text-lg font-bold text-white">
            {pending.toLocaleString()}
          </div>
          <strong className="flex-1">Pending registrations & requests</strong>
        </Link>
      );
    }

    if (waitlist > 0) {
      content.push(
        <Link
          to="/admin/pending/waitlist"
          key="waitlist"
          className="flex flex-1 items-center gap-2 px-4 py-4 text-dark transition hover:bg-white"
        >
          <div className="flex h-11 w-11 items-center justify-center rounded-full border border-borderGray bg-white text-lg font-bold text-dark">
            {waitlist.toLocaleString()}
          </div>
          <strong className="flex-1">Waitlisted registrations</strong>
        </Link>
      );
    }

    if (content.length === 0) {
      return null;
    }

    return (
      <div className="mb-8 flex divide-x divide-borderGray border-y border-borderGray sm:mb-4 sm:flex-col sm:divide-x-0 sm:divide-y">
        {content}
      </div>
    );
  }

  const loading =
    !store.organization ||
    !queryWaitlistRegistrationsCounts.groupRegistrationsCounts ||
    !queryNextAttendance.attendances ||
    !queryNextPrivateSerieAttendance.privateSerieAttendances ||
    !queryCustomerServiceItems.customerServiceItems;

  if (loading) {
    return (
      <Section>
        <Container>
          <Loading />
        </Container>
      </Section>
    );
  }

  const orgHasOfferings =
    store.unreadItems.allClasses > 0 ||
    store.unreadItems.allOfferings > 0 ||
    store.unreadItems.membershipOfferings > 0;
  const offerings = renderProgramming();
  const today = renderToday();
  const billingAlerts = renderBillingAlerts();
  const alerts = renderAlerts();
  let privateLessons = null;
  let memberships = null;
  const setup = store.organization.departments.every(
    (d) => d.settingOnboards.length >= 9
  );

  if (store.organization.betaAccess.includes(BetaAccessType.PrivateLessons)) {
    privateLessons = renderPrivateLessons();
  }

  if (store.organization.betaAccess.includes(BetaAccessType.Memberships)) {
    memberships = renderMemberships();
  }

  const registrationButtons = (
    <>
      <BorderButton
        bgColor="#fff"
        className="flex-1 text-organization hover:border-[#ccc]"
        to="/admin/users/add?returnPathname=/admin"
      >
        Add New Account
      </BorderButton>
      <BorderButton
        bgColor="#fff"
        disabled={!orgHasOfferings}
        className="flex-1 text-organization hover:border-[#ccc]"
        to="/admin/find"
      >
        Start Registration
      </BorderButton>
    </>
  );

  return (
    <>
      <div
        className="absolute bottom-0 left-0 right-0 top-0"
        css={{
          background: `linear-gradient(to bottom, ${colors.paleAction}, #fff 400px)`,
        }}
      />
      <div>
        <Container large className="pb-12 pt-8">
          <div className="mb-4 flex items-center sm:mb-2">
            <h1 className="mb-0 flex-1 sm:mb-6 sm:text-xl">
              <strong>Hey {store.user.firstName}! Here’s the latest.</strong>
            </h1>

            <div className="flex gap-4 sm:hidden">{registrationButtons}</div>
          </div>

          <OrganizationPaymentAlert />

          <SettingOnboardsProgress />

          {!orgHasOfferings && (
            <div className="flex items-center justify-center rounded-md border border-b-3 border-borderGray bg-white px-4 py-20">
              <BorderButton type="submit" to="/admin/first-offering">
                Create your first registration offering
              </BorderButton>
            </div>
          )}

          {orgHasOfferings && (
            <div className="mb-4 space-y-2">
              {alerts}

              <div className="hidden gap-4 sm:flex sm:pb-3">
                {registrationButtons}
              </div>

              {today}
              {offerings}
              {privateLessons}
              {memberships}
              {billingAlerts}
            </div>
          )}

          <UserReferralTemplate />

          {!setup && (
            <div className="py-8 text-center text-sm">
              <i className="far fa-circle-question text-organization" />{" "}
              <strong>Need help?</strong> Click the blue chat bubble in the
              corner.
            </div>
          )}
        </Container>
      </div>
    </>
  );
}

export const CustomerServiceItemsQuery = gql`
  query manageCustomerServiceItems($where: CustomerServiceItemWhereInput!) {
    customerServiceItems(where: $where) {
      id
      type
      user {
        id
        balance
      }
    }
  }
` as TypedDocumentNode<
  manageCustomerServiceItems,
  manageCustomerServiceItemsVariables
>;

const AttendancesCountsQuery = gql`
  query manageAttendancesCounts {
    attendancesCounts {
      today
    }
  }
` as TypedDocumentNode<
  manageAttendancesCounts,
  manageAttendancesCountsVariables
>;

const PrivateSerieAttendancesCountsQuery = gql`
  query managePrivateSerieAttendancesCounts {
    privateSerieAttendancesCounts {
      today
    }
  }
` as TypedDocumentNode<
  managePrivateSerieAttendancesCounts,
  manageAttendancesCountsVariables
>;

const NextAttendanceQuery = gql`
  query nextAttendance(
    $where: AttendanceWhereInput!
    $take: Int
    $orderBy: AttendanceOrderByInput
  ) {
    attendances(where: $where, take: $take, orderBy: $orderBy) {
      id
      date
      class {
        id
        type
      }
    }
  }
` as TypedDocumentNode<nextAttendance, nextAttendanceVariables>;

const NextPrivateSerieAttendanceQuery = gql`
  query nextPrivateSerieAttendance(
    $where: PrivateSerieAttendanceWhereInput!
    $take: Int
    $orderBy: PrivateSerieAttendanceOrderByInput
  ) {
    privateSerieAttendances(where: $where, take: $take, orderBy: $orderBy) {
      id
      date
      privateSerie {
        id
        offering {
          id
          type
        }
      }
    }
  }
` as TypedDocumentNode<
  nextPrivateSerieAttendance,
  nextPrivateSerieAttendanceVariables
>;

export const UserReferralTemplateQuery = gql`
  query userReferralTemplate($where: UserReferralTemplateWhereInput!) {
    userReferralTemplate(where: $where) {
      status
      referralTemplate {
        ...ReferralTemplatePayload
      }
    }
  }
  ${ReferralTemplatePayloadFragment}
` as TypedDocumentNode<userReferralTemplate, userReferralTemplateVariables>;
