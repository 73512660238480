import { gql } from "graphql-tag";
import {
  pendingInvoiceItems,
  pendingInvoiceItemsVariables,
  updateInvoiceItem,
  updateInvoiceItemVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const InvoiceItemPayloadFragment = gql`
  fragment InvoiceItemPayload on InvoiceItem {
    id
    addon {
      id
      title
      price
    }
    amount
    billingCategory {
      id
      title
    }
    charge {
      id
      amount
      billingCategory {
        id
        title
      }
      createdBy {
        id
        firstName
        lastName
      }
      description
    }
    chargeAmount {
      id
    }
    coupons {
      id
      amount
      coupon {
        id
        code
        title
        department {
          id
        }
      }
    }
    createdAt
    credits {
      id
      amount
      credit {
        id
        description
        billingCategory {
          id
          title
        }
      }
    }
    description
    discounts {
      id
      amount
      description
      discount {
        id
        discountType
        department {
          id
        }
      }
    }
    events {
      id
      createdAt
      type
      by {
        id
        firstName
        lastName
      }
    }
    fee {
      id
      title
    }
    groupRegistration {
      id
      accountOwner {
        id
        firstName
        lastName
      }
      class {
        id
        additionalFeeAmount
        additionalFeeTitle
        chargeAmounts(where: { status: { not: Processed } }) {
          id
          atRegistration
          date
          membershipOffering {
            id
            title
          }
          price
        }
        classTimes {
          id
          endTime
          day
          location {
            id
            title
          }
          startTime
        }
        collectTaxes
        department {
          id
          title
        }
        endDate
        fees {
          id
        }
        location {
          id
          title
        }
        price
        minAge
        maxAge
        participantType
        paymentFrequency
        paymentRate
        program {
          id
          title
          department {
            id
          }
          billingCategory {
            id
            title
          }
        }
        prorateInProgressClasses
        prorateStartEnd
        startDate
        status
        tags {
          id
          title
        }
        title
        type
      }
      events {
        id
        type
        by {
          id
          firstName
          lastName
          level
        }
      }
      roster {
        id
        firstName
        lastName
      }
      status
      student {
        id
        firstName
        lastName
      }
    }
    invoice {
      id
      invoiceDate
      status
    }
    meet {
      id
      city
      description
      endDate
      startDate
      state
      status
      title
    }
    membership {
      id
      events {
        id
        type
        value
      }
      offering {
        id
        paymentFrequency
        price
        priceLevels {
          id
          price
        }
        program {
          id
          title
        }
        title
        type
      }
      roster {
        id
        firstName
        lastName
      }
      status
    }
    note
    payments {
      createdAt
      id
      amount
      payment {
        id
        amount
        checkNumber
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        description
        stripeChargeId
        stripeToken
        type
      }
    }
    privateSerie {
      id
      accountOwner {
        id
        firstName
        lastName
      }
      attendances {
        id
        status
      }
      events {
        id
        type
        value
      }
      instructor {
        id
        firstName
        lastName
      }
      numberOfLessons
      offering {
        id
        billingCategory {
          id
          title
        }
        collectTaxes
        department {
          id
          title
        }
        fees {
          id
        }
        price
        title
        type
      }
      roster {
        id
        firstName
        lastName
      }
    }
    quantity
    recurringCharge {
      id
      amount
      billingCategory {
        id
        title
      }
    }
    refunds {
      id
      amount
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      description
      stripeRefundId
      type
    }
    status
  }
`;

const PendingInvoiceItemPayloadFragment = gql`
  fragment PendingInvoiceItemPayload on InvoiceItem {
    amount
    addon {
      id
      title
      price
      department {
        id
        title
      }
    }
    createdAt
    description
    events {
      id
      type
      value
      by {
        id
        firstName
        lastName
      }
    }
    id
    invoice {
      id
      status
      user {
        id
        firstName
        lastName
      }
    }
    payments {
      id
      amount
      payment {
        id
        type
      }
    }
    quantity
    note
    status
  }
`;

export const UpdateInvoiceItemMutation = gql`
  mutation updateInvoiceItem(
    $data: InvoiceItemCreateInput!
    $where: UniqueInput!
  ) {
    updateInvoiceItem(data: $data, where: $where) {
      ...InvoiceItemPayload
    }
  }
  ${InvoiceItemPayloadFragment}
` as TypedDocumentNode<updateInvoiceItem, updateInvoiceItemVariables>;

export const PendingInvoiceItemQuery = gql`
  query pendingInvoiceItems($organizationId: String!) {
    invoiceItems(
      where: {
        addonId: { not: null }
        status: { equals: Pending }
        invoice: {
          organizationId: $organizationId
          status: { notIn: [Preview] }
        }
      }
    ) {
      ...PendingInvoiceItemPayload
    }
  }
  ${PendingInvoiceItemPayloadFragment}
` as TypedDocumentNode<pendingInvoiceItems, pendingInvoiceItemsVariables>;
