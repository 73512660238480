import OrganizationModel from "src/models/OrganizationModel";

class Helmet {
  organization?: OrganizationModel;

  setOrganization(organization) {
    this.organization = organization;
  }

  setPageTitle(title: string) {
    if (
      !this.organization ||
      this.organization.id === "app" ||
      !this.organization.title
    ) {
      document.title = `${title} | Captyn`;
      return;
    }

    document.title = `${title} | ${this.organization.title}`;
  }
}

const helmet = new Helmet();

export default helmet;
