import DepartmentModel from "./DepartmentModel";

export default class DiscountModel {
  amount: number = null;
  amountType: "Dollar" | "Percent" = "Dollar";
  availableForAllOfferings: boolean;
  department: DepartmentModel;
  discountType: "MultiStudent" | "MultiClass" = "MultiStudent";
  id: string;
  max: number = null;
  min: number = null;
  organizationId: string;

  constructor(data: DiscountModel | {}) {
    Object.assign(this, data);
  }
}
