import styled from "@emotion/styled";
import { Link } from "react-router-dom";
// import Link from "src/components/buttons/LinkButton";
import colors from "src/utils/colors";

export default styled(Link)`
  height: 50px;
  line-height: 50px;
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none !important;
  color: ${colors.baseGray};
  &:hover {
    color: ${colors.baseGray};
  }
  & img {
    display: inline;
    cursor: pointer;
  }
`;
