import { transparentize } from "polished";
import { Link, useLocation, useParams } from "react-router-dom";
import { UpdateDepartmentMutation } from "src/api/Department";
import { BorderButton } from "src/components/buttons/BorderButton";
import { ConfirmModal } from "src/components/modals/ConfirmModal";
import { apiClient } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import { modals } from "src/utils/modals";
import { updateDepartmentVariables } from "types/code-generator";

export function SettingOnboardsProgress() {
  const store = useStore();
  const match = useParams<{ departmentId: string }>();
  const location = useLocation();

  const department = store.organization.departments.find((d) => {
    if (match.departmentId) {
      return d.id === match.departmentId;
    } else {
      return d.default;
    }
  });

  async function onNext() {
    if (process.env.NODE_ENV !== "development" && store.user.isSalesOrAbove) {
      return;
    }

    const variables: updateDepartmentVariables = {
      data: {
        settingOnboards: {
          set: Array.from(new Set([...department.settingOnboards, nextStep])),
        },
      },
      where: {
        id: department.id,
      },
    };

    await apiClient(UpdateDepartmentMutation, {
      variables,
    });

    await store.updateOrganization();
  }

  async function dismiss() {
    modals.show(ConfirmModal, {
      title: "Dismiss Checklist?",
      body: (
        <>
          <p>
            This will remove all the stars and the progress bar. We still
            encourage you to review all settings options.
          </p>
        </>
      ),
      handleOk: async () => {
        const variables: updateDepartmentVariables = {
          data: {
            settingOnboards: {
              set: Object.keys(settingOnboardings),
            },
          },
          where: {
            id: department.id,
          },
        };

        await apiClient(UpdateDepartmentMutation, {
          variables,
        });

        await store.updateOrganization();
      },
      buttonText: "Yes, Dismiss",
    });
  }

  let settingOnboardings: any = {
    General: "/admin/account",
    Logo: "/admin/logo",
    "Brand Color": "/admin/brand",
    Terminology: "/admin/terminology",
    "Required Fields": "/admin/required-fields",
    "Health Concerns": "/admin/health-questions",
    Agreements: "/admin/agreements",
    "Info Requests": `/admin/settings/${store.organization.defaultDepartment.id}/student-info-requests`,
    Locations: "/admin/locations",
    Programs: `/admin/settings/${store.organization.defaultDepartment.id}/programs`,
    Tags: `/admin/settings/${store.organization.defaultDepartment.id}/tags`,
    "Stripe Connection": "/admin/bank",
    Communications: `/admin/communications`,
    "Public Filtering": `/admin/settings/${store.organization.defaultDepartment.id}/filters`,
    "Billing Categories": `/admin/settings/${store.organization.defaultDepartment.id}/billing-categories`,
    Discounts: `/admin/settings/${store.organization.defaultDepartment.id}/discounts`,
    Coupons: `/admin/settings/${store.organization.defaultDepartment.id}/coupons`,
    "Add-ons": `/admin/settings/${store.organization.defaultDepartment.id}/addons`,
    "Line Item Fees": "/admin/fees",
  };

  if (store.organization.hasDepartments) {
    if (match.departmentId) {
      settingOnboardings = {
        General: `/admin/settings/${match.departmentId}/settings`,
        "Info Requests": `/admin/settings/${match.departmentId}/student-info-requests`,
        Programs: `/admin/settings/${match.departmentId}/programs`,
        Tags: `/admin/settings/${match.departmentId}/tags`,
        Communications: `/admin/communications`,
        "Billing Categories": `/admin/settings/${match.departmentId}/billing-categories`,
        Discounts: `/admin/settings/${match.departmentId}/discounts`,
        Coupons: `/admin/settings/${match.departmentId}/coupons`,
        "Add-ons": `/admin/settings/${match.departmentId}/addons`,
      };
    } else {
      settingOnboardings = {
        General: "/admin/account",
        Logo: "/admin/logo",
        "Brand Color": "/admin/brand",
        Terminology: "/admin/terminology",
        "Required Fields": "/admin/required-fields",
        "Health Concerns": "/admin/health-questions",
        Agreements: "/admin/agreements",
        Locations: "/admin/locations",
        "Stripe Connection": "/admin/bank",
        "Line Item Fees": "/admin/fees",
        "Dept. Setup": "/admin/departments",
      };
    }
  }

  const viewed = department?.settingOnboards?.length || 0;
  const available = Object.keys(settingOnboardings).length;
  const progress = Math.ceil((viewed / available) * 100);
  const remainingSteps = Object.keys(settingOnboardings).filter(
    (s) => !department?.settingOnboards.includes(s)
  );
  const nextStep = remainingSteps[0];

  // console.log("nextStep", nextStep);

  let suggestion = nextStep;
  let progressMessage = "Explore any of the settings to make progress.";
  let title = <>Settings Checklist</>;

  if (progress > 0) {
    if (progress <= 50) {
      progressMessage = "You’re making great progress.";
    } else if (progress > 50) {
      progressMessage = "Almost done!";
    }
  }

  if (suggestion === "General") {
    suggestion = "General Settings";
  }

  if (location.pathname === "/admin") {
    title = (
      <>
        <Link to="/admin/settings">Settings Checklist</Link>
      </>
    );
  }

  if (progress >= 100) {
    return null;
  }

  return (
    <div
      className="relative mb-8 rounded-md border border-b-3 border-borderGray bg-white px-4 pb-4 pt-3"
      css={{
        background: `linear-gradient(-60deg, ${transparentize(
          0.9,
          colors.action
        )} 0%, ${transparentize(
          0.9,
          colors.action
        )} 40%, #fff 40%, #fff 100%);`,
      }}
    >
      <h2 className="mb-0">{title}</h2>

      {store.unreadItems.allOfferings > 0 && (
        <p>
          ⭐️ Review settings to customize Captyn for {store.organization.title}
          .
        </p>
      )}

      {store.unreadItems.allOfferings === 0 && (
        <p>
          ⭐️ Review settings to customize Captyn for {store.organization.title}
          . You can also{" "}
          <Link to="/admin/first-offering">create an offering</Link> anytime.
        </p>
      )}

      <div className="mb-2 h-2.5 rounded-full bg-borderGray shadow-inner">
        <div
          className="h-2.5 rounded-full bg-success px-1.5"
          css={{ width: `${progress}%` }}
        />
      </div>

      <div className="mb-4 flex text-sm">
        <div className="flex-1">
          <strong>
            {progress}% {progressMessage}
          </strong>
        </div>
        <div>
          <strong>100%</strong>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <BorderButton
          type="submit"
          to={settingOnboardings[nextStep]}
          onClick={onNext}
        >
          Next
        </BorderButton>
        <div className="flex-1">Suggestion: Review your {suggestion}</div>
      </div>

      <button
        type="button"
        className="absolute right-4 top-2"
        onClick={dismiss}
      >
        <i className="far fa-xmark text-medGray opacity-50" />
      </button>
    </div>
  );
}
