import { useLocation } from "react-router-dom";
import { isServer } from "src/utils/isServer";

export function useSearchParams(keysToFilter = []) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  keysToFilter.forEach((key) => params.delete(key));

  return params;
}

export function getSearchParams(keysToFilter = []) {
  const location = isServer ? { search: "" } : window.location;
  const params = new URLSearchParams(location.search);

  keysToFilter.forEach((key) => params.delete(key));

  return params;
}

export function filterParams({
  key,
  value,
}: {
  key: string;
  value: string | string[];
}) {
  const location = isServer ? { search: "" } : window.location;
  const params = new URLSearchParams(location.search);
  let values = params.getAll(key);

  if (Array.isArray(value)) {
    values = value;
  } else {
    if (values.includes(value)) {
      values = values.filter((v) => v !== value && v !== "all");
    } else {
      values.push(value);
    }
  }

  params.delete(key);

  if (value !== "all") {
    for (const v of values) {
      if (v === "all") {
        continue;
      }

      params.append(key, v);
    }
  }

  return params;
}
