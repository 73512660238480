import { InvoiceItemCouponModel } from "src/models/InvoiceItemCouponModel";
import { InvoiceItemDiscountModel } from "src/models/InvoiceItemDiscountModel";
import { InvoiceItemPaymentModel } from "src/models/InvoiceItemPaymentModel";
import PrivateSerieAttendanceModel from "src/models/PrivateSerieAttendanceModel";
import { RecurringCreditModel } from "src/models/RecurringCreditModel";
import UserModel from "src/models/UserModel";
import { ChargeAmount, Fee, Frequency, invoice } from "types/code-generator";
import AddonModel from "./AddonModel";
import BillingCategoryModel from "./BillingCategoryModel";
import { ChargeModel } from "./ChargeModel";
import { CreditModel } from "./CreditModel";
import { EventModel } from "./EventModel";
import GroupRegistrationModel from "./GroupRegistrationModel";
import { InvoiceItemCreditModel } from "./InvoiceItemCredit";
import InvoiceModel from "./InvoiceModel";
import MembershipModel from "./MembershipModel";
import PrivateSerieModel from "./PrivateSerieModel";
import { RecurringChargeModel } from "./RecurringChargeModel";
import { RefundModel } from "./RefundModel";

export const isInvoiceItem = (invoiceItem) =>
  invoiceItem instanceof InvoiceItemModel;

export class InvoiceItemModel {
  addon?: AddonModel;
  amount: number;
  billingCategory?: BillingCategoryModel;
  captyn?: Frequency;
  charge?: ChargeModel;
  chargeAmount?: Partial<ChargeAmount>;
  coupons?: InvoiceItemCouponModel[];
  createdAt?: Date;
  credit?: CreditModel;
  credits?: InvoiceItemCreditModel[];
  description: string;
  discounts?: InvoiceItemDiscountModel[];
  events?: EventModel[];
  fee?: Fee;
  groupRegistration?: GroupRegistrationModel;
  id?: string;
  invoice?: InvoiceModel;
  meet?: invoice["invoice"]["invoiceItems"][0]["meet"];
  membership?: MembershipModel;
  note?: string;
  omit?: boolean;
  payments?: InvoiceItemPaymentModel[];
  privateSerie?: PrivateSerieModel;
  privateSerieAttendance?: PrivateSerieAttendanceModel;
  quantity?: number;
  recurringCharge?: RecurringChargeModel;
  recurringCredit?: RecurringCreditModel;
  refunds?: RefundModel[];
  status?: string;
  user?: UserModel;

  constructor(data: Partial<InvoiceItemModel>) {
    Object.assign(this, data);
  }
}
