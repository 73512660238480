import moment from "moment-timezone";
import OrganizationModel from "src/models/OrganizationModel";
import PrivateSerieModel from "src/models/PrivateSerieModel";
import { discounts, Frequency, PaymentFrequency } from "types/code-generator";
import { v4 as uuid } from "uuid";
import calculateNumberOfClassesFromDate from "./calculateNumberOfClassesFromDate";

interface CalculatePricingForPrivateSerie {
  privateSerie: PrivateSerieModel;
  organization: OrganizationModel;
}

export function calculatePricingForPrivateSerie({
  privateSerie,
  organization,
}: CalculatePricingForPrivateSerie) {
  let price = 0;
  let pricePerSession = 0;
  let discount;
  const discountsToAdd = [];

  let priceAdjustments = [...privateSerie.offering.priceAdjustments].sort(
    (a, b) => a.min - b.min
  );
  let discounts: discounts["discounts"] = [
    ...(privateSerie.offering.discounts || []),
  ].sort((a, b) => a.min - b.min);

  if (privateSerie.isExtending) {
    priceAdjustments = [
      ...privateSerie.offering.extensionPriceAdjustments,
    ].sort((a, b) => a.min - b.min);

    discounts = [...privateSerie.offering.extensionDiscounts].sort(
      (a, b) => a.min - b.min
    );
  }

  // console.log("discounts", discounts);
  // console.log("privateSerie", privateSerie);

  if (privateSerie.offering.frequency === Frequency.Monthly) {
    const today = moment();
    let startDate = moment();
    const offeringStartDate = moment(privateSerie.offering.startDate);

    if (offeringStartDate.isAfter(startDate)) {
      startDate = offeringStartDate;
    }

    const numberRemaining = calculateNumberOfClassesFromDate({
      classItem: privateSerie.offering,
      endDate: today.clone().endOf("month"),
      startDate,
      organization,
    });

    price = numberRemaining * privateSerie.offering.price;
  } else if (
    privateSerie.offering.paymentFrequency === PaymentFrequency.Manual
  ) {
    price = 0;
  } else {
    pricePerSession = privateSerie.offering.price;

    if (privateSerie.isExtending && privateSerie.offering.extensionPrice) {
      pricePerSession = privateSerie.offering.extensionPrice;
    }

    if (priceAdjustments.length > 0) {
      for (const pa of priceAdjustments) {
        if (privateSerie.roster.length >= (pa.min || 0)) {
          pricePerSession = pa.price;
        }
      }
    }

    price = pricePerSession * privateSerie.numberOfLessons;

    if (discounts.length > 0) {
      for (const d of discounts) {
        if (
          privateSerie.numberOfLessons >= d.min &&
          (!d.max || privateSerie.numberOfLessons <= d.max)
        ) {
          discount = d;
        }
      }

      if (discount) {
        const discountPercentage = discount.amount / 100;
        const discountAmount =
          pricePerSession - pricePerSession * discountPercentage;
        const discountPrice = discountAmount * privateSerie.numberOfLessons;

        discountsToAdd.push({
          id: uuid(),
          amount: price - discountPrice,
        });

        // pricePerSession -= discountAmount;
      }
    }

    // if (privateSerie.roster.length === 1) {
    //   price += privateSerie.offering.price * privateSerie.numberOfLessons;
    // } else if (privateSerie.offering.priceForAdditionalStudents) {
    //   pricePerStudent =
    //     privateSerie.offering.priceForAdditionalStudents *
    //     privateSerie.roster.length;
    //   price += pricePerStudent * privateSerie.numberOfLessons;
    // } else {
    //   pricePerStudent =
    //     privateSerie.offering.price * privateSerie.roster.length;
    //   price += pricePerStudent * privateSerie.numberOfLessons;
    // }
  }

  return {
    price,
    pricePerSession,
    discounts: discountsToAdd,
  };
}
