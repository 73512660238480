import React, { ReactElement, ReactNode } from "react";
import tw from "src/utils/tw";
import { Validator } from "src/utils/useRCForm";
import { UseFormHook } from "../forms/Form";
import InputError from "./InputError";

interface Props<T> {
  rules?: Validator<any>[];
  label?: string | ReactNode;
  initialValue?: any;
  children?: ReactNode;
  name?: string;
  form?: UseFormHook<T>;
  className?: string;
  border?: boolean;
  type?: "buttons" | "dynamic";
  required?: boolean;
  useChecked?: boolean;
  omitRequired?: boolean;
  reverse?: boolean;
  hideError?: boolean;
  column?: boolean;
}

export function InputItem<T>(props: Props<T>) {
  function getControls(children: React.ReactNode, recursively: boolean) {
    let controls: React.ReactElement<any>[] = [];
    const childrenArray = React.Children.toArray(children);

    for (let i = 0; i < childrenArray.length; i++) {
      if (!recursively && controls.length > 0) {
        break;
      }

      const child = childrenArray[i] as React.ReactElement<any>;

      if (!child.props) {
        continue;
      }

      if ("data-__meta" in child.props) {
        controls.push(child);
      } else if (child.props.children) {
        controls = controls.concat(
          getControls(child.props.children, recursively)
        );
      }
    }
    return controls;
  }

  function getOnlyControl() {
    const child = getControls(props.children, false)[0];
    return child !== undefined ? child : null;
  }

  function getChildProp(prop: string) {
    const child = getOnlyControl() as React.ReactElement<any>;
    return child && child.props && child.props[prop];
  }

  function getMeta() {
    return getChildProp("data-__meta");
  }

  function getErrors() {
    let errors = [];

    if (props.form && props.name && typeof props.name === "string") {
      errors = props.form.getFieldError(props.name);
    } else {
      const childProp = getChildProp("data-__field");
      errors = childProp?.errors || [];
    }

    return errors;
  }

  function isRequired() {
    let required = false;

    if (props.rules) {
      required = props.rules.some((r) => r.required);
    } else if (getOnlyControl()) {
      const meta = getMeta() || {};
      const validate = meta.validate || [];

      required = validate
        .filter((item: any) => !!item.rules)
        .some((item: any) => {
          return item.rules.some((rule: any) => rule.required);
        });
    }

    return required;
  }

  const required = !props.omitRequired && isRequired();
  const errors = getErrors();
  const error =
    errors?.length > 0
      ? errors.map((e: any) => (e.message ? e.message : e)).join(", ")
      : null;

  return (
    <div
      className={tw(
        !props.border && "mb-4",
        props.border && "-mb-px border-b border-t border-borderGray py-4",
        props.type === "buttons" && "pb-12 pt-4",
        props.column && "flex items-center gap-4",
        props.className
      )}
    >
      {props.label && (
        <div className={tw("mb-2", props.column && "flex-1")}>
          {required && <span className="text-error">*</span>}{" "}
          <strong>{props.label}</strong>
        </div>
      )}

      <div className={tw(props.column && "flex-1")}>
        {props.form &&
          props.form.getFieldDecorator(props.name, {
            initialValue: props.initialValue,
            rules: props.rules,
            valuePropName: props.useChecked && "checked",
          })(props.children as ReactElement)}

        {!props.form && props.children}

        {!props.hideError && (
          <InputError
            error={error}
            className={tw("mt-2", props.reverse && "text-white")}
          />
        )}
      </div>
    </div>
  );
}
