import tw from "src/utils/tw";
import { useMainOffset } from "src/utils/useMainOffset";

interface Props {
  large?: boolean;
  main?: boolean;
  full?: boolean;
  fullSmall?: boolean;
  className?: string;
  children: any;
  additionalOffset?: number;
}

export default function Container(props: Props) {
  const offsetTop = useMainOffset();

  let maxWidth = "max-w-md";

  // if (hideHeader.some((regex) => regex.test(store.location.pathname))) {
  //   offsetTop -= 50;
  // }

  if (props.full) {
    maxWidth = "max-w-xl";
  } else if (props.large) {
    maxWidth = "max-w-l";
  }

  let offset = offsetTop;

  if (props.additionalOffset) {
    offset += props.additionalOffset;
  }

  return (
    <div
      className={tw(
        "border-borderGray",
        "ml-auto",
        "mr-auto",
        "relative",
        maxWidth,
        props.fullSmall ? "sm:pl-0 sm:pr-0" : "",
        props.main
          ? "border-l border-r sm:border-l-0 sm:border-r-0"
          : "pl-4 pr-4",
        props.className
      )}
      css={[
        props.main && {
          minHeight: `calc(100vh - ${offset}px)`,
        },
      ]}
    >
      {props.children}
    </div>
  );
}
