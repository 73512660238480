import { CardElement, useStripe } from "@stripe/react-stripe-js";
import moment from "moment-timezone";
import { useRef, useState } from "react";
import { SendEmailMutation } from "src/api/Email";
import {
  DeleteStripeSourceMutation,
  PaymentIntentMutation,
  StripeCustomerQuery,
  UpsertStripeCustomerMutation,
} from "src/api/Stripe";
import { UpdateUserMutation, UserBillingSummaryQuery } from "src/api/User";
import { BorderButton } from "src/components/buttons/BorderButton";
import { Alert } from "src/components/forms/Alert";
import InputRadio from "src/components/inputs/InputRadio";
import { InputRadioGroup } from "src/components/inputs/InputRadioGroup";
import OrganizationModel from "src/models/OrganizationModel";
import UserModel from "src/models/UserModel";
import { apiClient, useApi } from "src/stores/Api";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import colors from "src/utils/colors";
import { dateFormatLong, stripeCCOptions } from "src/utils/constants";
import formatMoney from "src/utils/formatMoney";
import message from "src/utils/message";
import renderEmail from "src/utils/renderEmail";
import {
  EmailType,
  Level,
  PaymentType,
  StripeType,
  deleteStripeSourceVariables,
  sendEmailVariables,
  updateUserVariables,
  upsertStripeCustomerVariables,
} from "types/code-generator";
import RetryPaymentButton from "../buttons/RetryPaymentButton";
import CardElementContainer from "../checkout/CardElementContainer";
import { InputItem } from "../inputs/InputItem";
import { InputText } from "../inputs/InputText";
import CreditCardIcon, { CreditCardType } from "./CreditCardIcon";
import { Form, useForm } from "./Form";

interface Props {
  user: UserModel;
  onNetworkAction?: () => void;
  showReviewButton?: boolean;
  organization?: OrganizationModel;
  adding?: boolean;
}

const PaymentDetailsUpdated = (vars) => ({
  subject: `Payment Details Updated - ${vars.organizationName}`,
  html: `<p>Dear ${vars.firstName},</p>

<p>Your payment details have been updated. Your new card on file (${vars.brand} ending in ${vars.last4}) is now your default card, effective immediately.</p>

<p>Sincerely,<br />
${vars.organizationName}</p>`,
  type: EmailType.PaymentDetailsUpdated,
});

export default function UpdatePaymentForm(props: Props) {
  const store = useStore();
  const form = useForm();
  const session = useSession();
  const stripe = useStripe();
  const cardElement = useRef(null);

  const [changingCard, setChangingCard] = useState(Boolean(props.adding));
  const [paymentError, setPaymentError] = useState<string>(null);

  const queryUserBillingSummary = useApi(UserBillingSummaryQuery, {
    skip: !props.user?.id,
    variables: {
      id: props.user?.id,
    },
    refetchOnWindowFocus: true,
  });

  const queryStripeCustomer = useApi(StripeCustomerQuery, {
    skip: !props.user?.stripeCustomerId,
    variables: {
      stripeCustomerId: props.user?.stripeCustomerId,
    },
    refetchOnWindowFocus: true,
  });

  // const stripeCustomer: Stripe.Customer & {
  //   default_source: Stripe.CustomerSource;
  // } = queryStripeCustomer?.stripeCustomer;

  // console.log("stripeCustomer", stripeCustomer);

  function changeCard() {
    setChangingCard(true);
    window.scrollTo(0, document.body.scrollHeight);
  }

  function cancelChangeCard() {
    setChangingCard(false);
  }

  function onSubmit() {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      console.log("values", values);

      if (
        !store.organization.stripeAccountId &&
        !store.organization.stripeExpressAccountId &&
        !props.organization
      ) {
        setPaymentError("Unable to add a card to this organization.");
        return;
      }

      const processingMessage = message.loading("Updating…");
      form.setIsSubmitting(true);

      stripe
        .createToken(cardElement.current, {
          name: values.nameOnCard,
        })
        .then((stripeToken) => {
          if (stripeToken.error) {
            throw new Error(stripeToken.error.message);
          }
          return updateUser(stripeToken.token);
        })
        .then((token) => {
          setTimeout(() => {
            sendEmail(token);
            setChangingCard(false);
          }, 16);
        })
        .catch((error) => {
          setPaymentError(error.message);
        })
        .finally(() => {
          processingMessage();
          form.setIsSubmitting(false);
        });
    });
  }

  async function updateUser(stripeToken) {
    // console.log("stripeToken", stripeToken);

    let token = {
      id: stripeToken.card?.id,
      brand: stripeToken.card?.brand,
      expMonth: stripeToken.card?.exp_month,
      expYear: stripeToken.card?.exp_year,
      name: stripeToken.card?.name,
      last4: stripeToken.card?.last4,
      tokenId: stripeToken.id,
    };

    if (stripeToken.bank_account) {
      token = {
        id: stripeToken.bank_account.id,
        brand: "Bank Account",
        expMonth: null,
        expYear: null,
        name: stripeToken.bank_account.account_holder_name,
        last4: stripeToken.bank_account.last4,
        tokenId: stripeToken.id,
      };
    }

    const stripeVariables: upsertStripeCustomerVariables = {
      data: {
        userId: props.user.id,
        stripeToken: token,
        organizationId: props.organization
          ? props.organization.id
          : store.organization.id,
      },
    };

    const upsert = await apiClient(UpsertStripeCustomerMutation, {
      variables: stripeVariables,
    });

    queryUserBillingSummary.refetch();

    onNetworkAction();

    if (store.user.level === Level.AccountOwner) {
      const newUser = new UserModel({
        ...store.user,
        stripeToken: token,
        stripeCustomerId: upsert.upsertStripeCustomer.stripeCustomerId,
      });

      store.setCurrentUser({ user: newUser });

      if (session.accountOwner) {
        session.updateAccountOwner(newUser);
      }
    }

    return token;
  }

  async function sendEmail(token) {
    const variables = {
      organizationName: props.organization
        ? props.organization.title
        : store.organization.title,
      firstName: props.organization
        ? props.organization.title
        : props.user.firstName,
      brand: token.brand,
      last4: token.last4,
      organizationUrl: `https://${store.organization.subdomain}.captyn.com`,
    };

    const emailData = renderEmail({
      template: PaymentDetailsUpdated,
      variables,
    });

    const emailTo = [];

    if (props.user.email) {
      emailTo.push(props.user.id);
    }

    if (props.organization?.email) {
      emailTo.push(props.organization.email);
    }

    const emailVariables: sendEmailVariables = {
      data: {
        users: emailTo,
        subject: emailData.subject,
        organizationId: store.organization.id,
        html: emailData.htmlText,
        type: emailData.type,
        smsText: emailData.smsText,
      },
    };

    await apiClient(SendEmailMutation, {
      variables: emailVariables,
    });
  }

  async function removeCard() {
    const processingMessage = message.loading("Updating…");
    const today = moment();
    let note = `Card removed on ${today.format(dateFormatLong)}`;

    if (store.user) {
      note += ` by ${store.user.firstName} ${store.user.lastName}`;
    }

    const variables: updateUserVariables = {
      data: {
        stripeToken: {
          ...props.user.stripeToken,
          id: null,
          note,
          error: null,
        },
      },
      where: {
        id: props.user.id,
      },
    };

    const deleteVariables: deleteStripeSourceVariables = {
      data: {
        stripeCustomerId: props.user.stripeCustomerId,
        stripeTokenId: props.user.stripeToken.id,
      },
    };

    await apiClient(UpdateUserMutation, { variables });

    try {
      await apiClient(DeleteStripeSourceMutation, {
        variables: deleteVariables,
      });
    } catch (e) {
      console.log(e);
    }

    processingMessage();
    onNetworkAction();
  }

  function setCardElement(ref) {
    cardElement.current = ref;
  }

  async function onTypeChange(value: PaymentType) {
    if (value === PaymentType.Bank) {
      const clientSecret = await apiClient(PaymentIntentMutation, {
        variables: {
          userId: props.user.id,
        },
      });

      console.log("clientSecret", clientSecret);

      const result = await stripe.collectBankAccountForSetup({
        clientSecret: clientSecret.paymentIntent,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: `${props.user.firstName} ${props.user.lastName}`,
              email: props.user.email,
            },
          },
        },
      });

      console.log("result", result);

      if (result.setupIntent.status === "requires_confirmation") {
        const confirmed = await apiClient(PaymentIntentMutation, {
          variables: {
            userId: props.user.id,
            setupIntentId: result.setupIntent.id,
          },
        });

        console.log("confirmed", confirmed);
      }

      setChangingCard(false);
      onNetworkAction();
    }
  }

  function onNetworkAction() {
    queryUserBillingSummary.refetch();
    queryStripeCustomer.refetch();

    if (props.onNetworkAction) {
      props.onNetworkAction();
    }
  }

  let content;
  let currentCardContent;
  let paymentErrorMessage;
  let cardErrorMessage;
  let cardNote;
  let balanceNote;
  let balancePaymentNote;
  const accountBalance = queryUserBillingSummary?.userBillingSummary?.balance;

  // console.log("invoices", invoices);
  // console.log("cardError", cardError);

  if (accountBalance > 0) {
    balanceNote = (
      <div className="flex text-sm font-bold text-error">
        <div className="mr-2">
          <i aria-hidden className="far fa-circle-info" />
        </div>
        <div className="flex-1">
          Balance Due • ${formatMoney(accountBalance)}
          {props.user.stripeToken?.error && (
            <>
              <br />
              {props.user.stripeToken.error}
            </>
          )}
        </div>
      </div>
    );
    balancePaymentNote = (
      <div className="-mt-2 mb-6 flex text-sm font-bold text-error">
        <div className="mr-2">
          <i aria-hidden className="far fa-circle-info" />
        </div>
        <div className="flex-1">
          Upon saving this new card, the account will immediately be charged the
          outstanding balance of ${formatMoney(accountBalance)}.
        </div>
      </div>
    );
  }

  if (paymentError) {
    paymentErrorMessage = (
      <Alert className="mb-2" type="error" message={paymentError} />
    );
  }

  if (props.user?.stripeToken) {
    cardNote = (
      <>
        {props.user?.stripeToken.note && (
          <p className="mb-0 text-empty">{props.user.stripeToken.note}</p>
        )}

        {store.organization.stripe === StripeType.Express && (
          <div className="text-empty">
            The description on your statement will say “Captyn”.
          </div>
        )}
      </>
    );
  }

  if (changingCard) {
    let paymentElement = (
      <div data-private data-logrocket>
        <CardElementContainer>
          <CardElement options={stripeCCOptions} onReady={setCardElement} />
        </CardElementContainer>
      </div>
    );

    if (form.getFieldValue("paymentType") === PaymentType.Bank) {
      paymentElement = <div></div>;
    }
    content = (
      <Form onSubmit={onSubmit}>
        {paymentErrorMessage}

        {form.getFieldValue("paymentType") !== PaymentType.Bank && (
          <InputItem label="Change Card">
            {form.getFieldDecorator("nameOnCard")(
              <InputText
                placeholder="Name on Card"
                disabled={form.isSubmitting}
              />
            )}
          </InputItem>
        )}

        <div data-private data-logrocket>
          {paymentElement}
        </div>
        {balancePaymentNote}
        <div className="mr-4 inline-block">
          <BorderButton type="submit" disabled={form.isSubmitting}>
            Save
          </BorderButton>
        </div>
        <BorderButton onClick={cancelChangeCard} disabled={form.isSubmitting}>
          Cancel
        </BorderButton>
      </Form>
    );
  }

  let stripeUser = props.user;

  if (props.user?.primaryAccount) {
    stripeUser = props.user.primaryAccount;
  }

  if (stripeUser?.stripeToken && stripeUser?.stripeToken.id) {
    const card = stripeUser?.stripeToken;
    const hasNextAction = stripeUser?.stripeToken.nextAction;
    let nextActionUrl;
    let allowRemove = !props.showReviewButton && store.user.isAdminOrAbove;

    if (props.organization && store.isAdminArea) {
      allowRemove = false;
    }

    if (hasNextAction) {
      if (hasNextAction.verify_with_microdeposits) {
        nextActionUrl =
          hasNextAction.verify_with_microdeposits.hosted_verification_url;
      }
    }

    currentCardContent = (
      <>
        <div className="mb-4">
          {cardErrorMessage}

          {store.user.id !== props.user.id &&
            card.brand !== CreditCardType["Bank Account"] && (
              <p className="mb-2">{card.name}</p>
            )}

          {card.brand === CreditCardType["Bank Account"] && (
            <p className="mb-2">Bank Account</p>
          )}

          <div className="mb-4 flex">
            <div className="mr-2">
              <CreditCardIcon
                type={card.brand as CreditCardType}
                error={accountBalance > 0}
              />
            </div>
            <div className="flex-1">
              Ending in {card.last4}
              <>
                {card.expMonth && (
                  <>
                    {" "}
                    • Expires {card.expMonth}/{card.expYear}
                  </>
                )}
              </>
              {cardNote}
              {balanceNote}
            </div>
          </div>
        </div>

        {hasNextAction && (
          <>
            <div>
              <p className="">
                <i className="far fa-circle-info mr-2 text-error" />{" "}
                <strong>
                  This payment method requires additional verification.
                </strong>
              </p>
              <div>
                <BorderButton
                  type="submit"
                  href={nextActionUrl}
                  target="_blank"
                >
                  Verify Payment Method
                </BorderButton>
              </div>
            </div>
          </>
        )}

        {!changingCard && !hasNextAction && (
          <>
            {accountBalance > 0 && (
              <div className="mb-2">
                <RetryPaymentButton
                  balance={accountBalance}
                  user={props.user}
                  onNetworkAction={onNetworkAction}
                  organization={props.organization}
                />
              </div>
            )}

            <BorderButton
              onClick={changeCard}
              className="mr-4"
              disabled={form.isSubmitting}
            >
              <i className="far fa-arrow-right-arrow-left mr-2 text-organization" />{" "}
              <span className="text-dark">
                {props.organization && <>Change Payment Method</>}
                {!props.organization && (
                  <>
                    Change<span className="sm:hidden"> Card</span>
                  </>
                )}
              </span>
            </BorderButton>

            {allowRemove && (
              <BorderButton onClick={removeCard} disabled={form.isSubmitting}>
                <i className="far fa-trash-can mr-2 text-organization" />{" "}
                <span className="text-dark">Remove</span>
              </BorderButton>
            )}

            {props.showReviewButton && (
              <BorderButton to={`/account/settings#payment`}>
                <span className="text-dark">Review</span>
              </BorderButton>
            )}
          </>
        )}
      </>
    );
  } else {
    currentCardContent = (
      <>
        <div className="mb-4">
          <p className="mb-0">No card on file.</p>
          {cardNote}
          {balanceNote}
        </div>
        {!changingCard && (
          <BorderButton onClick={changeCard} disabled={form.isSubmitting}>
            <i
              className="far fa-credit-card mr-2"
              css={{
                color: store.isAdminArea ? colors.action : store.theme.orgColor,
              }}
            />{" "}
            Add Card
          </BorderButton>
        )}
      </>
    );
  }

  let paymentTypePicker;

  if (props.organization && changingCard) {
    paymentTypePicker = (
      <InputItem form={form} initialValue={PaymentType.Card} name="paymentType">
        <InputRadioGroup disabled={form.isSubmitting} onChange={onTypeChange}>
          <InputRadio value={PaymentType.Card} label="Card" />
          <InputRadio value={PaymentType.Bank} label="Bank Account" />
        </InputRadioGroup>
      </InputItem>
    );
  }

  // return (
  //   <form onSubmit={onSubmit}>
  //     <PaymentElement />
  //   </form>
  // );

  return (
    <div>
      {currentCardContent}
      {paymentTypePicker}
      {content}
    </div>
  );
}
