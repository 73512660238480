import { Elements } from "@stripe/react-stripe-js";
import { QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorBoundry from "src/components/layout/ErrorBoundry";
import Layout from "src/components/layout/Layout";
import Loading from "src/components/layout/Loading";
import { queryClient } from "src/stores/Api";
import { StoreProvider } from "src/stores/Store";
import { stripe } from "src/stores/Stripe";
import { CustomToaster } from "src/utils/message";
import { ModalProvider } from "src/utils/modals";
import { SessionProvider } from "./stores/Session";
import { UIProvider } from "./stores/UI";
import { stripeFonts } from "./utils/constants";
import lazyImport from "./utils/lazyImport";
import { ViewportProvider } from "./utils/useMediaQueries";

const EmailTest = lazyImport(() => import("src/routes/superAdmin/EmailTest"));
const MoveEmailTest = lazyImport(
  () => import("src/routes/superAdmin/MoveEmailTest")
);
const CancelEmailTest = lazyImport(
  () => import("src/routes/superAdmin/CancelEmailTest")
);
const PlainEmailTest = lazyImport(
  () => import("src/routes/superAdmin/PlainEmailTest")
);
const InvoiceTest = lazyImport(
  () => import("src/routes/superAdmin/InvoiceTest")
);
const Start = lazyImport(() => import("src/routes/start/Start"));

let testRoutes = [];

if (process.env.NODE_ENV === "development") {
  testRoutes = [
    <Route key="email" exact path="/email-test" component={EmailTest} />,
    <Route
      key="move-email"
      exact
      path="/move-email-test"
      component={MoveEmailTest}
    />,
    <Route
      key="cancel-email"
      exact
      path="/cancel-email-test"
      component={CancelEmailTest}
    />,
    <Route
      key="plain-email"
      exact
      path="/plain-email-test"
      component={PlainEmailTest}
    />,
    <Route key="invoice" exact path="/invoice-test" component={InvoiceTest} />,
  ];
}

export default function App() {
  return (
    <ErrorBoundry>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StoreProvider>
            <Elements stripe={stripe} options={{ fonts: stripeFonts }}>
              <ViewportProvider>
                <SessionProvider>
                  <UIProvider>
                    <ModalProvider>
                      <React.Suspense
                        fallback={<Loading delay={250} className="h-[75vh]" />}
                      >
                        <Switch>
                          {testRoutes}
                          <Route path="/start" component={Start} />
                          <Route component={Layout} />
                        </Switch>
                      </React.Suspense>
                      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                      <CustomToaster />
                    </ModalProvider>
                  </UIProvider>
                </SessionProvider>
              </ViewportProvider>
            </Elements>
          </StoreProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundry>
  );
}
