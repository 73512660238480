import { useEffect } from "react";

export function useIntersectionObserver({
  root = null,
  target,
  onIntersect,
  threshold = [1],
  rootMargin = "0px",
  enabled = true,
}) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (root) {
            return onIntersect(entry);
          }
          return entry.isIntersecting && onIntersect(entry);
        }),
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
}
