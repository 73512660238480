import { Component, ReactNode } from "react";
import { BorderButton } from "src/components/buttons/BorderButton";
import logrocket from "src/utils/logrocket";

interface Props {
  error?: Error;
  children?: ReactNode;
}

interface State {
  error: Error;
}

export default class ErrorBoundry extends Component<Props, State> {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);

    logrocket.captureException(error, {
      extra: errorInfo,
    });
  }

  render() {
    if (this.state.error || this.props.error) {
      return (
        <div className="flex h-screen items-center justify-center">
          <div className="max-w-sm px-4">
            <button
              className="m-0 mb-2 block w-full cursor-pointer border-none bg-transparent p-0 text-left text-sm text-empty"
              onClick={() => window.location.reload()}
            >
              <i aria-hidden className="far fa-circle-arrow-left" /> Go Back
            </button>
            <h1 className="mb-0">We’re sorry!</h1>
            <h3 className="text-empty">Something’s gone wrong.</h3>
            {this.props.error && (
              <p className="text-error">{this.props.error.message}</p>
            )}
            <p>
              Our team has been notified with details about the error. If you
              would like to give us some more feedback about what happened,
              please fill out an error report.
            </p>
            <BorderButton
              type="submit"
              onClick={() =>
                (window.location.href =
                  "mailto:support@captyn.com?subject=Customer%20Support")
              }
            >
              Contact Support
            </BorderButton>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
