import { useLocation } from "react-router-dom";
import { useStore } from "src/stores/Store";
import {
  SwimAmericaDomains,
  hideHeader,
  showWideContainer,
  swimAmericaHideHeader,
} from "src/utils/constants";
import { useMediaQueries } from "src/utils/useMediaQueries";

interface Props {
  cartOpen?: boolean;
  className?: string;
  isSuperAdmin?: boolean;
  isViewingAs?: boolean;
  children: any;
}

export default function Main(props: Props) {
  const store = useStore();
  const location = useLocation();
  const { small } = useMediaQueries();

  let marginTop = 50;
  let paddingBottom = "0rem";

  const isWide = showWideContainer.some((regex) =>
    regex.test(location.pathname)
  );

  if (props.cartOpen) {
    marginTop += 44;
  }

  if (
    hideHeader.some((regex) => regex.test(location.pathname)) ||
    (SwimAmericaDomains.includes(store.organization.subdomain) &&
      swimAmericaHideHeader.some((regex) => regex.test(location.pathname)))
  ) {
    marginTop = 0;
  }

  if (props.isSuperAdmin) {
    marginTop += 30;
  }

  if (props.isViewingAs) {
    marginTop += 30;
  }

  if (location.pathname.includes("/admin/schedule") && !small) {
    marginTop += 60;

    if (location.pathname.includes("/admin/schedule/month")) {
      marginTop += 30;
    }
  }

  if (isWide) {
    paddingBottom = "0";
  }

  return (
    <section
      className={props.className}
      css={{
        paddingBottom,
        marginTop,
      }}
      id="main"
    >
      {props.children}
    </section>
  );
}
