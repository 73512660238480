import { InvoiceItemCouponModel } from "src/models/InvoiceItemCouponModel";
import { InvoiceItemCreditModel } from "src/models/InvoiceItemCredit";
import { InvoiceItemDiscountModel } from "src/models/InvoiceItemDiscountModel";
import { InvoiceItemModel } from "src/models/InvoiceItemModel";
import InvoiceModel from "src/models/InvoiceModel";
import { DiscountType } from "types/code-generator";
import { v4 as uuid } from "uuid";

export default (invoiceItems: InvoiceItemModel[]) => {
  const result: Partial<InvoiceItemModel>[] = [];

  const invoice = new InvoiceModel({
    invoiceItems,
  });

  const enrollmentCredits = invoiceItems.reduce(
    (acc: InvoiceItemCreditModel[], ii) => {
      if (
        (ii.groupRegistration || ii.privateSerie || ii.membership) &&
        ii.credits
      ) {
        acc = acc.concat(ii.credits);
      }
      return acc;
    },
    []
  );

  result.push({
    id: uuid(),
    amount: Math.floor(invoice.getTotals().totalEnrollments),
    description: "Subtotal",
    credits: enrollmentCredits,
  });

  const coupons = invoiceItems
    .filter((i) => i.coupons && i.coupons.length > 0)
    .reduce(
      (acc: InvoiceItemCouponModel[], i) => acc.concat(i.coupons || []),
      []
    );

  const couponsTotal = coupons.reduce((acc, c) => (acc += c.amount), 0);

  const discountInvoiceItems = invoiceItems
    .filter((i) => i.discounts && i.discounts.length > 0)
    .reduce(
      (acc: InvoiceItemDiscountModel[], i) => acc.concat(i.discounts || []),
      []
    );

  // console.log("discountInvoiceItems", discountInvoiceItems);

  if (discountInvoiceItems.length > 0) {
    const multiClassDiscounts = discountInvoiceItems.filter(
      (d) => d.discount?.discountType === DiscountType.MultiClass
    );
    const multiClassDiscountAmount = multiClassDiscounts.reduce(
      (acc, d) => acc + d.amount,
      0
    );
    const multiStudentDiscounts = discountInvoiceItems.filter(
      (d) => d.discount?.discountType === DiscountType.MultiStudent
    );
    const multiStudentDiscountAmount = multiStudentDiscounts.reduce(
      (acc, d) => acc + d.amount,
      0
    );
    const otherDiscounts = discountInvoiceItems.filter((d) => !d.discount);
    const otherDiscountAmount = otherDiscounts.reduce(
      (acc, d) => acc + d.amount,
      0
    );

    if (multiStudentDiscountAmount > 0) {
      result.push({
        id: uuid(),
        amount: multiStudentDiscountAmount,
        description: "Multi-Participant Discount",
        discounts: multiStudentDiscounts,
        omit: true,
      });
    }

    if (multiClassDiscountAmount > 0) {
      result.push({
        id: uuid(),
        amount: multiClassDiscountAmount,
        description: "Multi-Offering Discount",
        discounts: multiClassDiscounts,
        omit: true,
      });
    }

    if (otherDiscountAmount) {
      result.push({
        id: uuid(),
        amount: otherDiscountAmount,
        description: "Session Discount",
        discounts: otherDiscounts,
        omit: true,
      });
    }
  }
  // console.log("invoiceItems", invoiceItems);

  invoiceItems.forEach((i) => {
    if (
      i.charge ||
      i.addon ||
      i.credit ||
      i.description === "Outstanding Account Balance" ||
      i.description === "Sales Tax" ||
      i.description === "One-time Additional Fees" ||
      i.fee ||
      i.description?.includes("Adding")
    ) {
      i.coupons = [];
      result.push(i);
    }
  });

  if (couponsTotal > 0) {
    result.push({
      id: uuid(),
      amount: couponsTotal,
      description: "Coupons",
      omit: true,
      coupons,
    });
  }

  return result;
};
