import SlideDown from "../layout/SlideDown";

interface Props {
  error: string | Array<{ field: string | number | symbol; message: string }>;
  className?: string;
}

export default function InputError(props: Props) {
  let error: string = props.error?.toString();

  if (Array.isArray(props.error)) {
    error = props.error.map((e) => e.message).join(", ");
  }

  return (
    <SlideDown>
      {error && (
        <div className={`flex pt-1 ${props.className}`}>
          <div className="mr-2">
            <i aria-hidden className="far fa-triangle-exclamation text-error" />
          </div>
          <div className="flex-1 font-bold">
            <>{error}</>
          </div>
        </div>
      )}
    </SlideDown>
  );
}
