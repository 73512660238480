import colors from "src/utils/colors";
import formatMoney from "src/utils/formatMoney";
import tw from "src/utils/tw";
import { userReferrals, userReferralTemplate } from "types/code-generator";

interface Props {
  userReferrals: userReferrals["user"]["referrals"];
  userReferralTemplate: userReferralTemplate["userReferralTemplate"];
}

export function UserReferralsProgress(props: Props) {
  return (
    <div className="divide-y divide-borderGray rounded-b-lg border-x border-b border-borderGray bg-white">
      {props.userReferrals.map((r) => {
        const milestones = r.referralTemplate.milestones.sort(
          (a, b) => a.amount - b.amount
        );
        const progress = milestones[r.milestoneProgress];
        let amount = 0;

        for (const [i, m] of milestones.entries()) {
          if (i <= r.milestoneProgress) {
            amount += m.amount;
          }
        }

        return (
          <div key={r.id} className="px-6 py-4">
            <div className="flex">
              <div className="mb-2 flex-1 text-organization">
                <strong className="text-lg text-dark">{r.name}</strong>{" "}
                <span className="sm:block">Status • {progress.title}</span>
              </div>
              <div className="font-bold text-organization sm:pt-1">
                ${formatMoney(amount)} earned
              </div>
            </div>

            <div>
              <div
                className="flex h-4 overflow-hidden rounded-full bg-bgGray"
                css={{
                  boxShadow: `0 0 0 1px inset ${colors.borderGray}`,
                }}
              >
                {milestones.map((m, i) => {
                  return (
                    <div
                      key={m.id}
                      className={tw(
                        "h-full flex-1",
                        i <= r.milestoneProgress ? "bg-main" : "bg-transparent",
                        i !== 0 &&
                          i > r.milestoneProgress + 1 &&
                          "border-l border-borderGray",
                        i !== 0 &&
                          i <= r.milestoneProgress &&
                          "border-l border-mainDark"
                      )}
                    ></div>
                  );
                })}
              </div>
              <div className="flex pb-1 pt-2 sm:flex-col">
                {milestones.map((m, i) => {
                  return (
                    <div
                      key={m.id}
                      className={tw(
                        "flex-1 text-right text-xs font-bold sm:mb-1 sm:text-left",
                        i > r.milestoneProgress && "opacity-50"
                      )}
                    >
                      {m.title}{" "}
                      <span className="text-organization sm:block">
                        +${formatMoney(m.amount)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
