import { EmailTemplateMove } from "src/components/email/EmailTemplate";
import { EmailType } from "types/code-generator";

interface RenderEmailInput<T> {
  template: EmailTemplate;
  variables: T;
}

export type EmailTemplate = (vars) => {
  subject: string;
  type?: string;
  sms?: string;
  html: string;
};

export interface RenderEmailPayload {
  subject: string;
  smsText?: string;
  type?: EmailType;
  htmlText?: string;
  move?: EmailTemplateMove;
}

export default function renderEmail<T>({
  template,
  variables,
}: RenderEmailInput<T>): RenderEmailPayload {
  const content = template(variables);
  const emailSubject = content.subject;

  let smsText;
  let htmlText;

  if (content.sms) {
    smsText = content.sms;
  }

  if (content.html) {
    htmlText = content.html;
  }

  const result = {
    subject: emailSubject,
    smsText,
    type: content.type as EmailType,
    htmlText,
  };

  return result;
}
