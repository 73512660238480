import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "src/components/modals/ConfirmModal";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import { BorderButton } from "../buttons/BorderButton";

const bounce = keyframes({
  "from, 20%, 53%, 80%, to": {
    transform: "translate3d(0,0,0)",
  },
  "40%, 43%": {
    transform: "translate3d(0, -30px, 0)",
  },
  "70%": {
    transform: "translate3d(0, -15px, 0)",
  },
  "90%": {
    transform: "translate3d(0,-4px,0)",
  },
});

export default function HeaderCartButton(props) {
  const store = useStore();
  const session = useSession();
  const [animation, setAnimation] = useState(`${bounce} 1s ease`);
  const history = useHistory();

  const totalRegistrations =
    session.groupRegistrations.length +
    session.privateSeries.length +
    session.memberships.length;

  useEffect(() => {
    setAnimation("");

    setTimeout(() => {
      setAnimation(`${bounce} 1s ease`);
    }, 32);
  }, [session.groupRegistrations, session.privateSeries, session.memberships]);

  function clearCart() {
    modals.show(ConfirmModal, {
      handleOk: async () => {
        if (store.user?.isAdminOrAbove) {
          session.clearSession();
          // history.push("/admin");
        } else {
          session.clearUserSession();
        }
      },
      title: "Clear Cart?",
      body: <p>Would you like to clear your cart selections?</p>,
      buttonText: "Yes, clear it",
      cancelText: "Nevermind",
    });
  }

  function checkout() {
    if (store.isAdminArea) {
      history.push("/admin/checkout");
    } else {
      history.push("/checkout");
    }
  }

  if (!store.isAdminArea) {
    return (
      <BorderButton
        onClick={checkout}
        small
        type="submit"
        className="leading-none"
        css={{
          animation,
          animationDelay: ".25s",
        }}
      >
        Cart <i aria-hidden className="far fa-cart-shopping mr-1 ml-0.5" />{" "}
        <strong>{totalRegistrations}</strong>
      </BorderButton>
    );
  }

  return (
    <>
      <div
        id="cart-button"
        css={{
          animation,
          animationDelay: ".25s",
        }}
        className={tw(
          "inline-block",
          "align-top",
          "border-b-3",
          "border",
          "rounded-full",
          "cursor-pointer",
          "overflow-hidden",
          "h-9",
          "truncate",
          "border-borderGray",
          "bg-lightGray"
        )}
      >
        <div className="flex h-full items-stretch">
          <div className="flex flex-1 items-center border-r border-borderGray">
            <button
              onClick={checkout}
              className="h-full cursor-pointer pl-4 pr-2.5 active:bg-borderGray"
            >
              <i aria-hidden className="far fa-cart-shopping mr-1" />{" "}
              <strong>{totalRegistrations}</strong>
            </button>
          </div>
          <button
            className="cursor-pointer pr-4 pl-3 active:bg-borderGray"
            onClick={clearCart}
          >
            <i aria-hidden className="far fa-trash-can" />
          </button>
        </div>
      </div>
    </>
  );
}
