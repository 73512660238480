import { Link, useHistory, useLocation } from "react-router-dom";
import { BorderButton } from "src/components/buttons/BorderButton";
import LinkButton from "src/components/buttons/LinkButton";
import FacebookIcon from "src/components/icons/FacebookIcon";
import InstagramIcon from "src/components/icons/InstagramIcon";
import TwitterIcon from "src/components/icons/TwitterIcon";
import { ProfileSwitcher } from "src/components/menu/ProfileSwitcher";
import { ContactSupportModal } from "src/components/modals/ContactSupportModal";
import { UserReferralTemplate } from "src/components/referrals/UserReferralTemplate";
import UserModel from "src/models/UserModel";
import { useSession } from "src/stores/Session";
import { useStore } from "src/stores/Store";
import { useUI } from "src/stores/UI";
import colors from "src/utils/colors";
import { formatPhoneNumber } from "src/utils/formatPhone";
import getUserAge, { AgeUnit } from "src/utils/getUserAge";
import { modals } from "src/utils/modals";
import tw from "src/utils/tw";
import {
  BetaAccessType,
  IntegrationType,
  Level,
  PermissionType,
} from "types/code-generator";
import { DepartmentFilter } from "./DepartmentFilter";
import { MenuContainer } from "./MenuContainer";
import { MenuItem } from "./MenuItem";

interface Props {}

export default function Menu(props: Props) {
  const ui = useUI();
  const store = useStore();
  const session = useSession();
  const history = useHistory();
  const location = useLocation();

  function toggleMenu() {
    ui.toggleMenu();
  }

  function signOut() {
    store.signOut();
    session.clearSession();
    ui.setMenuOpen(false);

    setTimeout(() => {
      history.push("/login");
    }, 150);
  }

  function getFindUrl() {
    let findUrl = "/find";
    const params = new URLSearchParams();

    if (store.user?.students?.length > 0) {
      const studentAges = store.user?.students.reduce((acc, s) => {
        if (s.birthDate) {
          const studentAge = getUserAge(s.birthDate);

          if (studentAge.ageUnit === AgeUnit.Years) {
            acc.add(studentAge.ageNumber);
          } else if (studentAge.ageUnit === AgeUnit.Months) {
            acc.add(0);
          }
        }

        return acc;
      }, new Set());

      for (const a of studentAges) {
        params.append("ages", a.toString());
      }

      findUrl += `?${params.toString()}`;
    }

    if (store.isApp) {
      findUrl = "/account/organizations";
    }

    return findUrl;
  }

  function switchView(level: Level) {
    const newUser = new UserModel({
      ...store.user,
      level,
    });

    store.setCurrentUser({
      user: newUser,
    });

    if (level === Level.Admin && location.pathname.startsWith("/account")) {
      history.push("/admin");
    }
  }

  function emailSupport() {
    modals.show(ContactSupportModal);
  }

  function emailOrg() {
    modals.show(ContactSupportModal, {
      title: `Send ${store.organization?.title} a Message`,
    });
  }

  let menuFooter;

  let menuContent = (
    <>
      <MenuItem to="/">Home</MenuItem>
      <MenuItem to="/explore">Explore & Register</MenuItem>
      {!store.user && (
        <>
          <MenuItem to="/login">Sign In</MenuItem>
          <MenuItem to="/register">Create Your Account</MenuItem>
        </>
      )}
    </>
  );

  if (
    store.user?.level === Level.Admin ||
    store.user?.level === Level.SuperAdmin ||
    store.user?.level === Level.Sales
  ) {
    const hasMeets =
      store.organization?.integrations.includes(IntegrationType.USASwimming) ||
      store.organization.betaAccess.includes(BetaAccessType.Meets);
    const hasVolunteers = store.organization.betaAccess.includes(
      BetaAccessType.Volunteers
    );
    menuContent = (
      <>
        <MenuItem to="/admin">Home</MenuItem>
        <MenuItem to="/admin/schedule">Today</MenuItem>
        <MenuItem to="/admin/users">People</MenuItem>
        <MenuItem to={"/admin/group-offerings"}>Programming</MenuItem>

        {(store.organization?.betaAccess.includes(
          BetaAccessType.PrivateLessons
        ) ||
          store.user?.isSalesOrAbove) && (
          <MenuItem to="/admin/custom-private-lessons">
            Custom {store.organization?.privateTerminology}
          </MenuItem>
        )}

        {(store.organization?.betaAccess.includes(BetaAccessType.Memberships) ||
          store.user?.isSalesOrAbove) && (
          <MenuItem to="/admin/membership-offerings">
            <div className="flex items-center">
              <div className="flex-1">Memberships</div>
            </div>
          </MenuItem>
        )}

        {hasMeets && <MenuItem to="/admin/meets">Meets & Times</MenuItem>}

        {hasVolunteers && (
          <MenuItem to="/admin/volunteers">Volunteers</MenuItem>
        )}

        {(store.user?.permissions.includes(
          PermissionType.AccessAdvancedSetting
        ) ||
          store.user.isSuperAdmin) && (
          <MenuItem to="/admin/settings">Settings & Reports</MenuItem>
        )}
      </>
    );
    menuFooter = (
      <div>
        <div>Switch to my:</div>
        <LinkButton
          className="block"
          onClick={() => switchView(Level.Instructor)}
        >
          <strong>{store.organization?.staffTerminology} view</strong>
        </LinkButton>

        <LinkButton
          className="block"
          onClick={() => switchView(Level.AccountOwner)}
        >
          <strong>{store.organization?.customerTerminology} view</strong>
        </LinkButton>
      </div>
    );
  }

  if (store.user?.level === Level.Instructor) {
    menuContent = (
      <>
        {store.organization?.hasDepartments && <DepartmentFilter />}
        <MenuItem to="/account">Home</MenuItem>
        <MenuItem to="/admin/schedule">Today</MenuItem>

        {(store.organization?.betaAccess.includes(
          BetaAccessType.PrivateLessons
        ) ||
          store.user?.isSalesOrAbove) && (
          <MenuItem to="/admin/custom-private-lessons">
            Custom {store.organization?.privateTerminology}
          </MenuItem>
        )}

        {store.organization?.integrations.includes(
          IntegrationType.USASwimming
        ) && <MenuItem to="/admin/meets">Meets</MenuItem>}

        <MenuItem to="/account/upcoming-schedule">
          My Upcoming Schedule
        </MenuItem>
        <MenuItem to="/account/settings">My Account</MenuItem>
      </>
    );
    menuFooter = (
      <div>
        <div>Switch to my:</div>

        {(store.userLevel === Level.Admin ||
          store.userLevel === Level.SuperAdmin ||
          store.userLevel === Level.Sales) && (
          <LinkButton className="block" onClick={() => switchView(Level.Admin)}>
            <strong>Admin view</strong>
          </LinkButton>
        )}

        <LinkButton
          className="block"
          onClick={() => switchView(Level.AccountOwner)}
        >
          <strong>{store.organization?.customerTerminology} view</strong>
        </LinkButton>
      </div>
    );
  }

  if (
    store.user?.level === Level.AccountOwner ||
    store.user?.level === Level.Captyn
  ) {
    const findUrl = getFindUrl();

    menuContent = (
      <>
        <MenuItem to="/account">Home</MenuItem>
        <MenuItem to="/account/settings">My Account</MenuItem>
        <MenuItem to={findUrl}>Explore & Register</MenuItem>
      </>
    );

    menuFooter = (
      <div>
        {(store.userLevel === Level.Instructor ||
          store.userLevel === Level.Admin ||
          store.userLevel === Level.SuperAdmin ||
          store.userLevel === Level.Sales) && (
          <>
            <div>Switch to my:</div>

            {(store.userLevel === Level.Admin ||
              store.userLevel === Level.SuperAdmin ||
              store.userLevel === Level.Sales) && (
              <>
                <LinkButton
                  className="block"
                  onClick={() => switchView(Level.Admin)}
                >
                  <strong>
                    {store.organization?.administratorTerminology} view
                  </strong>
                </LinkButton>

                <LinkButton
                  className="block"
                  onClick={() => switchView(Level.Instructor)}
                >
                  <strong>{store.organization?.staffTerminology} view</strong>
                </LinkButton>
              </>
            )}

            {store.userLevel === Level.Instructor && (
              <LinkButton
                className="block"
                onClick={() => switchView(Level.Instructor)}
              >
                <strong>{store.organization?.staffTerminology} view</strong>
              </LinkButton>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <MenuContainer>
        <div className="flex h-full flex-col">
          <div className="flex-1">
            <ProfileSwitcher />

            {store.organization?.hasDepartments &&
              store.user?.isAdminOrAbove && <DepartmentFilter />}

            <div className="border-b border-borderGray bg-white">
              {menuContent}
            </div>

            {store.user && (
              <div className="px-4 py-4">
                <BorderButton
                  bgColor={colors.lightGray}
                  onClick={signOut}
                  className="w-full"
                >
                  Sign Out
                </BorderButton>
              </div>
            )}

            {(store.user?.level === Level.AccountOwner ||
              store.user?.level === Level.Captyn ||
              !store.user) && (
              <>
                <div className="mx-4 -mt-px mb-4 border-t border-borderGray pt-4">
                  <div className="step-subhead mb-2">
                    Contact {store.organization?.title}
                  </div>

                  <div className="mb-3">
                    {store.organization?.email && (
                      <LinkButton onClick={emailOrg} className="mb-1 block">
                        <i className="far fa-envelope w-5 text-left" /> Send a
                        message
                      </LinkButton>
                    )}

                    {store.organization?.phone && (
                      <a
                        href={`tel:${formatPhoneNumber(
                          store.organization?.phone
                        )}`}
                        css={{
                          color: store.theme.orgColor,
                        }}
                        className="block"
                      >
                        <i className="far fa-phone w-5 text-left" />{" "}
                        {formatPhoneNumber(store.organization?.phone)}
                      </a>
                    )}
                  </div>

                  <div className="flex gap-3">
                    {store.organization?.facebook && (
                      <a
                        href={store.organization?.facebook}
                        rel="nofollow noreferrer"
                        target="_blank"
                        css={{
                          color: store.theme.orgColor,
                        }}
                        className="transition hover:opacity-80"
                      >
                        <FacebookIcon className="h-6 w-6" />
                      </a>
                    )}

                    {store.organization?.instagram && (
                      <a
                        href={store.organization?.instagram}
                        rel="nofollow noreferrer"
                        target="_blank"
                        css={{
                          color: store.theme.orgColor,
                        }}
                        className="transition hover:opacity-80"
                      >
                        <InstagramIcon className="h-6 w-6" />
                      </a>
                    )}

                    {store.organization?.twitter && (
                      <a
                        href={store.organization?.twitter}
                        rel="nofollow noreferrer"
                        target="_blank"
                        css={{
                          color: store.theme.orgColor,
                        }}
                        className="transition hover:opacity-80"
                      >
                        <TwitterIcon className="h-6 w-6" />
                      </a>
                    )}
                  </div>
                </div>
              </>
            )}

            {store.user && (
              <div className="mx-4 border-t border-borderGray py-4">
                {menuFooter}
                {(store.userLevel === Level.SuperAdmin ||
                  store.userLevel === Level.Sales) &&
                  store.user?.level !== store.userLevel && (
                    <LinkButton
                      className="block"
                      onClick={() => switchView(store.userLevel)}
                    >
                      <strong>Reset view</strong>
                    </LinkButton>
                  )}
              </div>
            )}

            {store.user && (
              <div className="mb-8 p-4">
                <UserReferralTemplate small />
              </div>
            )}
          </div>

          <div className="px-4 py-4 text-xs text-empty">
            <a
              href="https://captyn.com"
              target="_blank"
              rel="nofollow noreferrer"
              className="block text-empty"
            >
              <strong>Powered by</strong>{" "}
              <img
                src="/img/captyn-logo-gray.svg"
                alt="Captyn"
                className="ml-0.5 mt-0.5 h-3.5"
              />
            </a>
            <Link to="/terms-of-service" className="text-empty">
              Terms of Service
            </Link>{" "}
            •{" "}
            <Link to="/privacy-policy" className="text-empty">
              Privacy Policy
            </Link>{" "}
            •{" "}
            <button className="text-empty" onClick={emailSupport}>
              Contact
            </button>
          </div>
        </div>
      </MenuContainer>
      <div
        className={tw(
          "fixed inset-0 z-20 transition",
          ui.menuOpen
            ? "bg-dark opacity-60"
            : "pointer-events-none bg-transparent opacity-0"
        )}
        onClick={toggleMenu}
      />
    </>
  );
}
