import { gql } from "graphql-tag";
import { RouteComponentProps } from "react-router-dom";
import { withApi, withApiMutation, withInfiniteApi } from "src/stores/Api";
import { StoreProps } from "src/stores/Store";
import { getSearchParams } from "src/utils/useSearchParams";
import {
  PrivateSerieAttendanceStatus,
  createPrivateSerieAttendance,
  createPrivateSerieAttendanceVariables,
  filteredPrivateSerieAttendanceCountsVariables,
  filteredPrivateSeriesAttendances,
  filteredPrivateSeriesAttendancesVariables,
  privateSerieAttendance,
  privateSerieAttendanceVariables,
  privateSerieAttendancesForDay,
  privateSerieAttendancesForDayVariables,
  updatePrivateSerieAttendance,
  updatePrivateSerieAttendanceVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

export const PrivateSerieAttendancePayloadFragment = gql`
  fragment PrivateSerieAttendancePayload on PrivateSerieAttendance {
    atAccountHome
    automated
    completedBy {
      id
      firstName
      lastName
    }
    completedOn
    createdAt
    date
    duration
    id
    instructor {
      id
      firstName
      lastName
    }
    invoiceItems {
      id
      amount
      payments {
        id
        amount
      }
    }
    location {
      id
      title
      address1
    }
    note
    number
    privateSerie {
      id
      instructor {
        id
        firstName
        lastName
      }
      numberOfLessons
      offering {
        id
        department {
          id
          title
        }
        duration
        participantType
        paymentFrequency
        program {
          id
          title
        }
        title
        type
      }
      roster {
        id
        firstName
        lastName
        gender
        healthConcerns
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        birthDate
        note
      }
    }
    scheduledBy {
      id
      firstName
      lastName
    }
    scheduledOn
    status
    studentsAdded {
      id
      birthDate
      firstName
      gender
      lastName
    }
    studentsAttended {
      id
      firstName
      lastName
    }
    time
    updatedAt
    verifiedBy {
      id
      firstName
      lastName
    }
    verifiedOn
    zone {
      id
      title
    }
  }
`;

export const UpdatePrivateSerieAttendanceMutation = gql`
  mutation updatePrivateSerieAttendance(
    $data: PrivateSerieAttendanceCreateInput!
    $where: UniqueInput!
  ) {
    updatePrivateSerieAttendance(data: $data, where: $where) {
      ...PrivateSerieAttendancePayload
    }
  }
  ${PrivateSerieAttendancePayloadFragment}
` as TypedDocumentNode<
  updatePrivateSerieAttendance,
  updatePrivateSerieAttendanceVariables
>;

export const CreatePrivateSerieAttendanceMutation = gql`
  mutation createPrivateSerieAttendance(
    $data: PrivateSerieAttendanceCreateInput!
  ) {
    createPrivateSerieAttendance(data: $data) {
      ...PrivateSerieAttendancePayload
    }
  }
  ${PrivateSerieAttendancePayloadFragment}
` as TypedDocumentNode<
  createPrivateSerieAttendance,
  createPrivateSerieAttendanceVariables
>;

export const PrivateSerieAttendancesForDay = gql`
  query privateSerieAttendancesForDay(
    $date: Date!
    $organizationId: String!
    $filter: String
  ) {
    privateSerieAttendances(
      where: {
        date: { equals: $date }
        organizationId: $organizationId
        status: { notIn: [Canceled, Pending] }
      }
      filter: $filter
    ) {
      ...PrivateSerieAttendancePayload
    }
  }
  ${PrivateSerieAttendancePayloadFragment}
` as TypedDocumentNode<
  privateSerieAttendancesForDay,
  privateSerieAttendancesForDayVariables
>;

export const PrivateSerieAttendanceQuery = gql`
  query privateSerieAttendance($where: UniqueInput!) {
    privateSerieAttendance(where: $where) {
      ...PrivateSerieAttendancePayload
    }
  }
  ${PrivateSerieAttendancePayloadFragment}
` as TypedDocumentNode<privateSerieAttendance, privateSerieAttendanceVariables>;

const FilteredPrivateSerieAttendancesQuery = gql`
  query filteredPrivateSeriesAttendances(
    $where: PrivateSerieAttendanceWhereInput!
    $filter: String
    $skip: Int
    $take: Int
  ) {
    privateSerieAttendances(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
    ) {
      ...PrivateSerieAttendancePayload
    }
  }
  ${PrivateSerieAttendancePayloadFragment}
` as TypedDocumentNode<
  filteredPrivateSeriesAttendances,
  filteredPrivateSeriesAttendancesVariables
>;

const FilteredPrivateSerieAttendancesCountsQuery = gql`
  query filteredPrivateSerieAttendanceCounts(
    $where: PrivateSerieAttendanceWhereInput!
    $filter: String
  ) {
    privateSerieAttendancesCounts(where: $where, filter: $filter) {
      total
      completed
      verified
      scheduled
      unscheduled
    }
  }
`;

export const QueryFilteredPrivateSerieAttendancesCounts = withApi(
  FilteredPrivateSerieAttendancesCountsQuery,
  {
    name: "queryFilteredPrivateSerieAttendancesCounts",
    skip: ({ match }: any) => {
      return match.params.tab !== "sessions";
    },
    options: ({ store, location }: StoreProps & RouteComponentProps) => {
      const countParams = getSearchParams(["sort", "order"]);

      // console.log(countParams.toString());

      const variables: filteredPrivateSerieAttendanceCountsVariables = {
        where: { organizationId: store.organization.id },
        filter: countParams.toString(),
      };

      return {
        variables,
      };
    },
  }
);

export const QueryFilteredPrivateSerieAttendances = withInfiniteApi(
  FilteredPrivateSerieAttendancesQuery,
  {
    name: "queryFilteredPrivateSerieAttendances",
    skip: ({ match }) => {
      if (
        !match.params.tab ||
        match.params.tab === "offerings" ||
        match.params.tab === "registrations"
      ) {
        return true;
      }

      return false;
    },
    options: ({ store, location, match }: any): any => {
      return {
        variables: {
          where: {
            organizationId: store.organization.id,
            status: {
              notIn: [PrivateSerieAttendanceStatus.Pending],
            },
          },
          filter: location.search,
          skip: 0,
          take: 10,
        },
        nextFetchPolicy: "cache-first",
      };
    },
  }
);

export const UpdatePrivateSerieAttendance = withApiMutation(
  UpdatePrivateSerieAttendanceMutation,
  {
    name: "updatePrivateSerieAttendance",
  }
);

export const CreatePrivateSerieAttendance = withApiMutation(
  CreatePrivateSerieAttendanceMutation,
  {
    name: "createPrivateSerieAttendance",
  }
);
