import { gql } from "graphql-tag";
import moment from "moment-timezone";
import React, { useState } from "react";
import { BorderButton } from "src/components/buttons/BorderButton";
import OrganizationModel from "src/models/OrganizationModel";
import UserModel from "src/models/UserModel";
import { useApi, useApiMutation } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import formatMoney from "src/utils/formatMoney";
import message from "src/utils/message";
import {
  EventType,
  retryEvents,
  retryEventsVariables,
  retryPayment,
  retryPaymentVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";

interface Props {
  balance: number;
  user: UserModel;
  onNetworkAction?: () => void;
  organization?: OrganizationModel;
}

export const RetryPaymentMutation = gql`
  mutation retryPayment($userId: ID!, $organizationId: ID!) {
    retryPayment(userId: $userId, organizationId: $organizationId) {
      paymentSuccessful
    }
  }
` as TypedDocumentNode<retryPayment, retryPaymentVariables>;

const RetryEventsQuery = gql`
  query retryEvents($where: EventWhereInput!) {
    events(where: $where) {
      id
      createdAt
      type
    }
  }
` as TypedDocumentNode<retryEvents, retryEventsVariables>;

export default function RetryPaymentButton(props: Props) {
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const eventsQuery = useApi(RetryEventsQuery, {
    variables: {
      where: {
        byId: {
          equals: props.user.id,
        },
        type: {
          in: [EventType.PaymentRetry],
        },
      },
    },
  });
  const events = eventsQuery?.events || [];
  let paymentRetries = events.filter((e) => e.type === EventType.PaymentRetry);
  const mostRecentAttempt = moment.max(
    paymentRetries.map((p) => moment(p.createdAt))
  );
  const today = moment();
  const retryPayment = useApiMutation(RetryPaymentMutation, {
    variables: {
      userId: props.user.id,
      organizationId: props.organization
        ? props.organization.id
        : store.organization.id,
    },
  });
  const diff = today.diff(mostRecentAttempt, "minutes");

  // console.log("diff", diff);
  // console.log("paymentRetries", paymentRetries);

  let note;
  let disabled = false;

  if (diff <= 60 && paymentRetries.length > 0) {
    note = (
      <p className="text-center text-sm text-empty">
        Didn’t work. Try again in 1 hour, or change the card.
      </p>
    );
    disabled = true;
  }

  // if (process.env.NODE_ENV === "development") {
  //   disabled = false;
  //   paymentRetries = [];
  // }

  if (paymentRetries.length === 4) {
    return (
      <div className="mb-2 max-w-sm">
        <p className="text-center text-sm text-empty">
          The maximum number of charge attempts has been reached. Please change
          the card on file.
        </p>
      </div>
    );
  }

  async function attemptRetry() {
    setLoading(true);
    const processingMessage = message.loading("Attempting payment…");

    try {
      const result = await retryPayment();

      console.log("result", result);

      processingMessage();

      if (result.retryPayment?.paymentSuccessful) {
        message.success("Charge successful!");
      } else {
        message.error("Error retrying payment");
      }
    } catch (e) {
      processingMessage();
      console.log("e", e);
    }

    setLoading(false);

    if (props.onNetworkAction) {
      props.onNetworkAction();
    }

    eventsQuery.refetch();
  }

  return (
    <React.Fragment>
      <div className="max-w-sm">
        <BorderButton
          type="submit"
          onClick={attemptRetry}
          disabled={disabled || loading}
          className="w-full"
        >
          Try Again • Will Charge ${formatMoney(props.balance)}
        </BorderButton>
        {note}
      </div>
    </React.Fragment>
  );
}
