import { FullOrganizationQuery } from "src/api/Organization";
import LinkButton from "src/components/buttons/LinkButton";
import UpdatePaymentForm from "src/components/forms/UpdatePaymentForm";
import Container from "src/components/layout/Container";
import PaymentTable from "src/components/layout/PaymentTable";
import { ContactSupportModal } from "src/components/modals/ContactSupportModal";
import InvoiceModel from "src/models/InvoiceModel";
import OrganizationModel from "src/models/OrganizationModel";
import { useApi } from "src/stores/Api";
import { useStore } from "src/stores/Store";
import { checkOrganizationPaymentAlerts } from "src/utils/checkOrganizationPaymentAlerts";
import { dateFormat } from "src/utils/constants";
import formatMoney from "src/utils/formatMoney";
import { modals } from "src/utils/modals";

interface Props {
  full?: boolean;
}

export function OrganizationPaymentAlert(props: Props) {
  const store = useStore();

  const fullOrganizationQuery = useApi(FullOrganizationQuery, {
    skip: !props.full,
    variables: {
      id: store.organization.id,
    },
  });

  const organization = new OrganizationModel(
    fullOrganizationQuery.organization
  );

  function emailSupport() {
    modals.show(ContactSupportModal);
  }

  if (
    !store.organization ||
    (props.full && !fullOrganizationQuery.organization)
  ) {
    return null;
  }

  const paymentAlerts = checkOrganizationPaymentAlerts({
    organization: store.organization,
  });

  // console.log("paymentAlerts", paymentAlerts);

  let content = null;

  if (paymentAlerts.hasPaymentMethodAlert && !props.full) {
    content = (
      <div className="mb-4 flex items-center gap-4 rounded-full bg-dark py-2 pl-2 pr-4 text-white">
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white text-center text-sm text-organization">
          <i className="far fa-credit-card" />
        </div>
        <div className="flex-1">
          <LinkButton
            className="text-white underline"
            to={`/admin/account/plan`}
          >
            Update your payment method
          </LinkButton>{" "}
          by {paymentAlerts.paymentMethodDeadline.format(dateFormat)} to
          continue using Captyn.
        </div>

        <div>
          <LinkButton onClick={emailSupport} className="text-white underline">
            Need help?
          </LinkButton>
        </div>
      </div>
    );
  }

  if (
    !paymentAlerts.hasPaymentMethodAlert &&
    paymentAlerts.hasBalanceAlert &&
    !props.full
  ) {
    content = (
      <div className="mb-4 flex items-center gap-4 rounded-full bg-dark py-2 pl-2 pr-4 text-white">
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white text-center text-sm text-organization">
          <i className="far fa-credit-card" />
        </div>
        <div className="flex-1">
          Your account has a balance of $
          {formatMoney(store.organization.owner.balance)}.{" "}
          <LinkButton
            className="text-white underline"
            to={`/admin/account/plan`}
          >
            Verify your payment method
          </LinkButton>{" "}
          to continue using Captyn.
        </div>

        <div>
          <LinkButton onClick={emailSupport} className="text-white underline">
            Need help?
          </LinkButton>
        </div>
      </div>
    );
  }

  if (
    (paymentAlerts.hasPaymentMethodAlert || paymentAlerts.hasBalanceAlert) &&
    props.full
  ) {
    const currentInvoice = organization.owner.invoices.reduce(
      (acc, i) => {
        const invoice = new InvoiceModel(i);
        const totals = invoice.getTotals();

        if (totals.totalOwed > 0) {
          return new InvoiceModel({
            ...invoice,
            invoiceItems: acc.invoiceItems.concat(invoice.invoiceItems),
          });
        }
      },
      new InvoiceModel({ invoiceItems: [] })
    );

    return (
      <div className="flex-1">
        <div className="fixed left-0 right-0 top-0 z-20 h-[50px] bg-white shadow">
          <Container full>
            <div className="flex h-[50px] items-center">
              <img
                src="/img/captyn-logo.svg"
                alt="Captyn"
                width="91"
                height="16"
              />
            </div>
          </Container>
        </div>

        <Container className="pb-20 pt-24 sm:pt-20">
          <h1>Update payment method</h1>

          <div className="mb-8">
            <p>Thank you for using Captyn!</p>

            <p>
              In order to continue providing services, we are in need of an
              updated payment method for your {store.organization.title}{" "}
              account.
            </p>
          </div>

          <div className="mb-8">
            <h3>Summary</h3>
            <hr />

            <PaymentTable
              fullWidth
              invoice={currentInvoice}
              removeInvoiceItem={() => {}}
            />
          </div>

          <div className="mb-8">
            <h3>Payment Method</h3>

            <UpdatePaymentForm
              user={store.organization.owner}
              onNetworkAction={store.updateOrganization}
              organization={store.organization}
              adding
            />
          </div>
        </Container>
      </div>
    );
  }

  return content;
}
