import Decimal from "decimal.js-light";
import { intersection } from "lodash-es";
import moment from "moment-timezone";
import DiscountModel from "src/models/DiscountModel";
import GroupRegistrationModel, {
  isGroupRegistration,
} from "src/models/GroupRegistrationModel";
import OrganizationModel from "src/models/OrganizationModel";
import PrivateSerieModel from "src/models/PrivateSerieModel";
import calculateProratedRegistrationPrice from "src/utils/calculateProratedRegistrationPrice";
import {
  AmountType,
  DiscountType,
  GroupRegistrationStatus,
} from "types/code-generator";

interface CalculateDiscountsForRegistrationInput {
  discounts: DiscountModel[];
  registration: GroupRegistrationModel;
  registrations: Array<GroupRegistrationModel | PrivateSerieModel>;
  startDate: Date;
  organization: OrganizationModel;
  index?: number;
}

export default ({
  discounts,
  registration,
  registrations,
  startDate,
  organization,
  index = 0,
}: CalculateDiscountsForRegistrationInput) => {
  const result: Array<{ discount: DiscountModel; amount: number }> = [];

  discounts.forEach((d) => {
    let amount = 0;

    if (d.amountType === AmountType.Dollar) {
      if (d.discountType === DiscountType.MultiStudent) {
        const registrationsForStudent = registrations.filter((r) => {
          if (isGroupRegistration(r) && isGroupRegistration(registration)) {
            return (
              r.status !== GroupRegistrationStatus.Waitlist &&
              ((r.student && r.student.id === registration.student.id) ||
                (r.roster &&
                  registration.roster &&
                  intersection(
                    r.roster.map((rs) => rs.id),
                    registration.roster.map((rs) => rs.id)
                  ).length > 0))
            );
          }
          return false;
        });
        amount += Math.ceil(d.amount / registrationsForStudent.length);
      } else {
        amount += d.amount;
      }
    } else {
      if (isGroupRegistration(registration)) {
        let endDate = moment(startDate)
          .tz(organization.timezone)
          .endOf("month");

        if (registration.finalDate) {
          const finalDate = moment(registration.finalDate).tz(
            organization.timezone
          );
          if (finalDate.isAfter(startDate) && finalDate.isBefore(endDate)) {
            endDate = finalDate;
          }
        }

        // console.log("startDate", startDate);
        let price = calculateProratedRegistrationPrice({
          classItem: registration.class,
          endDate: endDate.toDate(),
          startDate,
          organization,
        });

        if (registration.class?.chargeAmounts?.length) {
          let atRegistrationChargeAmount =
            registration.class?.chargeAmounts.find((ca) => ca.atRegistration);

          for (const m of registration?.accountOwner?.memberships || []) {
            const membershipAtRegistrationChargeAmount =
              registration.class?.chargeAmounts.find(
                (ca) =>
                  ca.atRegistration &&
                  ca.membershipOffering?.id === m.offering?.id
              );

            if (
              membershipAtRegistrationChargeAmount?.price <
              atRegistrationChargeAmount?.price
            ) {
              atRegistrationChargeAmount = membershipAtRegistrationChargeAmount;
            }
          }

          if (
            atRegistrationChargeAmount &&
            atRegistrationChargeAmount.price > 0
          ) {
            price = atRegistrationChargeAmount.price;
          }
        }

        // console.log("price", price);

        let discountAmount = new Decimal(d.amount).times(price);

        if (!discountAmount.isInteger()) {
          if (
            index === 0 ||
            (discountAmount.modulo(1).toNumber() > 0.5 && index === 1)
          ) {
            Decimal.config({ rounding: Decimal.ROUND_CEIL });
          } else {
            Decimal.config({ rounding: Decimal.ROUND_FLOOR });
          }

          discountAmount = discountAmount.toInteger();
        }

        amount += discountAmount.toNumber();
      }
    }

    // console.log("amount", amount);

    result.push({
      discount: d,
      amount,
    });
  });

  return result;
};
