import { ReactNode } from "react";
import tw from "src/utils/tw";
import InputCheckbox from "../inputs/InputCheckbox";

interface Props {
  className?: string;
  options: Array<{
    label: string | ReactNode;
    value: string;
    disabled?: boolean;
  }>;
  disabled?: boolean;
  border?: boolean;
  value?: string[];
  onChange?: (values) => void;
  inline?: boolean;
  small?: boolean;
}

export function CheckboxGroup(props: Props) {
  let value = props.value || [];

  if (!props.value && props["data-__meta"]?.initialValue) {
    value = props["data-__meta"].initialValue || [];
  }

  function onChange(newValue) {
    let newValues = [...value];

    if (newValues.includes(newValue)) {
      newValues = newValues.filter((v) => v !== newValue);
    } else {
      newValues.push(newValue);
    }

    props.onChange(newValues);
  }

  return (
    <div
      className={tw(
        props.inline && "flex gap-4",
        props.border && "divide-y divide-borderGray border-b border-borderGray"
      )}
    >
      {props.options.map((o) => {
        let disabled = props.disabled;

        if (typeof o.disabled !== "undefined") {
          disabled = o.disabled;
        }

        return (
          <div key={o.value} className={tw("py-3", props.inline && "flex-1")}>
            <InputCheckbox
              onChange={() => {
                onChange(o.value);
              }}
              checked={value.includes(o.value)}
              disabled={disabled}
              className={tw(props.inline && "items-center", props.className)}
              small={props.small}
            >
              {o.label}
            </InputCheckbox>
          </div>
        );
      })}
    </div>
  );
}
