import { OfferingTag } from "src/components/items/OfferingTags";
import { useStore } from "src/stores/Store";
import { Level } from "types/code-generator";

interface Props {
  user: {
    level: Level;
    primaryAccount?: {
      id: string;
    };
  };
}

export function LevelTag(props: Props) {
  const store = useStore();

  let levelTag;
  let accountTag;

  if (props.user.level === Level.Admin) {
    levelTag = (
      <OfferingTag>{store.organization.administratorTerminology}</OfferingTag>
    );
  }

  if (props.user.level === Level.Instructor) {
    levelTag = <OfferingTag>{store.organization.staffTerminology}</OfferingTag>;
  }

  if (props.user.level !== Level.Student && !props.user.primaryAccount) {
    accountTag = (
      <div className="step-subhead flex items-center gap-1">
        <i className="far fa-crown" /> Account
      </div>
    );
  }

  if (props.user.level !== Level.Student && props.user.primaryAccount) {
    accountTag = (
      <div className="step-subhead flex items-center gap-1">
        <i className="far fa-link-simple" /> Account
      </div>
    );
  }

  return (
    <div>
      {levelTag}
      {accountTag}
    </div>
  );
}
